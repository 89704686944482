// Floor.tsx
import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Grid,
  Box,
  Tooltip,
  IconButton,
} from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";
import AddBlockButton from "../../Assets/Add_block_button.json";
import { useFormik } from "formik";
import * as yup from "yup";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { ColDef } from "ag-grid-community";
import AutoComplete from "../../Components/AutoComplete/AutoComplete";
import TextBox from "../../Components/TextBox/TextBox";
import DataTable from "../../Components/DataTable/DataTable";
import { useAppDispatch, useAppSelector } from "../../Redux/Store";
import {
  AddFloor,
  GetFloorById,
  GetFloorList,
  UpdateFloor,
  deleteFloor,
} from "../../Redux/Thunks/FloorThunk";
import { DeleteFloorEntity } from "../../Model/FloorModel";
import { GetLocationDetails } from "../../Redux/Thunks/LocationThunk";
import { BlockEntity } from "../../Model/BlockModel";
import { AutoComplete as AutoCompleteType } from "../../Model/AutoCompleteModel";
import SnackbarComponent from "../../Components/SnackBar/Snackbar";
import Loader from "../../Components/Loader/Loader";
import Theme from "../../theme/theme";
import FormControl from "@mui/material/FormControl";
import {
  floorButtonStyles,
  floorContainerStyles,
  floorTextBoxStyles,
} from "./FloorStyles";
import Title from "../../Components/Title/Title";
import TextLabel from "../../Components/Label/TextLabel";
import CustomButton from "../../Components/Button/Button";
import { Patientstyle } from "../Patient/PatientStyle";
import DialogBox from "../../Components/DialogBox/DialogBox";
import FormAccordion from "../../Components/Accordion/FormAccordion";
import { assetStyles } from "../Asset/AssetStyles";
import { useNavigate } from "react-router-dom";
import { getStoreId } from "../../Utilities/Store";

const Floor: React.FC = (): JSX.Element => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [SelectedFloorId, setSelectedFloorId] = useState(0);
  const [blockData, setBlockData] = useState<any>([]);
  const [selectedBLock, setSelectedBlock] = useState<AutoCompleteType | null>(
    null
  );
  const playerRef = useRef(null);
  const [rowData, setRowData] = useState([]);
  const dispatch = useAppDispatch();
  const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const { Floors, floor, isLoading } = useAppSelector((state) => state.floor);
  const { locationDetails } = useAppSelector((store) => store.location);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [deleteFloorData, setDeleteFloorData] = useState<any>();
  const navigate = useNavigate()
  useEffect(() => {
    dispatch(GetFloorList());
    const GetDetails = async () => {
      await dispatch(GetLocationDetails());
    };
    GetDetails();
  }, []);

  // useEffect(() => {
  //   if (floor?.floorId > 0) {
  //     formikFloor.setFieldValue("floor", floor.floorName);
  //     formikFloor.setFieldValue('block', floor.blockId)
  //     let currentSelectedBlock =
  //       (blockData && blockData.length > 0)
  //         ? blockData
  //           .map((data: AutoCompleteType) => {
  //             if (floor.blockId == data.id) {
  //               return data.label;
  //             }
  //           })
  //           .filter((data: any) => data != undefined)
  //         : [];
  //     setSelectedBlock(currentSelectedBlock);
  //   }
  // }, [floor]);

  useEffect(() => {
    let newBlockData;
    if (locationDetails && locationDetails?.block.length > 0) {
      newBlockData = locationDetails?.block.map((data: BlockEntity) => {
        return { label: data.blockName, id: data.blockId };
      });
    }
    setBlockData(newBlockData);
  }, [locationDetails?.block.length]);

  useEffect(() => {
    if (Floors && Floors.length > 0) {
      let data = Floors.map((tabData: any, index: number) => {
        return {
          index: index + 1,
          floorName: tabData.floorName,
          block: tabData.blockName,
          floorId: tabData.floorId,
          blockId: tabData.blockId,
        };
      });
      setRowData(data);
    }
  }, [Floors]);

  useEffect(() => {
    if (floor) {
      formikFloor.setFieldValue("Block", floor.blockId);
      let currentSelectedBlock =
        blockData.length > 0
          ? blockData
            .map((data: AutoCompleteType) => {
              if (floor.blockId == data.id) {
                return data.label;
              }
            })
            .filter((data: any) => data != undefined)
          : [];
      setSelectedBlock(currentSelectedBlock);
    }
  }, [floor]);

  const formikFloor = useFormik({
    initialValues: {
      block: "",
      floor: "",
    },
    validationSchema: yup.object({
      floor: yup.string()
        .trim()
        .matches(/^[A-Za-z0-9 #_$-]*$/, {
          message: "Invalid characters",
          excludeEmptyString: true,
        })
        .required("Required"),
      block: yup.string().matches(/^[A-Za-z0-9#_$-]+$/, "Invalid Character").required("Required "),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (SelectedFloorId < 1) {
        let param = {
          FloorName: values.floor,
          StoreId: await getStoreId(),
          BLockId: values.block,
        };
        let res = await dispatch(AddFloor(param));
        if (res && res.meta.requestStatus === "rejected") {
          setSnackBarMessage("Connection Refused");
          setShowSnackBar(true);
          return;
        }
        if (
          (res.payload && res.payload.status && res.payload.status == true) ||
          res.payload.status == 200
        ) {
          setSnackBarMessage("Floor added sucessfully!!");
          setShowSnackBar(true);
          await dispatch(GetFloorList());
          resetForm();
          setIsSubmitting(true);
          setSelectedBlock(null);
        } else {
          setSnackBarMessage(res.payload.data);
          setShowSnackBar(true);
        }
      } else {
        let param = {
          FloorName: values.floor,
          StoreId: await getStoreId(),
          BLockId: values.block,
          FloorId: floor.floorId,
        };
        let res = await dispatch(UpdateFloor(param));
        if (res && res.meta && res.meta.requestStatus === "rejected") {
          setSnackBarMessage("Connection Refused");
          setShowSnackBar(true);
          return;
        }
        if (
          (res.payload && res.payload.status && res.payload.status == true) ||
          res.payload.status == 200
        ) {
          setSnackBarMessage("Floor updated sucessfully!!");
          setShowSnackBar(true);
          await dispatch(GetFloorList());
          setSelectedFloorId(0);
          resetForm();
          setIsSubmitting(true);
          setSelectedBlock(null);
          setIsUpdate(false);
        } else if (res.payload.status == false || res.payload.status == 400) {
          setSnackBarMessage(res.payload.data);
          setShowSnackBar(true);
        } else if (res.payload.status == false || res.payload.status == 500) {
          setSnackBarMessage(res.payload.data);
          setShowSnackBar(true);
        }
        else {
          setSnackBarMessage(res.payload.data);
          setSelectedBlock(null);
          setShowSnackBar(true);
        }
      }
    },

  });
  const onEdit = async (data: any) => {
    setIsUpdate(true);
    setSelectedFloorId(data.floorId);
    let param: DeleteFloorEntity = {
      blockId: data.blockId,
      id: data.floorId,
    };
    const res = await dispatch(GetFloorById(param));
    setSelectedBlock(res.payload.data.blockName);
    formikFloor.setFieldValue("floor", data.floorName);
    formikFloor.setFieldValue("block", data.blockId);
  };

  const onDelete = async () => {
    let deleteParam: DeleteFloorEntity = {
      id: deleteFloorData?.floorId,
      blockId: deleteFloorData?.blockId,
    };
    let res = await dispatch(deleteFloor(deleteParam));
    if (res.meta.requestStatus === "rejected") {
      setSnackBarMessage("Connection Refused");
      setShowSnackBar(true);
      return;
    }
    if (
      (res.payload && res.payload.status && res.payload.status == true) ||
      res.payload.status == 200
    ) {
      setSnackBarMessage("Floor deleted sucessfully!!");
      setShowSnackBar(true);
      setIsSubmitting(true);
      setShowDialog(false);

    } else {
      setSnackBarMessage(res.payload.data);
      setShowSnackBar(true);
    }
    dispatch(GetFloorList());
  };

  const confirmDelete = (data: any) => {
    setDeleteFloorData(data);
    setShowDialog(true);

    setDialogMessage("Are you sure want to delete floor?")
  }

  const ActionRenderer = (props: any) => {
    return (
      <Box aria-label="Actions">
        <Tooltip title="Edit" placement="bottom-start">
          <IconButton onClick={() => onEdit(props.data)}>
            <EditOutlinedIcon color="primary" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete" placement="bottom-start">
          <IconButton onClick={() => confirmDelete(props.data)}>
            <DeleteIcon color="primary" />
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

  const colDefs: ColDef[] = [
    { field: "index" },
    { field: "floorName" },
    { field: "block" },
    { field: "actions", headerName: "Actions", cellRenderer: ActionRenderer },
  ];

  const setBlockTableValue = (id: number | string) => {
    formikFloor.setFieldValue(
      "block",
      blockData.find((item: AutoCompleteType) => item.id === id)?.id || 0
    );
    let currentSelectedBlock =
      blockData.length > 0
        ? blockData
          .map((data: AutoCompleteType) => {
            if (id == data.id) {
              return data.label;
            }
          })
          .filter((data: any) => data != undefined)
        : [];
    setSelectedBlock(currentSelectedBlock);
  };

  const setLocationClearValue = () => {
    setSelectedBlock(null);
    formikFloor.setFieldValue("Locationtype", null);
  };

  const handleDisagree = () => {
    setShowDialog(false);
  }

  const handleNavigate = () => {
    navigate('/locations')
  }
  return (
    <>
      <Box mr={2} pt={1}>
        <FormAccordion isExpanded={isUpdate == true ? true : false} title={isUpdate == true ? "Update Floor" : "Add Floor"} backPage={handleNavigate} style={{ ...assetStyles.containerStyles }}>
          <Grid container spacing={2} >
            <Grid item xs={12}>
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={4}>
                    <TextLabel label="Block" color={Theme.palette.common.white} required />
                    <AutoComplete
                      color={Theme.palette.primary.contrastText}
                      value={selectedBLock}
                      data={blockData ?? []}
                      title="Block"
                      required={true}
                      onClick={setBlockTableValue}
                      onClear={setLocationClearValue}
                      error={
                        formikFloor.touched.block &&
                        Boolean(formikFloor.errors.block)
                      }
                      helperText={
                        formikFloor.touched.block && formikFloor.errors.block
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <TextLabel label="Floor" color={Theme.palette.common.white} required/>
                    <TextBox
                      type="text"
                      value={formikFloor.values.floor}
                      formik={formikFloor}
                      error={
                        Boolean(formikFloor.touched.floor) &&
                        Boolean(formikFloor.errors.floor)
                      }
                      fieldpropsName="floor"
                      helpertext={
                        formikFloor.touched.floor
                          ? String(formikFloor.errors.floor)
                          : ""
                      }
                      label="Floor"
                      sx={Patientstyle.textboxStyles}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <CustomButton
                      onClick={() => formikFloor.handleSubmit()}
                      sx={{ ...floorButtonStyles, marginRight: 1 }}
                    >
                      {isUpdate == true ? (
                        "Update Floor"
                      ) : (
                        "Add Floor"
                      )}
                    </CustomButton>
                    <CustomButton
                      variant="contained"
                      onClick={() => {
                        formikFloor.resetForm();
                        setSelectedBlock(null);
                        setIsUpdate(false);
                        setSelectedFloorId(0);
                      }}
                      sx={Patientstyle.clearButton}
                      text="Clear"
                      formik={formikFloor}
                      isLoading={isLoading}
                    >Clear</CustomButton>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
          <Loader openModal={isLoading} />
          <SnackbarComponent
            setShowSnackBar={setShowSnackBar}
            showSnackBar={showSnackBar}
            snackBarMessage={snackBarMessage}
          />
        </FormAccordion>
        <Grid item xs={12}>
          <Title title="Floor List" sx={{ color: Theme.palette.primary.main }} />
        </Grid>
        <Grid item xs={12}>
          <DataTable rows={rowData} columnDefs={colDefs} />
        </Grid>
        <DialogBox
          message={dialogMessage}
          handleAccept={() => onDelete()}
          handleCancel={handleDisagree}
          open={showDialog}
        />
      </Box>
    </>
  );
};

export default Floor;
