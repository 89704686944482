import React from "react";
import { TitleProps } from "../../Model/TitleModel";
import { Typography } from "@mui/material";

function Title(props: TitleProps) {
  const { title, sx } = props;
  return (
    <Typography
      variant="h5"
      sx={{ fontWeight: "bold", margin: "5px 0", color: "#2e2586", ...sx }}
    >
      {title}
    </Typography>
  );
}

export default Title;
