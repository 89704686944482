import { TaskDetailModel } from "../Model/ReportModel";

export class SignalRNotificationType {
    static readonly Assigned = 1;
    static readonly ProgressAssigned = 0;
    static readonly Scheduled = 2;
    static readonly Accepted = 3;
    static readonly Rejected = 4;
    static readonly CancelRequested = 5;
    static readonly Completed = 6;
    static readonly Cancelled = 7;
    static readonly ShiftStarted = 8;
    static readonly ShiftEnded = 9;

    private static readonly taskStatusIds = [
        SignalRNotificationType.Assigned,
        SignalRNotificationType.Scheduled,
        SignalRNotificationType.Accepted,
        SignalRNotificationType.Rejected,
        SignalRNotificationType.CancelRequested,
        SignalRNotificationType.Completed,
        SignalRNotificationType.Cancelled,
        SignalRNotificationType.ShiftStarted,
        SignalRNotificationType.ShiftEnded
    ]

    static getStatusName(value: number): string {
        switch (value) {
            case SignalRNotificationType.Assigned:
                return 'Assigned';
            case SignalRNotificationType.Scheduled:
                return 'Scheduled';
            case SignalRNotificationType.Accepted:
                return 'Accepted';
            case SignalRNotificationType.Rejected:
                return 'Rejected';
            case SignalRNotificationType.CancelRequested:
                return 'CancelRequested';
            case SignalRNotificationType.Completed:
                return 'Completed';
            case SignalRNotificationType.Cancelled:
                return 'Cancelled';
            case SignalRNotificationType.ShiftStarted:
                return 'ShiftStarted';
            case SignalRNotificationType.ShiftEnded:
                return 'ShiftEnded';
            default:
                return 'error';
        }
    }

    static readonly taskStatus = this.taskStatusIds.map(statusId => ({
        statusId,
        statusName: SignalRNotificationType.getStatusName(statusId)
    }));


    static getProgressBarDetails = (data: TaskDetailModel[]) => {
        const currentStatus = data[data.length - 1].currentStatus;

        let stepLabels = data.map((task) => {
            return task.statusName
        })

        if (currentStatus === SignalRNotificationType.Assigned) {
            stepLabels.push(SignalRNotificationType.getStatusName(SignalRNotificationType.Accepted));
            stepLabels.push(SignalRNotificationType.getStatusName(SignalRNotificationType.Completed));
            stepLabels.push(SignalRNotificationType.getStatusName(SignalRNotificationType.CancelRequested));
            stepLabels.push(SignalRNotificationType.getStatusName(SignalRNotificationType.Cancelled));
        }
        if (currentStatus === SignalRNotificationType.Scheduled) {
            stepLabels.push(SignalRNotificationType.getStatusName(SignalRNotificationType.Assigned));
            stepLabels.push(SignalRNotificationType.getStatusName(SignalRNotificationType.Accepted));
            stepLabels.push(SignalRNotificationType.getStatusName(SignalRNotificationType.Completed));
            stepLabels.push(SignalRNotificationType.getStatusName(SignalRNotificationType.CancelRequested));
            stepLabels.push(SignalRNotificationType.getStatusName(SignalRNotificationType.Cancelled));
        }
        if (currentStatus === SignalRNotificationType.Accepted) {
            stepLabels.push(SignalRNotificationType.getStatusName(SignalRNotificationType.Completed));
        }
        if (currentStatus === SignalRNotificationType.CancelRequested) {
            stepLabels.push(SignalRNotificationType.getStatusName(SignalRNotificationType.Cancelled));

        }
        if (currentStatus === SignalRNotificationType.ShiftStarted) {
            stepLabels.push(SignalRNotificationType.getStatusName(SignalRNotificationType.ShiftStarted));
        }
        if (currentStatus === SignalRNotificationType.ShiftEnded) {
            stepLabels.push(SignalRNotificationType.getStatusName(SignalRNotificationType.ShiftEnded));
        }
        return stepLabels;
    }

}

