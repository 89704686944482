import {
  Grid,
  Container,
  Box,
  Typography,
  Tooltip,
  IconButton,
  Switch,
  Button,
  Stack,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../Redux/Store";
import { useEffect, useState } from "react";
import {
  createPatient,
  getAllPatients,
  getSinglePatient,
  PatientResponse,
  updatePatient,
} from "../../Redux/Thunks/PatientThunk";
import { MappedPatientData, PatientDataModel } from "../../Model/PatientModel";
import DataTable from "../../Components/DataTable/DataTable";
import { ColDef } from "ag-grid-community";
import { unwrapResult } from "@reduxjs/toolkit";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import * as yup from "yup";
import { useFormik } from "formik";
import Title from "../../Components/Title/Title";
import TextBox from "../../Components/TextBox/TextBox";
import SelectBox from "../../Components/SelectBox/SelectBox";
import SnackbarComponent from "../../Components/SnackBar/Snackbar";
import Loader from "../../Components/Loader/Loader";
import { getStoreId } from "../../Utilities/Store";
import Cookies from "js-cookie";
import DialogBox from "../../Components/DialogBox/DialogBox";
import { Patientstyle } from "./PatientStyle";
import CustomButton from "../../Components/Button/Button";
import TextLabel from "../../Components/Label/TextLabel";
import Theme from "../../theme/theme";
import { SelectComponent } from "../../Components/SelectComponent";

const PatientDetails = () => {
  const { patientListData, patientByIdData, isLoading } = useAppSelector(
    (state) => state.patient
  );
  const dispatch = useAppDispatch();
  const [isUpdateAction, setIsUpdateAction] = useState<boolean>(false);
  const [id, setId] = useState<number>();
  const [ShowSnackBar, setShowSnackBar] = useState<boolean>(false);
  const [snackMessage, setSnackMessage] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean>(false);
  const [alertBoxMsg, setAlertBoxMsg] = useState<string>("");
  const [showDialog, setShowDialog] = useState(false);
  const [updateCurrentPatientId, setUpdateCurrentPatientId] = useState<any>();

  const fetchAllData = () => {
    const response = dispatch(getAllPatients());
    return response;
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  const handleDisagree = () => {
    setShowDialog(false);
  };

  const formikAddPatient = useFormik({
    initialValues: {
      patientName: "",
      patientAge: "",
      patientGender: "",
      storeId: Cookies.get("storeId"),
    },
    validationSchema: yup.object({
      patientName: yup
        .string()
        .trim()
        .matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, "Name must contain only letters")
        .required("Required"),
      patientNumber: yup
        .string()
        .trim()
        .matches(/^[0-9]{10}$/, "Must be a valid mobile number"),
      patientAge: yup
        .string()
        .matches(/^(?:\d{1,2}|120)$/, "Age must be a number within 1 to 120")
        .required("Required"),
      patientGender: yup
        .string()
        .trim()
        .matches(/^(male|female|Male|Female)$/, "Gender must be selected")
        .required("Required"),
      patientContact: yup
        .string()
        .trim()
        .matches(
          /^(?:\+91|91|0)?[6789]\d{9}$/,
          "Contact number must contain only numbers"
        ),
      // .required("Requird"),
      patientAddress: yup
        .string()
        .trim()
        .matches(/^[a-zA-Z0-9\s\.,#\-]+$/, "Address is mandatory"),
      // .required("Required"),
      patientCompanionName: yup
        .string()
        .trim()
        .matches(/^[A-Za-z]+$/, "Name must contain only letters"),
      // .required("Requird"),
      patientCompanionContact: yup
        .string()
        .trim()
        .matches(
          /^(?:\+91|91|0)?[6789]\d{9}$/,
          "Contact number must contain only numbers"
        ),
      // .required("Requird"),
      patientLocation: yup
        .string()
        .trim()
        .matches(/^[a-zA-Z0-9\s\.,#\-]+$/, "Location must be given"),
      // .required("Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      //For creating Patients
      if (!isUpdateAction) {
        const createValues = {
          patientName: formikAddPatient.values.patientName,
          patientAge: formikAddPatient.values.patientAge,
          patientGender: formikAddPatient.values.patientGender,
          storeId: await getStoreId(),
          isActive: true,
          patientNumber: "",
          patientContact: "",
          patientAddress: "",
          patientCompanionName: "",
          patientCompanionContact: "",
          patientLocation: "",
        };
        //Create Patient Rquest
        const createResponse = await dispatch(createPatient(createValues));
        const unwrapRes = unwrapResult<any>(createResponse);
        if (unwrapRes.status == true && unwrapRes.statusCode == 200) {
          setShowSnackBar(true);
          setSnackMessage("Patient Created");
        } else if (unwrapRes.status == false && unwrapRes.statusCode !== 200) {
          setShowSnackBar(true);
          setSnackMessage("Error Occured");
        }
        resetForm();
        fetchAllData();
      } else {
        //Updating Patients
        if (id !== undefined) {
          const getResponse = await dispatch(
            getSinglePatient({ patientId: id })
          );
          const unwrappedGetResponse = unwrapResult(getResponse);
          const updateValues = {
            patientId: id,
            patientName: formikAddPatient.values.patientName,
            patientAge: formikAddPatient.values.patientAge,
            patientGender: formikAddPatient.values.patientGender,
            storeId: await getStoreId(),
            isActive: unwrappedGetResponse.data.isActive,
            patientNumber: "",
            patientContact: "",
            patientAddress: "",
            patientCompanionName: "",
            patientCompanionContact: "",
            patientLocation: "",
          };
          const updateResponse = await dispatch(updatePatient(updateValues));
          const updateUnwrapRes = unwrapResult(updateResponse);
          if (
            updateUnwrapRes.status == true &&
            updateUnwrapRes.statusCode == 200
          ) {
            setShowSnackBar(true);
            setSnackMessage("Patient Updated");
          } else {
            console.log("Patient Not Updated due to unknown error");
          }
          resetForm();
          setIsUpdateAction(false);
          fetchAllData();
        }
      }
    },
  });

  const ActionRenderer = (params: any) => {
    const { data: rowData } = params;
    const patientId = rowData.patientId;
    const isNewActive = rowData.isActive;

    //Handled for only Action Status

    const handleSwitch = async (patientId: number) => {
      setAlertBoxMsg(
        rowData.isActive
          ? "Do you want to change the user's active state?"
          : "Do you want to change the user's inactive state?"
      );
      setUpdateCurrentPatientId(patientId);
      setShowDialog(true);
    };

    return (
      <Box aria-label="Actions">
        <Tooltip title="Edit" placement="bottom-start">
          <IconButton
            sx={{ cursor: "pointer" }}
            onClick={() => handleUpdate(patientId)}
          >
            <EditOutlinedIcon color="primary" />
          </IconButton>
        </Tooltip>

        <Switch
          sx={{ cursor: "pointer" }}
          checked={rowData.isActive}
          onChange={() => handleSwitch(patientId)}
          color="primary"
          name="isActiveSwitch"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </Box>
    );
  };

  const columnDefs: ColDef[] = [
    {
      field: "no",
      headerName: "No",
      maxWidth: 90,
      // minWidth:35,
    },

    {
      field: "patientName",
      headerName: "Name",
      minWidth: 100,
    },
    {
      field: "patientAge",
      headerName: "Age",
      minWidth: 85,
    },
    {
      field: "patientGender",
      headerName: "Gender",
      minWidth: 105,
    },
    {
      field: "action",
      headerName: "Action",
      cellRenderer: ActionRenderer,
      minWidth: 120,
    },
  ];

  const options = [
    { label: "Male", value: "Male", id: 1 },
    { label: "Female", value: "Female", id: 2 },
  ];

  const isFormEmpty = () => {
    return Object.values([
      formikAddPatient.values.patientAge,
      formikAddPatient.values.patientGender,
      formikAddPatient.values.patientName,
    ]).every((value) => value === "");
  };

  const handleUpdate = async (patientId: number) => {
    //Handled for Update Data but not Action status switch
    setIsUpdateAction(true);
    const responseForGetPatient = await dispatch(
      getSinglePatient({ patientId })
    );
    const unwrapRes = unwrapResult(responseForGetPatient);
    if ((unwrapRes.status = true)) {
      formikAddPatient.setFieldValue("patientName", unwrapRes.data.patientName);
      formikAddPatient.setFieldValue("patientAge", unwrapRes.data.patientAge);
      formikAddPatient.setFieldValue(
        "patientGender",
        unwrapRes.data.patientGender
      );
      setId(unwrapRes.data.patientId);
    }
  };

  const handleStatusUpdate = async (patientId: number) => {
    formikAddPatient.resetForm();
    try {
      const responseForGetPatient = await dispatch(
        getSinglePatient({ patientId })
      );
      const unwrapRes = unwrapResult(responseForGetPatient);
      const messageActive = unwrapRes.data.isActive == true ? "pass" : "fail";
      const PatientUpdatedValues = {
        ...unwrapRes.data,
        patientId: patientId,
        isActive: unwrapRes.data.isActive ? false : true,
      };

      const response = await dispatch(updatePatient(PatientUpdatedValues));
      const updatedResponse = unwrapResult(response);
      const responseForGetPatientTwo = await dispatch(
        getSinglePatient({ patientId })
      );
      const unwrapResTwo = unwrapResult(responseForGetPatientTwo);
      if (updatedResponse.data == true && unwrapRes.data.isActive == false) {
        setShowSnackBar(true);
        setSnackMessage("Patient Active");
      }
      if (updatedResponse.data == true && unwrapRes.data.isActive == true) {
        setShowSnackBar(true);
        setSnackMessage("Patient Inactive");
      }
      setShowDialog(false);
      fetchAllData();
    } catch (e) {
      throw e;
    }
  };

  return (
    <>
      <Container>
        <Grid item container mt={2} sx={Patientstyle.containerStyles}>
          <Grid item xs={12} mt={2} mb={2}>
            <Typography variant="h6" color="primary">
              <Title
                sx={Patientstyle.titleStyles}
                title={!isUpdateAction ? "Add Patient" : "Update Patient"}
              />
            </Typography>
          </Grid>
          <Grid
            container
            sx={{ display: "flex", justifyContent: "center" }}
            spacing={2}
          >
            <Grid item xs={12} md={4}>
              <TextLabel
                label="Patient Name"
                color={Theme.palette.primary.contrastText}
              />
              <TextBox
                type="text"
                formik={formikAddPatient}
                error={
                  Boolean(formikAddPatient.touched.patientName) &&
                  Boolean(formikAddPatient.errors.patientName)
                }
                fieldpropsName="patientName"
                helpertext={
                  formikAddPatient.touched.patientName
                    ? String(formikAddPatient.errors.patientName)
                    : ""
                }
                sx={Patientstyle.textboxStyles}
                label="Patient Name"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextLabel
                label="Patient Age"
                color={Theme.palette.primary.contrastText}
              />
              <TextBox
                type="text"
                formik={formikAddPatient}
                error={
                  Boolean(formikAddPatient.touched.patientAge) &&
                  Boolean(formikAddPatient.errors.patientAge)
                }
                fieldpropsName="patientAge"
                helpertext={
                  formikAddPatient.touched.patientAge
                    ? String(formikAddPatient.errors.patientAge)
                    : ""
                }
                sx={Patientstyle.textboxStyles}
                label="Patient Age"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextLabel
                label="Patient Gender"
                color={Theme.palette.primary.contrastText}
              />
              <SelectBox
                options={options}
                formik={formikAddPatient}
                value={formikAddPatient.values.patientGender}
                error={
                  Boolean(formikAddPatient.touched.patientGender) &&
                  Boolean(formikAddPatient.errors.patientGender)
                }
                fieldpropsName="patientGender"
                placeHolder="Patient Gender"
                helpertext={
                  formikAddPatient.touched.patientGender
                    ? String(formikAddPatient.errors.patientGender || "")
                    : ""
                }
                sx={Patientstyle.textboxStyles}
                label="Patient Gender"
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end" spacing={2} marginTop={2}>
            <Grid item>
              <CustomButton
                variant="contained"
                onClick={() => formikAddPatient.handleSubmit()}
                sx={Patientstyle.buttonStyles}
                text={!isUpdateAction ? "Add Patient" : "Update Patient"}
                formik={formikAddPatient}
                isLoading={isLoading}
              >
                {!isUpdateAction ? "Add Patient" : "Update Patient"}
              </CustomButton>
            </Grid>
            <Grid item>
              <CustomButton
                variant="outlined"
                onClick={() => {
                  formikAddPatient.resetForm();
                  setIsUpdateAction(false);
                }}
                formik={formikAddPatient}
                sx={Patientstyle.clearButton}
                text="Clear"
              >
                Clear
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
        <SnackbarComponent
          setShowSnackBar={setShowSnackBar}
          showSnackBar={ShowSnackBar}
          snackBarMessage={snackMessage}
        />
        <Loader openModal={isLoading} />
        <DialogBox
          message={alertBoxMsg}
          handleAccept={() => handleStatusUpdate(updateCurrentPatientId)}
          handleCancel={handleDisagree}
          open={showDialog}
        />
      </Container>
      <Container>
        <Grid item xs={12} mt={2}>
          <Typography variant="h6" color="primary">
            <Title title={"Patient Details"} />
          </Typography>
        </Grid>
        <DataTable
          rows={
            patientListData.length > 0
              ? patientListData.map((item, index) => ({
                ...item,
                no: index + 1,
              }))
              : []
          }
          columnDefs={columnDefs}
        />
        <Grid item xs={12} mt={2}>
          <Grid
            className="ag-theme-quartz"
            style={{
              height: "auto",
              width: "100%",
              marginBottom: "10px",
            }}
          ></Grid>
        </Grid>
      </Container>
    </>
  );
};

export default PatientDetails;
