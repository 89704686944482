import React, { useState, useEffect } from "react";
import { Grid, Typography, Box, Stack, Accordion, AccordionSummary, AccordionDetails, Paper, Tooltip, IconButton, Switch, } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { ColDef } from "ag-grid-community";
import AutoComplete from "../../Components/AutoComplete/AutoComplete";
import TextBox from "../../Components/TextBox/TextBox";
import { ExpandMore } from "@mui/icons-material";
import { AutoComplete as AutoCompleteType } from "../../Model/AutoCompleteModel";
import { validations } from "../../Utilities/Validation";
import { useAppDispatch, useAppSelector } from "../../Redux/Store";
import { getUserFromServer, createUserInServer, getAllListUserFromServer, getSingleUserByIdFromServer, updateUserInServer, } from "../../Redux/Thunks/AddUsersThunk";
import { CreateUser, Role, Store, IUpdateUserValue, UserInfoValue, } from "../../Model/AddUserModels";
import CloseIcon from "@mui/icons-material/Close";
import DataTable from "../../Components/DataTable/DataTable";
import SnackbarComponent from "../../Components/SnackBar/Snackbar";
import { DataSourceItem } from "../../Model/AddUserModels";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Theme from "../../theme/theme";
import { getUserId } from "../../Utilities/UserId";
import AccountCircleSharpIcon from "@mui/icons-material/AccountCircleSharp";
import Loader from "../../Components/Loader/Loader";
import Title from "../../Components/Title/Title";
import { userListValue } from "../../Utilities/UserData";
import DialogBox from "../../Components/DialogBox/DialogBox";
import { Patientstyle } from "../Patient/PatientStyle";
import TextLabel from "../../Components/Label/TextLabel";
import CustomButton from "../../Components/Button/Button";
import _ from "lodash";
import FormAccordion from "../../Components/Accordion/FormAccordion";
import { assetStyles } from "../Asset/AssetStyles";
const AddUser: React.FC = (): JSX.Element => {
  const [storeValue, setStoreValue] = useState<AutoCompleteType[]>([]);
  const [roleValue, setRoleValue] = useState<AutoCompleteType[] | any>([]);
  const [store, setStore] = useState<AutoCompleteType[] | any>([]);
  const [role, setRole] = useState<AutoCompleteType[] | any>([]);
  const [isRequired, setIsRequired] = useState<boolean>(true);
  const [addPermissionData, setAddPermissionData] = useState<any[]>([]);
  const [userInfoValue, setUserInfoValue] = useState<UserInfoValue[]>([]);
  const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [userId, setUserId] = useState("");
  const [userIdCookie, setUserIdCookie] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [tableData, setTableData] = useState<DataSourceItem[]>([]);
  const [pendingState, setPendingState] = useState(false);
  const [mainStore, setMainStore] = useState([]);
  const [updateToggleUserId, setUpdateToggleUserId] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [userDataById, setUserDataById] = useState([]);
  const [updateUserPermissionValue, setUpdateUserPermissionValue] = useState<UserInfoValue[]>([]);
  const dispatch: any = useAppDispatch();
  const { isLoading, listData } = useAppSelector((state) => state.listUser);
  const getUserIdFromCookie = async () => {
    const user: any = await getUserId();
    setUserIdCookie(user);
  };
  useEffect(() => {
    getUserIdFromCookie();
    getStoreData();
    fetchData();
  }, []);
  useEffect(() => {
    if (listData && listData.length > 0) {
      let data = listData.map((tabData: any, index: number) => {
        return {
          no: index + 1,
          displayName: tabData.displayName,
          storeName: tabData.storeName,
          roleName: tabData.roleName,
          email: tabData.email ?? "",
          phoneNumber: tabData.phoneNumber,
          userId: tabData.userId,
          isActive: tabData.isActive,
          employeeNumber: tabData.employeeNumber
        };
      });
      setTableData(data);
    }
  }, [listData]);
  async function getStoreData() {
    await dispatch(getUserFromServer()).then((res: any) => {
      let newStoreData;
      let newRoleData;
      const storeData = res.payload.data.stores;
      if (storeData) {
        newStoreData = storeData?.map((data: Store) => {
          return { label: data.name, id: data.storeId };
        });
        setMainStore(newStoreData);
        setStoreValue(newStoreData);
      }
      const roleData = res.payload.data.roles;
      if (roleData) {
        newRoleData = roleData?.map((data: Role) => {
          return { label: data.name, id: data.id };
        });
        setRoleValue(newRoleData);
      }
    });
  }
  const fetchData = async () => {
    const res = await dispatch(getAllListUserFromServer(await userListValue()));
    if (res.meta.requestStatus === "rejected") {
      setSnackBarMessage("Connection Refused");
      setShowSnackBar(true);
      return;
    }
    if (res.payload.status == true || res.payload.status == 200) {
      return;
    } else {
      return;
    }
  };
  const formikAddUser: any = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      userEmail: "",
      userPassword: "",
      mobileNo: "",
      employeeNumber: "",
    },
    validationSchema: yup.object({
      firstName: yup
        .string()
        .trim()
        .matches(/^[A-Za-z\s]+$/, "First name must contain only letters")
        .required("Required"),
      lastName: yup
        .string()
        .trim()
        .matches(/^[A-Za-z\s]+$/, "Last name must contain only letters"),
      userPassword: !isEdit
        ? yup
          .string()
          .min(6, "Password must be at least 6 characters")
          .matches(/[0-9]/, "Password must contain at least one number")
          .required("Require")
        : yup
          .string()
          .min(6, "Password must be at least 6 characters")
          .matches(/[0-9]/, "Password must contain at least one number"),
      userEmail: yup
        .string()
        .matches(validations.emailRegExp, "Must be a valid email"),
      mobileNo: yup
        .string()
        .trim()
        .matches(/^[0-9]{10}$/, "Must be a valid mobile number")
        .required("Required"),
      employeeNumber: !isEdit ? yup.string() : yup.string(),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (addPermissionData.length === 0) {
        setSnackBarMessage("Please select permission for user!!");
        setShowSnackBar(true);
        return;
      }
      let userValue: CreateUser = {
        FirstName: values.firstName,
        PhoneNumber: values.mobileNo,
        Password: values.userPassword ?? "",
        UserLogins: userInfoValue,
        LastName: values.lastName ?? "",
        Email: values.userEmail ?? "",
        EmployeeNumber: values.employeeNumber,
      };

      if (userValue && !isEdit) {
        try {
          setSnackBarMessage("Creating user...");
          setShowSnackBar(true);
          const res = await dispatch(createUserInServer(userValue));
          if (res.payload.status == true || res.payload.status == 200) {
            setSnackBarMessage("User Created successfully!!");
            setAddPermissionData([]);
            setIsEdit(false);
            setUserInfoValue([]);
            setUserDataById([]);
            getStoreData();
            formikAddPermission.resetForm();
            formikAddUser.resetForm();
            fetchData();
          } else if (res.meta.requestStatus === "rejected") {
            setSnackBarMessage(res.payload);
            setShowSnackBar(true);
          } else if (res.payload.status == false || res.payload.status == 400) {
            setSnackBarMessage(res.payload.message);
            setShowSnackBar(true);
          } else if (res.payload.status == false || res.payload.status == 500) {
            setSnackBarMessage(res.payload.message);
            setShowSnackBar(true);
          }
          setShowSnackBar(true);
          setIsEdit(false);
        } catch (error) {
          setSnackBarMessage("An error occurred");
          setShowSnackBar(true);
        }
      }
      if (isEdit) {
        const UpdateUserValue: IUpdateUserValue = {
          userId: userId,
          firstName: values.firstName,
          lastName: values.lastName ?? "",
          email: values.userEmail ?? "",
          phoneNumber: values.mobileNo,
          userLogins: updateUserPermissionValue,
          isActive: pendingState,
          employeeNumber: values.employeeNumber
        };

        const areUserEqual = _.isEqual(UpdateUserValue, userDataById);
        if (areUserEqual) {
          setSnackBarMessage("Please change details to update");
          setShowSnackBar(true);
        } else {
          setSnackBarMessage("Editing user...");
          setShowSnackBar(true);
          try {
            setSnackBarMessage("Updating user...");
            setShowSnackBar(true);
            const res = await dispatch(updateUserInServer(UpdateUserValue));
            if (res.payload.status == true || res.payload.status == 200) {
              fetchData();
              setSnackBarMessage("User Updated successfully!!");
              setAddPermissionData([]);
              setUpdateUserPermissionValue([]);
              setUserId("");
              setUserInfoValue([]);
              setUserDataById([]);
              formikAddPermission.resetForm();
              getStoreData();
              resetForm();
              setIsEdit(false);
            } else if (res.meta.requestStatus === "rejected") {
              setSnackBarMessage(res.payload);

            } else if (
              res.payload.status == false ||
              res.payload.status == 400
            ) {
              setSnackBarMessage(res.payload.message);
              setShowSnackBar(true);
            } else if (
              res.payload.status == false ||
              res.payload.status == 500
            ) {
              setSnackBarMessage(res.payload.message);
              setShowSnackBar(true);
            }
            setShowSnackBar(true);

          } catch (error) {
            setSnackBarMessage("An error occurred");
            setShowSnackBar(true);
          }
        }
      }
    },
  });
  const formikAddPermission = useFormik({
    initialValues: {
      storeId: "",
      roleId: "",
    },
    validationSchema: yup.object({
      storeId:
        !isEdit && storeValue.length > 0
          ? yup.string().required("Required")
          : yup.string(),
      roleId: !isEdit ? yup.string().required("Required") : yup.string(),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (!store || (store.length === 0 && !role) || store.length === 0) {
        setShowSnackBar(true);
        setSnackBarMessage("Store value is required");
        return;
      }
      const userPermissionValue: UserInfoValue = {
        storeId: values.storeId,
        roleId: values.roleId,
        userId: userId,
        store: store,
        role: role,
      };
      const param: any = {
        storeId: values.storeId,
        roleId: values.roleId,
        userId: userId,
      };
      setUpdateUserPermissionValue((previous) => [
        ...previous.filter(
          (data: any) => data.storeId !== userPermissionValue.storeId
        ),
        param,
      ]);
      setUserInfoValue((previous) => [
        ...previous.filter(
          (data: any) => data.storeId !== userPermissionValue.storeId
        ),
        userPermissionValue,
      ]);
      setUpdateUserPermissionValue((previous) => [
        ...previous.filter(
          (data: any) => data.storeId !== userPermissionValue.storeId
        ),
        userPermissionValue,
      ]);
      const permissionData: UserInfoValue = {
        store: store,
        role: role,
        storeId: values.storeId,
        roleId: values.roleId,
      };
      let filteredStoreValue = storeValue.filter(
        (item: any) => !store.includes(item.label)
      );
      setStoreValue(
        filteredStoreValue.length > 0
          ? filteredStoreValue
          : [{ label: "No option", id: -1 }]
      );
      setAddPermissionData((previous) => [...previous, permissionData]);
      setRole([]);
      setStore([]);
      resetForm();
    },
  });
  var setstoreTableValue = async (id: number | string) => {
    await formikAddPermission.setFieldValue(
      "storeId",
      storeValue.find((item: any) => item.id === id)?.id
    );
    var currentSelectedStoreValue =
      (await storeValue.length) > 0
        ? storeValue
          .map((data: AutoCompleteType) => {
            if (id == data.id) {
              return data.label;
            }
          })
          .filter((data: any) => data != undefined)
        : [];
    setStore(currentSelectedStoreValue);
    if (store && formikAddPermission.values.roleId) {
      await formikAddPermission.submitForm();
    }
  };
  const setRoleTableValue = async (id: number | string) => {
    const roleData = await formikAddPermission.setFieldValue(
      "roleId",
      roleValue.find((item: any) => item.id === id)?.id || ""
    );
    const currentSelectedRoleLabels = await roleValue
      .filter((data: AutoCompleteType) => data.id === id)
      .map((data: AutoCompleteType) => data.label);
    setRole(currentSelectedRoleLabels);
    if (store && roleData) {
      await formikAddPermission.submitForm();
    }
  };
  const handleClearUserAdmission = (item: any) => {
    const filteredItem: any = mainStore.find(
      (mainItem: any) => mainItem.label == item.store
    );
    const isItemExists = addPermissionData.some(
      (data) => data.store === item.store && data.role === item.role
    );
    const isStoreExists = storeValue?.some(
      (data: any) => data.label === item.store
    );
    if (!isStoreExists) {
      const newStoreValue = [
        { label: item.store, id: filteredItem ? filteredItem.id : null },
      ];
      setStoreValue((previous: any) => [
        ...previous.filter((data: any) => data.id !== -1),
        ...newStoreValue,
      ]);
      const filteredData: UserInfoValue[] = userInfoValue.filter(
        (userItem) => !(userItem.storeId === filteredItem.id)
      );
      setUserInfoValue(filteredData);
      setUpdateUserPermissionValue(filteredData);
    }
    if (isItemExists) {
      setAddPermissionData((previous) =>
        previous.filter(
          (data) => !(data.store === item.store && data.role === item.role)
        )
      );
    } else {
      setAddPermissionData((previous) => [...previous, item]);
    }
  };
  const setRoleClearValue = () => {
    formikAddPermission.setFieldValue("roleId", "");
    setRole([null]);
  };
  const setStoreClearValue = () => {
    formikAddPermission.setFieldValue("storeId", "");
    setStore([]);
  };
  const ActionRenderer = (props: any) => {
    const handleChange = (id: any, active: boolean) => {
      setUpdateToggleUserId(id);
      setDialogMessage(
        !active === true
          ? "Do you want to change the user's active state?"
          : "Do you want to change the user's inactive state?"
      );
      setShowDialog(true);
      setPendingState(!active);
    };
    const handleAgree = async () => {
      setShowDialog(false);
      const newIsActive = pendingState;
      const res = await dispatch(
        getSingleUserByIdFromServer(updateToggleUserId)
      );
      const userData = res.payload.data;
      const userPermissionValue = userData.userPermissions.map((item: any) => ({
        storeId: item.storeId,
        roleId: item.roleId,
        userId: updateToggleUserId,
      }));
      const UpdateUserValue: IUpdateUserValue = {
        userId: updateToggleUserId,
        firstName: userData.firstName,
        lastName: userData.lastName ?? "",
        email: userData.email ?? "",
        isActive: userData.isActive === true ? false : true,
        phoneNumber: userData.phoneNumber,
        userLogins: userPermissionValue,
        employeeNumber: userData.empolyeeNumber,
      };
      const updateToggleRes = await dispatch(
        updateUserInServer(UpdateUserValue)
      );
      if (
        updateToggleRes.payload.status === true ||
        updateToggleRes.payload.statusCode === 200
      ) {
        await fetchData();
        setSnackBarMessage(newIsActive ? "User Active" : "User InActive");
      } else if (updateToggleRes.meta.requestStatus === "rejected") {
        setSnackBarMessage("Connection Refused");
      } else {
        setSnackBarMessage(res.payload.data);
      }
      setShowSnackBar(true);
    };
    const handleDisagree = () => {
      setShowDialog(false);
    };
    const onEdit = async (id: string) => {
      setIsEdit(true);
      try {
        setStoreValue(mainStore);
        const res = await dispatch(getSingleUserByIdFromServer(id));
        const userData = res.payload.data;
        formikAddUser.setFieldValue("firstName", userData.firstName);
        formikAddUser.setFieldValue("lastName", userData.lastName ?? "");
        formikAddUser.setFieldValue("email", userData.email ?? "");
        formikAddUser.setFieldValue("mobileNo", userData.phoneNumber);
        formikAddUser.setFieldValue("employeeNumber", userData.employeeNumber ?? "");
        let userPermissions = userData.userPermissions.map(
          (item: UserInfoValue) => ({
            userLoginId: item.userLoginId,
            storeId: item.storeId,
            storeName: item.storeName,
            roleId: item.roleId,
            roleName: item.roleName,
          })
        );
        const userPermissionValue = userData.userPermissions.map(
          (item: UserInfoValue) => ({
            storeId: item.storeId,
            roleId: item.roleId,
            userId: id,
          })
        );
        setUserInfoValue((previous: UserInfoValue[]) => {
          const existingStoreIds = new Set(
            previous.map((item) => item.storeId)
          );
          const filteredUserPermissionValue = userPermissionValue.filter(
            (item: UserInfoValue) => !existingStoreIds.has(item.storeId)
          );
          return [...previous, ...filteredUserPermissionValue];
        });
        setUpdateUserPermissionValue((previous: UserInfoValue[]) => {
          const existingStoreIds = new Set(
            previous.map((item) => item.storeId)
          );
          const filteredUserPermissionValue = userPermissionValue.filter(
            (item: UserInfoValue) => !existingStoreIds.has(item.storeId)
          );
          return [...previous, ...filteredUserPermissionValue];
        });
        const storeNames = userPermissions.map((perm: any) => perm.storeName);
        setUserId(id);
        const filteredStoreValue = mainStore.filter(
          (item: any) => !storeNames.includes(item.label)
        );
        setStoreValue(
          filteredStoreValue.length > 0
            ? filteredStoreValue.filter((data: any) => data.id !== -1)
            : [{ label: "No option", id: -1 }]
        );
        const permissionData = userPermissions.map((perm: any) => ({
          store: perm.storeName,
          role: perm.roleName,
        }));
        setAddPermissionData(permissionData);
        setUpdateUserPermissionValue(userPermissionValue);
        setPendingState(userData.isActive);
        let param: any = {
          userId: id,
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
          phoneNumber: userData.phoneNumber,
          userLogins: userPermissionValue,
          isActive: userData.isActive,
          employeeNumber: userData.employeeNumber
        };
        setUserDataById(await param);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    return (
      <Box aria-label="Actions">
        <Tooltip title="Edit" placement="bottom-start">
          <IconButton onClick={() => onEdit(props.data.userId)}>
            <EditOutlinedIcon color="primary" />
          </IconButton>
        </Tooltip>
        {userIdCookie != props.data.userId ? (
          <Switch
            checked={props.data.isActive}
            onChange={() =>
              handleChange(props.data.userId, props.data.isActive)
            }
            color="primary"
            name="isActiveSwitch"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        ) : (
          <IconButton sx={{ left: "10px", cursor: "pointer" }}>
            <Tooltip title="Active User" placement="bottom-start">
              <AccountCircleSharpIcon />
            </Tooltip>
          </IconButton>
        )}
        <DialogBox
          message={dialogMessage}
          handleAccept={handleAgree}
          handleCancel={handleDisagree}
          open={showDialog}
        />
      </Box>
    );
  };
  const columnDefs: ColDef[] = [
    { field: "no", headerName: "No", minWidth: 75, valueGetter: 'node.rowIndex + 1' },
    { field: "displayName", headerName: "Name", minWidth: 250 },
    { field: "employeeNumber", headerName: "Emp Number", minWidth: 150 },
    { field: "storeName", headerName: "Store", minWidth: 150 },
    { field: "roleName", headerName: "Role", minWidth: 150 },
    { field: "email", headerName: "Email", minWidth: 100 },
    { field: "phoneNumber", headerName: "Phone Number", minWidth: 150 },
    { field: "actions", headerName: "Actions", cellRenderer: ActionRenderer, minWidth: 150 },
  ];
  return (
    <Box sx={{ height: "100vh" }} mr={2} pt={1}>
      <FormAccordion
        isExpanded={isEdit}
        title={!isEdit ? "Add User" : "Update User"}
        style={{ ...assetStyles.containerStyles }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <TextLabel
                  label="First Name"
                  color={Theme.palette.primary.contrastText}
                  required
                />
                <TextBox
                  type="text"
                  formik={formikAddUser}
                  error={
                    Boolean(formikAddUser.touched.firstName) &&
                    Boolean(formikAddUser.errors.firstName)
                  }
                  fieldpropsName="firstName"
                  helpertext={
                    formikAddUser.touched.firstName
                      ? String(formikAddUser.errors.firstName)
                      : ""
                  }
                  label="First Name"
                  sx={Patientstyle.textboxStyles}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <TextLabel
                  label="Last Name"
                  color={Theme.palette.primary.contrastText}
                />
                <TextBox
                  label="Last Name"
                  type="text"
                  formik={formikAddUser}
                  error={
                    Boolean(formikAddUser.touched.lastName) &&
                    Boolean(formikAddUser.errors.lastName)
                  }
                  fieldpropsName="lastName"
                  helpertext={
                    formikAddUser.touched.lastName
                      ? String(formikAddUser.errors.lastName)
                      : ""
                  }
                  sx={Patientstyle.textboxStyles}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <TextLabel
                  label="Email"
                  color={Theme.palette.primary.contrastText}
                />
                <TextBox
                  type="email"
                  formik={formikAddUser}
                  error={
                    Boolean(formikAddUser.touched.userEmail) &&
                    Boolean(formikAddUser.errors.userEmail)
                  }
                  fieldpropsName="userEmail"
                  helpertext={
                    formikAddUser.touched.userEmail
                      ? String(formikAddUser.errors.userEmail)
                      : ""
                  }
                  label="Email"
                  sx={Patientstyle.textboxStyles}
                />
              </Grid>
              {!isEdit ? (
                <Grid item xs={12} md={6} lg={4}>
                  <TextLabel
                    label="Password"
                    color={Theme.palette.primary.contrastText}
                    required
                  />
                  <TextBox
                    type="password"
                    formik={formikAddUser}
                    error={
                      Boolean(formikAddUser.touched.userPassword) &&
                      Boolean(formikAddUser.errors.userPassword)
                    }
                    fieldpropsName="userPassword"
                    helpertext={
                      formikAddUser.touched.userPassword
                        ? String(formikAddUser.errors.userPassword)
                        : ""
                    }
                    label="Password"
                    sx={Patientstyle.textboxStyles}
                  />
                </Grid>
              ) : null}
              <Grid item xs={12} md={6} lg={4}>
                <TextLabel
                  label="Mobile No"
                  color={Theme.palette.primary.contrastText}
                  required
                />
                <TextBox
                  disable={isEdit}
                  type="text"
                  formik={formikAddUser}
                  error={
                    Boolean(formikAddUser.touched.mobileNo) &&
                    Boolean(formikAddUser.errors.mobileNo)
                  }
                  fieldpropsName="mobileNo"
                  helpertext={
                    formikAddUser.touched.mobileNo
                      ? String(formikAddUser.errors.mobileNo)
                      : ""
                  }
                  label="Mobile No"
                  sx={Patientstyle.textboxStyles}
                />
              </Grid>
              {/* {!isEdit ? ( */}
              <Grid item xs={12} md={6} lg={4}>
                <TextLabel
                  label="Employee Number"
                  color={Theme.palette.primary.contrastText}
                />
                <TextBox
                  type="text"
                  formik={formikAddUser}
                  error={
                    Boolean(formikAddUser.touched.employeeNumber) &&
                    Boolean(formikAddUser.errors.employeeNumber)
                  }
                  fieldpropsName="employeeNumber"
                  helpertext={
                    formikAddUser.touched.employeeNumber
                      ? String(formikAddUser.errors.employeeNumber)
                      : ""
                  }
                  label="Employee Number"
                  sx={Patientstyle.textboxStyles}
                />
              </Grid>
              {/* ) : null} */}
              {isEdit && <Grid item xs={12} md={6} lg={4} />}
              <Grid item xs={12} lg={8}>
                <Accordion
                  elevation={2}
                  sx={{
                    width: { sm: "100%", md: "97.5%" },
                    borderRadius: "10px !important",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{ color: Theme.palette.primary.dark }}
                  >
                    {!storeValue.some((item: any) => item.id == -1)
                      ? " Apply Permission"
                      : " Permission List"}
                  </AccordionSummary>
                  <AccordionDetails sx={{ display: "flex" }}>
                    <Grid container spacing={2}>
                      {!storeValue.some((item: any) => item.id == -1) ? (
                        <Grid item xs={12} md={6} lg={6}>
                          <TextLabel
                            label="Store"
                            color={Theme.palette.primary.main}
                            required
                          />
                          <AutoComplete
                            whiteColorField={true}
                            color={Theme.palette.primary.contrastText}
                            value={store}
                            data={storeValue}
                            title="Store"
                            required={isRequired}
                            onClick={setstoreTableValue}
                            onClear={setStoreClearValue}
                            error={
                              formikAddPermission.touched.storeId &&
                              Boolean(formikAddPermission.errors.storeId)
                            }
                            helperText={
                              formikAddPermission.touched.storeId &&
                              formikAddPermission.errors.storeId
                            }
                          />
                        </Grid>
                      ) : null}
                      {!storeValue.some((item: any) => item.id == -1) ? (
                        <Grid item xs={12} md={6} lg={6}>
                          <TextLabel
                            label="Role"
                            color={Theme.palette.primary.main}
                            required
                          />
                          <AutoComplete
                            whiteColorField={true}
                            value={role}
                            data={roleValue}
                            title="Role"
                            required={isRequired}
                            onClick={setRoleTableValue}
                            onClear={setRoleClearValue}
                            error={
                              formikAddPermission.touched.roleId &&
                              Boolean(formikAddPermission.errors.roleId)
                            }
                            helperText={
                              formikAddPermission.touched.roleId &&
                              formikAddPermission.errors.roleId
                            }
                          />
                        </Grid>
                      ) : null}
                      {addPermissionData.length > 0 &&
                        addPermissionData.map((item, index) => (
                          <Grid item xs={12} md={6} key={index}>
                            <Paper
                              elevation={3}
                              sx={{
                                backgroundColor:
                                  Theme.palette.background.default,
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  color: "black",
                                }}
                              >
                                <Grid p={1}>
                                  <Typography>{item.store}</Typography>
                                  <Typography variant="subtitle2">
                                    {item.role}
                                  </Typography>
                                </Grid>
                                <Grid p={1} sx={{ cursor: "pointer" }}>
                                  <CloseIcon
                                    onClick={() =>
                                      handleClearUserAdmission(item)
                                    }
                                  />
                                </Grid>
                              </Box>
                            </Paper>
                          </Grid>
                        ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12} md={4}>
                <Stack direction="row" spacing={2} mt={1}>
                  <CustomButton
                    variant="contained"
                    onClick={() => formikAddUser.handleSubmit()}
                    text={!isEdit ? "Add User" : "Update User"}
                    sx={Patientstyle.buttonStyles}
                    formik={formikAddUser}
                    isLoading={isLoading}
                  >
                    {!isEdit ? "Add User" : "Update User"}
                  </CustomButton>
                  <CustomButton
                    variant="contained"
                    sx={Patientstyle.clearButton}
                    text="Clear"
                    onClick={() => {
                      formikAddUser.resetForm();
                      formikAddPermission.resetForm();
                      setUserInfoValue([]);
                      setUpdateUserPermissionValue([])
                      setStore([]);
                      setRole([]);
                      getStoreData();
                      setAddPermissionData([]);
                      setIsEdit(false);
                      setIsRequired(false);
                    }}
                    formik={formikAddUser}
                    isLoading={isLoading}
                  >
                    Clear
                  </CustomButton>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormAccordion>
      <Grid item xs={12} mt={1.5}>
        <Title sx={{ color: Theme.palette.primary.main }} title={"User List"} />
      </Grid>
      <Grid item xs={12}>
        <DataTable rows={tableData} columnDefs={columnDefs} />
      </Grid>
      <SnackbarComponent
        setShowSnackBar={setShowSnackBar}
        showSnackBar={showSnackBar}
        snackBarMessage={snackBarMessage}
      />
      <Loader openModal={isLoading} />
    </Box>
  );
};
export default AddUser;
