import { createAsyncThunk } from "@reduxjs/toolkit";
import { getHeaderToken } from "../../Utilities/header";
import axios from "axios";

export const GetStoreList = createAsyncThunk(
    "Store/getList",
    async (token: string) => {
        try {
            const baseURL = process.env.REACT_APP_BASEURL;
            const URL = `${baseURL}/api/Store/list/0`
            const response = await axios.get(URL, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            return response.data;
        } catch (e: any) {
            return e.response;
        }
    }
);
