import * as Yup from "yup";


export const LoginSchema = Yup.object().shape({
    username: Yup.string()
        .min(6, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    password: Yup.string()
        .min(4, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    otp: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
});
interface Permissions {
    create: boolean;
    delete: boolean;
    list: boolean;
    modify: boolean;
    view: boolean;
    viewAmount: boolean;
}

interface PagePermission {
    moduleName: string;
    permissions: Permissions;
}


export interface UserPermissions {
    pagePermissions: PagePermission[];
    roleId: string;
    roleName: string;
    storeId: number|string;
    storeName: string;
    userLoginId: number;
}
export interface UserVerifyParams{
    userId:string;
    storeId:number;
}