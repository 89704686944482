import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import ButtonGroup from "@mui/material/ButtonGroup";
import IconButton from "@mui/material/IconButton";
import HomeIcon from "@mui/icons-material/Home";
import Groups2Icon from "@mui/icons-material/Groups2";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import SettingsIcon from "@mui/icons-material/Settings";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { Tooltip, useTheme } from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import {
  iconButtonStyle,
  LayoutStyle,
  InactiveButtonStyle,
} from "../../layouts/SideNavbarStyle";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import Theme from "../../theme/theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import ManageAccountsSharpIcon from "@mui/icons-material/ManageAccountsSharp";
import AccessibleSharpIcon from '@mui/icons-material/AccessibleSharp';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DialogBox from "../DialogBox/DialogBox";
import { resetState, useAppDispatch } from "../../Redux/Store";
const SideNavbar = () => {

  const [showDialog, setShowDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [hovered, setHovered] = useState<boolean>(false);

  const isSmallScreen = useMediaQuery(Theme.breakpoints.down("md"));
  const theme = useTheme();

  const dispatch = useAppDispatch();


  const handleOpenNavbarDuringHover = () => {
    setHovered(true);
  };
  const handleCloseNavbarOnLeave = () => {
    setHovered(false);
  };

  const location = useLocation();
  const currentPath = location.pathname;
  const rootPath = location.pathname == "/dashboard";

  const navigate = useNavigate();
  const handlePageNavigate = (val: string) => {
    if (val == "Adduser") {
      navigate("/Adduser");
    } else if (val == "manageUser") {
      navigate("/manageUser");
    } else if (val == "AssetPage") {
      navigate("/asset");
    } else if (val == "LocationPage") {
      navigate("/locations");
    } else if (val == "/") {
      navigate("/");
    } else if (val == "/dashboard") {
      navigate("/dashboard");
    } else if (val == "/addPatients") {
      navigate("/addPatients");
    } else if (val == "shift") {
      navigate("/shift");
    } else if (val == "report") {
      navigate("/report")
    }
  };
  const confirmLogout = () => {
    setShowDialog(true);
    setDialogMessage("Are you sure want to logout?")
  }
  const handleDisagree = () => {
    setShowDialog(false);
  }

  const handleLogout = () => {
    try {
      const isAuthenticated = Cookies.get("accessToken");
      if (isAuthenticated) {
        Object.keys(Cookies.get()).forEach(function(cookieName) {
          Cookies.remove(cookieName);
        });
        dispatch(resetState());
        navigate("/");
      }
    } catch (e) {
      throw e;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "auto",
        position: "fixed",
        minHeight: "100vh",
      }}
    >
      <Grid container sx={{ width: "100%", height: "100vh" }}>
        <ButtonGroup
          orientation={isSmallScreen ? "horizontal" : "vertical"}
          sx={{
            background:
              "linear-gradient(100deg, rgba(46,37,134,1) 100%, rgba(93,81,204,1) 100%);",
            boxShadow: (Theme) => Theme.shadows[10],
            padding: "1rem",
            borderRadius: "0 2rem 2rem 0",
            alignItems: "center",
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-around"}
          >
            <Tooltip title="Home">
              <IconButton
                style={rootPath ? InactiveButtonStyle : iconButtonStyle}
                onClick={() => handlePageNavigate("/dashboard")}
              >
                <HomeIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Manage Users">
              <IconButton
                // sx={iconButtonStyle}
                style={
                  currentPath.includes("manageUser")
                    ? InactiveButtonStyle
                    : iconButtonStyle
                }
                onClick={() => handlePageNavigate("manageUser")}
              >
                <Groups2Icon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Manage Assets">
              <IconButton
                style={
                  currentPath.includes("asset")
                    ? InactiveButtonStyle
                    : iconButtonStyle
                }
                onClick={() => handlePageNavigate("AssetPage")}
              >
                {/* <LocalHospitalIcon /> */}
                <AccessibleSharpIcon />
              </IconButton>
            </Tooltip>

            {/* <Tooltip title="Patients">
              <IconButton
                // sx={iconButtonStyle}
                style={
                  currentPath.includes("addPatients")
                    ? InactiveButtonStyle
                    : iconButtonStyle
                }
                onClick={() => handlePageNavigate("/addPatients")}
              >
                <ManageAccountsSharpIcon />
              </IconButton>
            </Tooltip> */}

            {/* <Tooltip title="Settings">
              <IconButton sx={iconButtonStyle}>
                <SettingsIcon />
              </IconButton>
            </Tooltip> */}

            <Tooltip title="Manage Location">
              <IconButton
                style={
                  (currentPath.includes("locations") || currentPath.includes("floor") || currentPath.includes("block"))
                    ? InactiveButtonStyle
                    : iconButtonStyle
                }
                onClick={() => handlePageNavigate("LocationPage")}
              >
                <AddLocationAltIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Manage Shifts">
              <IconButton
                style={
                  currentPath.includes("shift")
                    ? InactiveButtonStyle
                    : iconButtonStyle
                }
                onClick={() => handlePageNavigate("shift")}
              >
                <AccessTimeFilledIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Report">
              <IconButton
                style={
                  currentPath.includes("report")
                    ? InactiveButtonStyle
                    : iconButtonStyle
                }
                onClick={() => handlePageNavigate("report")}
              >
                <AssignmentIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Logout">
              <IconButton onClick={confirmLogout} sx={iconButtonStyle}>
                <PowerSettingsNewIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </ButtonGroup>
      </Grid>
      <DialogBox
        message={dialogMessage}
        handleAccept={() => handleLogout()}
        handleCancel={handleDisagree}
        open={showDialog}
      />
    </Box>
  );
};

export default SideNavbar;
