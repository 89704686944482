import { createAsyncThunk } from "@reduxjs/toolkit";
import { getStoreId } from "../../Utilities/Store";
import axios from "axios";
import { getHeaderToken } from "../../Utilities/header";
import { CancelRequestTask, UpdateCancelRequest } from "../../Model/CancelRequestModel";

export const GetCancelRequestList = createAsyncThunk(
    "task/cancelRequest", async () => {
        try {
            const URL = process.env.REACT_APP_BASEURL + `/api/Task/cancelRequest/${await getStoreId()}`;
            const response = await axios.get(URL, await getHeaderToken());
            return response.data;
        } catch (e: any) {
            return e.response;
        }
    }
)

export const UpdateCancelRequestList = createAsyncThunk(
    "task/updateCancelRequest",
    async (data: UpdateCancelRequest) => {
        try {
            const URL = process.env.REACT_APP_BASEURL + `/api/Task/update`;
            const response = await axios.put(URL, data, await getHeaderToken());
            return response.data;
        } catch (e: any) {
            return e.response;
        }
    }
)

export const UpdateTaskReassign = createAsyncThunk(
    "task/taskReassign",
    async (data: any) => {
        try {
            const URL = process.env.REACT_APP_BASEURL + `/api/Task/updateTaskCurrentInfo/${data.taskId}/${await getStoreId()}`;
            const response = await axios.put(URL, data, await getHeaderToken());
            return response.data;
        } catch (e: any) {
            return e.response;
        }
    }
)