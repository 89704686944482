import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiResponse } from "../../Utilities/ApiResponse";
import axios from "axios";
import Cookies from "js-cookie";
import { getHeaderToken } from "../../Utilities/header";
import { UserVerifyParams } from "../../Model/Login";

//add second type argument for specifying type of passing parameter
interface LoginData {
    username: string,
    password: string,
    otp: string,
}

export const UserLogin = createAsyncThunk(
    "login",
    async (action: LoginData) => {
        try {
            const LoginPostURL = process.env.REACT_APP_BASEURL + "/api/account/login";
            var headers = { "Client-Type": process.env.REACT_APP_CLIENT_TYPE };
            const response = await axios.post(LoginPostURL, action, { headers });

            if (response && response.statusText === "OK") {
                let total_minutes = Number(Number(response.data.expires_in) / 60);
                let total_hours = Number(total_minutes / 60);
                let days: any = Number(total_hours / 24);
                Cookies.set("accessToken", response.data.token.accessToken, {
                    expires: days,
                });
            }
            return response;
        } catch (e: any) {
            return e.response;
        }
    }
);

export const UserProfile = createAsyncThunk(
    "user/profile",
    async () => {
        try {
            const LoginPostURL = process.env.REACT_APP_BASEURL + "/api/User/profile";
            const response = await axios.get(LoginPostURL, await getHeaderToken());
            return response.data;
        } catch (e: any) {
            return e.response;
        }
    }
);

export const UserLoginVerify = createAsyncThunk(
    "login/verify",
    async (data:UserVerifyParams) => {
        try {
            const LoginPostURL = process.env.REACT_APP_BASEURL + `/api/account/verify/${data.userId}/${data.storeId}`;
            var headers = { "Client-Type": process.env.REACT_APP_CLIENT_TYPE };
            const response = await axios.get(LoginPostURL,{ headers });
            return response;
        } catch (e: any) {
            return e.response;
        }
    }
);