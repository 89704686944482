import { createSlice } from "@reduxjs/toolkit";
import { createShift, deleteShiftAssignment, getAssignedUsers, getShift, getShiftAssignmentInitialData, getShiftList, updateShift } from "../Thunks/ShiftThunk";
import { shiftAssignentLocationInitial, shiftAssignmentInitialModel, ShiftListModel, ShiftUserAssignmentModel } from "../../Model/ShiftModels";
import { convertUTCToLocal } from "../../Utilities/Common";

interface initialState {
    isLoading: boolean;
    shifts: ShiftListModel[];
    shift: {};
    shiftIntervalTypes: [];
    shiftAssignmentInitialData: shiftAssignmentInitialModel;
    AssignedShiftUsers: ShiftUserAssignmentModel;
}

const initialState: initialState = {
    isLoading: false,
    shifts: [],
    shift: {},
    shiftIntervalTypes: [],
    AssignedShiftUsers: {
        shiftId: 0,
        assignedUsers: [],
        shiftName: "",
        startTime: new Date(),
        endTime: new Date()
    },
    shiftAssignmentInitialData: {
        shifts: [],
        locations: [],
        users: []
    }
}

const shiftSlice = createSlice({
    name: "shift",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(createShift.pending, (state, action) => {
            state.isLoading = true;
        })
            .addCase(createShift.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(createShift.rejected, (state, action) => {
                state.isLoading = false;
            }).addCase(updateShift.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(updateShift.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(updateShift.rejected, (state, action) => {
                state.isLoading = false;
            })
            .addCase(getShiftList.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getShiftList.fulfilled, (state, action) => {
                state.shifts = action.payload.data;
                state.isLoading = false;
            })
            .addCase(getShiftList.rejected, (state, action) => {
                state.isLoading = false;
            })
            .addCase(getShift.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getShift.fulfilled, (state, action) => {
                state.shift = action.payload.data;
                state.isLoading = false;
            })
            .addCase(getShift.rejected, (state, action) => {
                state.isLoading = false;
            })
            .addCase(getShiftAssignmentInitialData.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getShiftAssignmentInitialData.fulfilled, (state, action) => {
                let data = action.payload.data;
                if (data && data.shifts && data.shifts.length > 0) {
                    state.shiftAssignmentInitialData.shifts = data.shifts.map((item: any) => {
                        return {
                            id: item.shiftId,
                            label: item.shiftName,
                            startTime: convertUTCToLocal(item.startTime),
                            endTime: convertUTCToLocal(item.endTime)
                        }
                    });
                }
                if (data && data.locations && data.locations.length > 0) {

                    state.shiftAssignmentInitialData.locations = data.locations.map((item: shiftAssignentLocationInitial) => {
                        return {
                            id: item.locationId,
                            label: item.locationName,
                            locationId: item.locationId,
                            locationName: item.locationName,
                            locationTypeId: item.locationTypeId,
                            floorId: item.floorId,
                            locationTypeName: item.locationTypeName,
                            floorName: item.floorName,
                            blockName: item.blockName,
                            blockId: item.blockId
                        }
                    });
                }
                if (data && data.users && data.users.length > 0) {
                    let users = data.users.map((item: any) => {
                        return {
                            id: item.userId,
                            label: item.displayName,
                            userCurrentAssignedDate: item.userCurrentAssignedDate
                        }
                    });
                    state.shiftAssignmentInitialData.users = users;
                }

                state.isLoading = false;
            })
            .addCase(getShiftAssignmentInitialData.rejected, (state, action) => {
                state.isLoading = false;
            })
            .addCase(getAssignedUsers.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getAssignedUsers.fulfilled, (state, action) => {
                state.AssignedShiftUsers = action.payload.data;
                state.isLoading = false;
            })
            .addCase(getAssignedUsers.rejected, (state, action) => {
                state.isLoading = false;
            })
            .addCase(deleteShiftAssignment.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(deleteShiftAssignment.rejected, (state, action) => {
                state.isLoading = false;
            })
            .addCase(deleteShiftAssignment.pending, (state, action) => {
                state.isLoading = true;
            })
    }
})

export const shiftReducer = shiftSlice.reducer;