import {
  Box,
  Button,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Typography,
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useEffect, useState } from "react";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Title from "../../Components/Title/Title";
import TextBox from "../../Components/TextBox/TextBox";
import AddIcon from "@mui/icons-material/Add";
import { useAppDispatch, useAppSelector } from "../../Redux/Store";
import { GetLocationDetails } from "../../Redux/Thunks/LocationThunk";
import { BlockEntity } from "../../Model/BlockModel";
import AutoComplete from "../../Components/AutoComplete/AutoComplete";
import { FloorEntity } from "../../Model/FloorModel";
import { LocationTypeEntity } from "../../Model/LocationTypeModel";
import { useNavigate } from "react-router-dom";
import { AutoComplete as AutoCompleteType } from "../../Model/AutoCompleteModel";
import {
  LocationButtonStyles,
  LocationFieldsStyles,
  SetBackgroundStyle,
} from "../../layouts/SideNavbarStyle";
import Loader from "../../Components/Loader/Loader";
import { AddLocationProps } from "../../Model/Location";
import BulkUploadButton from "../../Components/BulkUpload/BulkUploadButton";
import { assetStyles } from "../Asset/AssetStyles";
import CustomButton from "../../Components/Button/Button";
import TextLabel from "../../Components/Label/TextLabel";
import Theme from "../../theme/theme";
import { Patientstyle } from "../Patient/PatientStyle";
import { useRef } from "react";
import { ExpandMore } from "@mui/icons-material";
import FormAccordion from "../../Components/Accordion/FormAccordion";

function AddLocation(props: AddLocationProps) {
  var {
    formikLocation,
    selectedBlock,
    selectedFloor,
    selectedLocationType,
    setSelectedLocationType,
    setSelectedBlock,
    setSelectedFloor,
    id,
    onClear,
    setShowSnackBar,
    setSnackBarMessage
  } = props;
  const navigate = useNavigate();
  const [open, setOpen] = useState(0);
  const [blockData, setBlockData] = useState<any>([]);
  const [FloorData, setFloorData] = useState<any>([]);
  const [FloorDataBackUp, setFloorDataBackUp] = useState<any>([]);
  const [LocationTypeData, setlocationTypeData] = useState<any>([]);

  const { locationDetails, location, isLoading } = useAppSelector(
    (store) => store.location
  );

  const dispatch = useAppDispatch();


  useEffect(() => {
    if (id > 0) {
      setOpen(id);
      formikLocation.setFieldValue("block", location.blockId);
      formikLocation.setFieldValue("floor", location.floorId);
      formikLocation.setFieldValue("locationType", location.locationTypeId);
      formikLocation.setFieldValue("room", location.locationName);
    }
  }, [id]);

  useEffect(() => {
    if (locationDetails && locationDetails?.block.length > 0) {
      let newBlockData = locationDetails?.block.map((data: BlockEntity) => {
        return { label: data.blockName, id: data.blockId };
      });
      let newFloors = locationDetails?.floor.map((data: FloorEntity) => {
        return { label: data.floorName, id: data.floorId, blockId: data.blockId };
      });
      let newLocationType = locationDetails?.locationTypes.map(
        (data: LocationTypeEntity) => {
          return { label: data.locationTypeName, id: data.locationTypeId };
        }
      );
      setBlockData(newBlockData);
      setFloorDataBackUp(newFloors);
      setlocationTypeData(newLocationType);
    }
  }, [locationDetails?.block.length && locationDetails?.floor.length]);

  useEffect(() => {
    const GetDetails = async () => {
      await dispatch(GetLocationDetails());
    };
    GetDetails();
  }, []);

  useEffect(() => {
    if (location) {
      let newFloors: any[] = [];
      if (locationDetails !== null && locationDetails?.block.length > 0) {
        newFloors = locationDetails?.floor.map((data: FloorEntity) => {
          return { label: data.floorName, id: data.floorId, blockId: data.blockId };
        }).filter((data: any) => {
          return data.blockId == location.blockId;
        });
        setFloorData(newFloors);
      }

      let currentSelectedBlock =
        blockData.length > 0
          ? blockData.find((data: AutoCompleteType) => location.blockId == data.id) || null
          : null;

      let currentSelectedFloor =
        FloorData.length > 0 || FloorDataBackUp.length > 0
          ? newFloors.find((data: AutoCompleteType) => location.floorId == data.id) || null
          : null;

      let currentSelectedLocationType =
        LocationTypeData.length > 0
          ? LocationTypeData.find((data: AutoCompleteType) => location.locationTypeId == data.id) || null
          : null;
      setSelectedBlock(currentSelectedBlock);
      setSelectedFloor(currentSelectedFloor);
      setSelectedLocationType(currentSelectedLocationType);
    }
  }, [location.locationId]);

  var setBlockvalue = (id: number | string) => {
    formikLocation.setFieldValue("block", id);
    let newFloors = locationDetails?.floor.map((data: FloorEntity) => {
      return { label: data.floorName, id: data.floorId, blockId: data.blockId };
    }).filter((data: any) => {
      return data.blockId == id;
    });
    setFloorData(newFloors);
    let currentSelectedBLock =
      blockData.length > 0
        ? blockData
          .map((data: AutoCompleteType) => {
            if (id == data.id) {
              return data.label;
            }
          })
          .filter((data: any) => data != undefined)
        : [];
    setSelectedBlock(currentSelectedBLock);
  };

  var setFloorvalue = (id: number | string) => {
    formikLocation.setFieldValue("floor", id);
    let currentSelectedFloor =
      FloorDataBackUp.length > 0
        ? FloorDataBackUp.map((data: AutoCompleteType) => {
          if (id == data.id) {
            return data.label;
          }
        }).filter((data: any) => data != undefined)
        : [];
    setSelectedFloor(currentSelectedFloor);
  };

  var setlocationTypevalue = (id: number | string) => {
    formikLocation.setFieldValue("locationType", id);
    let currentSelectedLocationtype =
      LocationTypeData.length > 0
        ? LocationTypeData.map((data: AutoCompleteType) => {
          if (id == data.id) {
            return data.label;
          }
        }).filter((data: any) => data != undefined)
        : [];
    setSelectedLocationType(currentSelectedLocationtype);
  };

  const onFloorClicked = () => {
    if (!selectedBlock) {
      setSnackBarMessage("Please select block");
      setShowSnackBar(true);
    }
  }
  const setBlockClearValue = () => {
    formikLocation.setFieldValue("block", location.blockId);
    setSelectedBlock(null);
    setFloorData([]);
    formikLocation.setFieldValue("floor", null);
    setSelectedFloor(null);
  };
  const setFloorClearValue = () => {
    formikLocation.setFieldValue("floor", null);
    setSelectedFloor(null);
  };
  const setLocationClearValue = () => {
    setSelectedLocationType(null);
    formikLocation.setFieldValue("locationType", null);

  };
  return (
    <>
      <FormAccordion isExpanded={open !== 0 ? true : false} title={id < 1 ? "Add Location" : "Update Location"} style={assetStyles.containerStyles}>
        <Grid container >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3.9}>
              <Grid container>
                <Grid item xs={10}>
                  <TextLabel label="Block" color={Theme.palette.common.white} required />
                  <AutoComplete
                    value={selectedBlock}
                    data={blockData}
                    title="Block"
                    onClick={setBlockvalue}
                    onClear={setBlockClearValue}
                    error={
                      formikLocation.touched.block &&
                      Boolean(formikLocation.errors.block)
                    }
                    helperText={
                      formikLocation.touched.block
                        ? formikLocation.errors.block
                        : ""
                    }
                    sx={Patientstyle.selectBoxStyle}
                  />
                </Grid>
                <Grid item xs={2} sx={{ marginTop: "30px" }}>
                  <IconButton
                    onClick={() => navigate("/block")}
                    aria-label="Add Block"
                    color="primary"
                    sx={{ color: "white" }}
                  >
                    <AddIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={3.9}>
              <Grid container>
                <Grid item xs={10}>
                  <TextLabel label="Floor" color={Theme.palette.common.white} required />
                  <AutoComplete
                    value={selectedFloor}
                    data={FloorData}
                    title="Floor"
                    onClick={setFloorvalue}
                    onClear={setFloorClearValue}
                    error={
                      formikLocation.touched.floor &&
                      Boolean(formikLocation.errors.floor)
                    }
                    helperText={
                      formikLocation.touched.floor
                        ? formikLocation.errors.floor
                        : ""
                    }
                    sx={Patientstyle.selectBoxStyle}
                    onAutoCompleteClick={id > 0 ? undefined : onFloorClicked}
                  />
                </Grid>
                <Grid item xs={2} sx={{ marginTop: "30px" }}>
                  <IconButton
                    onClick={() => navigate("/floor")}
                    aria-label="Add Floor"
                    color="primary"
                    sx={{ color: "white" }}
                  >
                    <AddIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={3.9}>
              <TextLabel
                label="Location Type"
                color={Theme.palette.common.white}
                required
              />
              <AutoComplete
                value={selectedLocationType}
                data={LocationTypeData}
                title="Location Type"
                onClick={setlocationTypevalue}
                onClear={setLocationClearValue}
                error={
                  formikLocation.touched.locationType &&
                  Boolean(formikLocation.errors.locationType)
                }
                helperText={
                  formikLocation.touched.locationType
                    ? formikLocation.errors.locationType
                    : ""
                }
                sx={Patientstyle.selectBoxStyle}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3.9}>
              <TextLabel label="Name" color={Theme.palette.common.white} required />
              <TextBox
                type="text"
                formik={formikLocation}
                error={
                  Boolean(formikLocation.touched.room) &&
                  Boolean(formikLocation.errors.room)
                }
                fieldpropsName="room"
                helpertext={
                  formikLocation.touched.room
                    ? String(formikLocation.errors.room)
                    : ""
                }
                label="Name"
                sx={Patientstyle.textboxStyles}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end" spacing={2} marginTop={2}>
            <Grid item>
              <CustomButton
                onClick={() => formikLocation.handleSubmit()}
                variant="outlined"
                sx={LocationButtonStyles}
              >
                {/* {id === 0 ? "Add Location" : "Update Location"} */}
                {id < 1 ? "Add Location" : "Update Location"}
              </CustomButton>
            </Grid>
            <Grid item>
              <CustomButton
                variant="outlined"
                onClick={onClear}
                sx={Patientstyle.clearButton}
              >
                Clear
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      </FormAccordion>
      <Loader openModal={isLoading} />
    </>
  );
}

export default AddLocation;
