import { createSlice } from "@reduxjs/toolkit";
import { BlockAndFloor, Location } from "../../Model/Location";
import { GetLocationById, GetLocationDetails, GetLocationList } from "../Thunks/LocationThunk";
import { GetBlockById, GetBlockList } from "../Thunks/BlockThunk";
import { blockInitialState } from "../../Model/BlockModel";

const BLockSlice = createSlice({
    name: "Block",
    initialState: blockInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetBlockList.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(GetBlockList.fulfilled, (state, action) => {
                state.isLoading = false;
                if (action.payload.status == true) {
                    state.Blocks = action.payload.data
                }
                else {
                    state.Blocks = null;
                };
            })
            .addCase(GetBlockList.rejected, (state, action) => {
                state.isLoading = false;
            })
            .addCase(GetBlockById.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(GetBlockById.fulfilled, (state, action) => {
                state.isLoading = false;
                if (action.payload.status == true) {
                    state.Block = action.payload.data
                }
                else {
                    state.Block = null;
                };
            })
            .addCase(GetBlockById.rejected, (state, action) => {
                state.isLoading = false;
            })
    }
})

export const blockReducer = BLockSlice.reducer;