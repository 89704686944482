import { Autocomplete, Grid, InputAdornment, lighten, styled, TextField } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AssetAutoCompleteProps, AssetAutoSelectDataParams, AssetData, AssetGroupedByBlockAndFloor } from "../../Model/AutoCompleteModel";
import { nanoid } from "@reduxjs/toolkit";

interface LocationData {
    blockName: string;
    floorName: string;
    assetId: number;
    assetName: string;
    // Add other relevant fields if needed
}

const GroupHeader = styled("div")(({ theme }) => ({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    color: theme.palette.common.white,
    backgroundColor: lighten(theme.palette.primary.dark, 0.5),
}));

const GroupItems = styled("ul")({
    padding: 0,
});

function convertData(grouped: AssetGroupedByBlockAndFloor) {
    const result: any[] = [];
    for (const [blockName, floors] of Object.entries(grouped)) {
        for (const [floorName, assets] of Object.entries(floors)) {
            assets.forEach((asset: LocationData) => {
                result.push({
                    key: nanoid(),
                    title: `${blockName}-${floorName}`,
                    value: asset.assetName,
                    id: asset.assetId,
                    label: asset.assetName,
                });
            });
        }
    }
    return result;
}

function AssetAutoComplete(props: AssetAutoCompleteProps) {
    const {
        data,
        title,
        onClick,
        value,
        error,
        required,
        helperText,
        onClear,
        color,
        sx,
    } = props;

    const [assetData, setAssetData] = useState<any[]>([]);
    
    
    const groupByBlockAndFloor = useCallback(async () => {
        const grouped: AssetGroupedByBlockAndFloor = {} as AssetGroupedByBlockAndFloor;
        for (const asset of data) {
            const { blockName, floorName } = asset;
    
            if (blockName && floorName) {
                if (!grouped[blockName]) {
                    grouped[blockName] = {};
                }
    
                if (!grouped[blockName][floorName]) {
                    grouped[blockName][floorName] = [];
                }
    
                grouped[blockName][floorName].push(asset);
            }
        }
    
        const result = await convertData(grouped);
        setAssetData(result);
    }, [data]);
    
    
    useEffect(() => {
        if (data.length > 0) {
            groupByBlockAndFloor();
        }
    }, [data, groupByBlockAndFloor]);
    const options = useMemo(
        () =>
            assetData.sort((a: any, b: any) =>
                -b.title.localeCompare(a.title)
            ),
        [assetData]
    );

    return (
        <div>
            <Autocomplete
                value={value}
                disablePortal
                id="combo-box-demo"
                options={options}
                groupBy={(option) => option.title}
                isOptionEqualToValue={(option, value) => option.id == value.id}
                getOptionLabel={(option) => option.label}
                fullWidth
                className={!value && error ? "shake" : ""}
                onChange={(event, selectedOption) => {
                    if (selectedOption) {
                        onClick(selectedOption.id);
                    }
                }}
                onInputChange={(event, inputValue, reason) => {
                    if (reason === "clear") {
                        onClear?.();
                    }
                }}
                renderGroup={(params) => (
                    <li key={params.key}>
                        <GroupHeader>{params.group}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                    </li>
                )}
                getOptionDisabled={(option) => option.label === "No option"}
                renderOption={(props, option) => (
                    <li {...props} style={{ color: "black" }}>
                        {option.label}
                    </li>
                )}
                ListboxProps={{
                    sx: {
                        maxHeight: "150px",
                        zIndex: 2,
                    },
                }}
                renderInput={(params) => (
                    <Grid container alignItems="center">
                        <TextField
                            {...params}
                            required={required}
                            error={!value && error}
                            helperText={helperText}
                            className={!value && error ? "shake" : ""}
                            sx={{
                                "& .MuiInputLabel-root": {
                                    color: "black",
                                    fontSize: "small !important",
                                    backgroundColor: "transparent",
                                },
                                "& .MuiOutlinedInput-root": {
                                    backgroundColor: "white",
                                    borderRadius: "10px",
                                    "& fieldset": {
                                        color: "black",
                                    },
                                },
                                "& .MuiInputBase-input": {
                                    color: "black",
                                    minHeight: "unset",
                                    padding: "2px !important",
                                    fontSize: "small",
                                },
                                ...sx,
                            }}
                            placeholder={title}
                        />
                    </Grid>
                )}
            />
        </div>
    );
}

export default AssetAutoComplete;
