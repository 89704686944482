import { createSlice, findNonSerializableValue } from "@reduxjs/toolkit";
import { UserLogin } from "../Thunks/LoginThunk";
import { ApiResponse, LocalResponse, DataTypeofAlertData } from "../../Utilities/ApiResponse";
import { Avatar } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';


let initialState: LocalResponse = {
    isLoading: false,
    data: [
        {
            alertId: 1,
            avatar: <Avatar />,
            title: "Asset is Missing",
            titleLogo: <CancelIcon />,
            content: "Please submit your quarterly figures for Q2 by EoB on August 15 - Sep 5, 2030.",
            update: "Asset Information",
            time: "4 minutes",
        },
        {
            alertId: 2,
            avatar: <Avatar />,
            title: "Finished the Shift & Lodged out from the System",
            titleLogo: null,
            content: "Attender ## Left the Designated Place",
            update: "Staff Update",
            time: "10 minutes",
        },
        {
            alertId: 3,
            avatar: <Avatar />,
            title: "Attender ## Finished the Task",
            titleLogo: null,
            content: "Attender ## Finished the Task from Room # and Parked the asset @ Room#",
            update: "Task Update",
            time: "32 minutes",
        },
        {
            alertId: 4,
            avatar: <Avatar />,
            title: `Change in Staffing for Job "Job 101"`,
            titleLogo: null,
            content: "The Asset or Attender Modified at the end place",
            update: "Task Update",
            time: "5 hours",
        }
    ],
    error: null,
}


const AlertSlice = createSlice({
    name: 'AlertBoxSlice',
    initialState,
    reducers: {
        deleteAlert(state, action) {
            const getId = action.payload;
            const updatedData = state.data.filter(alert => alert.alertId !== getId);
            return { ...state, data: updatedData }
        },
    },
    extraReducers: (builder) => { }
})

export const { deleteAlert } = AlertSlice.actions;

export const alertReducer = AlertSlice.reducer;