import { createSlice } from "@reduxjs/toolkit";
import { InitialReportState } from "../../Model/ReportModel";
import { GetAttendanceReportData, GetAttenderList, GetTaskReportData } from "../Thunks/ReportThunk";

var initialData: InitialReportState = {
    Attendance: [],
    isLoading: false,
    Task: [],
    Attender:[],
}

const ReportSlice = createSlice({
    name: "Report",
    initialState: initialData,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetTaskReportData.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(GetTaskReportData.fulfilled, (state, action) => {
                state.isLoading = false;
                if (action.payload?.status == true) {
                    state.Task = action.payload.data
                }
                else {
                    state.Task = initialData.Task;;
                }
            })
            .addCase(GetTaskReportData.rejected, (state, action) => {
                state.isLoading = false;
            })
            .addCase(GetAttendanceReportData.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(GetAttendanceReportData.fulfilled, (state, action) => {
                state.isLoading = false;
                
                if (action.payload.status == true) {
                    state.Attendance = action.payload.data
                }
                else {
                    state.Attendance = [];
                }
            })
            .addCase(GetAttendanceReportData.rejected, (state, action) => {
                state.isLoading = false;
            })
            .addCase(GetAttenderList.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(GetAttenderList.fulfilled, (state, action) => {

                state.isLoading = false;
                if (action.payload.status == true) {
                    state.Attender = action.payload.data
                }
            })
            .addCase(GetAttenderList.rejected, (state, action) => {
                state.isLoading = false;
            })
    }
})

export const reportReducer = ReportSlice.reducer;