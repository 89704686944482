import { AgGridEvent, ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useMemo } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "./DataTable.css";
import { Box } from "@mui/material";
import { ScrollBarStyle } from "../ScrollBarStyle";
interface dataTableProps {
  rows: any[];
  columnDefs: ColDef[];
}

const DataTable = ({ rows, columnDefs }: dataTableProps) => {
  const onSortChanged = (e: AgGridEvent) => {
    e.api.refreshCells({ force: true });
  };
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      filter: true,
      flex: 1,
    };
  }, []);
  return (
    <Box
      className="ag-theme-quartz"
      style={{
        height: "70.5vh",
        width: "100%"
       
      }}
    >
      <AgGridReact
      
        rowData={rows}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowHeight={40}
        onSortChanged={onSortChanged}
        gridOptions={{
          pagination: true,
          paginationPageSize: 10,
          paginationPageSizeSelector: [10, 20, 50, 100],
        }}
      />
    </Box>
  );
};

export default DataTable;
