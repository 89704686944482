import React, { useMemo, useRef, useState } from 'react'
import { Box, Dialog, Grid, IconButton } from '@mui/material';
import { dialogContainer, pv10 } from '../ShiftStyles';
import { userSelectionPropsModel } from '../../../Model/ShiftModels';
import { AgGridReact } from 'ag-grid-react';
import { AgGridEvent, ColDef, GridReadyEvent } from 'ag-grid-community';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "../Shift.css";
import { AutoComplete } from '../../../Model/AutoCompleteModel';
import IconTextBox from '../../../Components/TextBox/IconTextBox';
import SearchIcon from '@mui/icons-material/Search';
import CustomButton from '../../../Components/Button/Button';
import { LocationButtonStyles } from '../../../layouts/SideNavbarStyle';
import { convertUTCToLocal } from '../../../Utilities/Common';

const UserSelection = ({ open, onClose, onAdd, rowData, selectedUsers, setSelectedUsers, setSelectedUserNames }: userSelectionPropsModel) => {

    const gridRef = useRef<AgGridReact<AutoComplete>>(null);

    const [searchText, setSearchText] = useState("");

    const onSortChanged = (e: AgGridEvent) => {
        e.api.refreshCells({ force: true });
    };

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            filter: true,
            flex: 1,
        };
    }, []);

    const handleRowSelect = (event: any) => {
        if (event.node.selected) {
            let filteredUser = selectedUsers.find((user) =>
                user === event.data.id && event.data.userCurrentAssignedDate === null
            );

            if (!filteredUser && event.data.userCurrentAssignedDate === null) {
                setSelectedUsers((prevSelectedUsers) => [...prevSelectedUsers, event.data.id]);
                setSelectedUserNames((prevSelectedUserNames) => [...prevSelectedUserNames, event.data.label]);
            }
        } else {
            setSelectedUsers((prevSelectedUsers) =>
                prevSelectedUsers.filter((userId) => userId !== event.data.id)
            );
            setSelectedUserNames((prevSelectedUsers) =>
                prevSelectedUsers.filter((userId) => userId !== event.data.label)
            );
        }
    };

    const handleQuickFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchText(value);
        if (gridRef.current) {
            gridRef.current.api.setGridOption("quickFilterText", value);
        }
    };

    const onGridReady = (params: GridReadyEvent) => {
        if (selectedUsers.length > 0) {
            params.api.forEachNode((node) => {
                if (selectedUsers.includes(node.data.id)) {
                    node.setSelected(true);
                }
            });
        }
    };

    const statusRenderer = (params: any) => {
        return (
            <Grid>
                {params.data.userCurrentAssignedDate === null ? `Available` : `Unavailable until ${convertUTCToLocal(params.data.userCurrentAssignedDate, "MMMM D, YYYY h:mm A")}`}
            </Grid>
        )
    }

    const columnDefs = [
        {
            headerCheckboxSelection: true,
            checkboxSelection: (params: any) => {
                return params.data.userCurrentAssignedDate === null;
            },
            field: '',
            maxWidth: 40,
        },
        { field: "no", headerName: "No", maxWidth: 75, valueGetter: 'node.rowIndex + 1' },
        { headerName: "Attender Name", maxWidth: 300, field: "label", sortable: true, filter: true },
        { headerName: "Status", field: "Status", cellRenderer: statusRenderer },
    ];

    return (
        <Dialog open={open} maxWidth="md" fullWidth>
            <Grid container sx={dialogContainer}>
                <Grid spacing={1} container sx={pv10}>
                    <Grid item xs={12} md={7}>
                        <IconTextBox placeholder='Search...' handleChange={handleQuickFilter} value={searchText}>
                            <IconButton>
                                <SearchIcon />
                            </IconButton>
                        </IconTextBox>
                    </Grid>
                    <Grid item md={0.4}></Grid>
                    <Grid item xs={6} md={2.3}>
                        <CustomButton
                            onClick={onClose}
                            variant="outlined"
                            sx={{ ...LocationButtonStyles, marginBottom: "10px" }}
                        >
                            {"Cancel"}
                        </CustomButton>
                    </Grid>
                    <Grid item xs={6} md={2.3}>
                        <CustomButton
                            onClick={onAdd}
                            variant="outlined"
                            sx={{ ...LocationButtonStyles, marginBottom: "10px" }}
                        >
                            {"Add"}
                        </CustomButton>
                    </Grid>
                </Grid>
                <Box
                    className="ag-theme-quartz"
                    style={{
                        height: "52.5vh",
                        width: "100%",
                    }}
                >
                    <AgGridReact
                        ref={gridRef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        rowHeight={40}
                        onSortChanged={onSortChanged}
                        gridOptions={{
                            pagination: true,
                            paginationPageSize: 10,
                            paginationPageSizeSelector: [10, 20, 50, 100],
                            suppressRowClickSelection: true,
                        }}
                        onRowSelected={handleRowSelect}
                        rowSelection="multiple"
                        onGridReady={onGridReady}
                    />
                </Box>
            </Grid>
        </Dialog>
    )
}

export default UserSelection;