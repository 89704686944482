import React, { useState } from 'react';
import { TaskStatusPageProps } from '../../../Model/ReportModel';
import { Box, Grid } from '@mui/material';
import Theme from '../../../theme/theme';
import LabelValue from '../../../Components/Label/InfoPair';
import ProgressBars from '../../../Components/ProgressBar/ProgressBar';
import { TaskStatusNames } from '../../../Utilities/TaskStatuses';
import { ScrollBarStyle } from '../../../Components/ScrollBarStyle';

const TaskStatusDetails = (props: TaskStatusPageProps) => {
    let { data, currentStatus } = props;
    const dataCopy = [...data];
    var sortedData: any = data && data.length > 0 && data[0].taskId > 0 ? dataCopy.sort((a, b) => {
        const dateA = new Date(a.updatedOn || a.createdOn).getTime();
        const dateB = new Date(b.updatedOn || b.createdOn).getTime();
        return dateA - dateB;
    }) : null;

    var progresBarDetails = data && data.length > 0 && data[0].taskId > 0 ? TaskStatusNames.getProgressBarDetails(sortedData) : null;

    return (
        <Box padding="5px 0px" sx={{ height: '100%', border: '1px solid', borderRadius: '6px', borderColor: Theme.palette.common.layout.liteBrownTwo ,  ...ScrollBarStyle,}}>
            <Grid container>
                <Grid md={12} sx={{ marginTop: '5px' }} >
                    {progresBarDetails &&
                        <ProgressBars  currentStep={data.length} steps={progresBarDetails ? progresBarDetails : []} />
                    }
                </Grid>
            </Grid>
        </Box>
    );
}

export default TaskStatusDetails;
