import { Grid, IconButton, InputBase, Paper, Typography } from '@mui/material';
import Theme from '../../../theme/theme';
import Title from '../../../Components/Title/Title';
import TaskDetails from './TaskDetails';
import { useAppSelector } from '../../../Redux/Store';
import { TaskDataModel, TaskReportModel } from '../../../Model/ReportModel';
import Loader from '../../../Components/Loader/Loader';
import SearchIcon from '@mui/icons-material/Search';
const TaskReport = ({ handleChange, searchTerm }: TaskReportModel) => {

    const { isLoading, Task } = useAppSelector(state => state.report);

    return (
        <>
            <Grid container display={'flex'} px={0} justifyContent={'space-between'}>
                <Grid item>
                    <Title title="Task Report" sx={{ color: Theme.palette.primary.main }} />
                </Grid>
                <Grid item >
                    <Paper
                        component="form"
                        sx={{ p: '1px 2px', display: 'flex', alignItems: 'center', width: 350 }}
                    >
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Search..."
                            inputProps={{ 'aria-label': 'search' }}
                            value={searchTerm}
                            onChange={handleChange}
                        />
                        <IconButton type="button" sx={{ p: '5px' }} aria-label="search">
                            <SearchIcon sx={{ color: Theme.palette.primary.light }} />
                        </IconButton>
                    </Paper>
                </Grid>
            </Grid>
            {
                (Task && Task.length > 0) && Task[0].task !== null && Task[0].task?.taskId !== 0 ? (
                    Task.filter((task: TaskDataModel) => task.task !== null).map((data: TaskDataModel) => (
                        <TaskDetails key={data.task.taskId} task={data.task} taskDetails={data.taskDetails} />
                    ))
                ) : <Grid container display={'flex'} justifyContent={'center'} height={'300px'} alignItems={'center'} alignContent={'center'}>
                    <Typography variant='h1' sx={{ color: Theme.palette.primary.light }}>No Result Found...</Typography>
                </Grid>
            }
            <Loader openModal={isLoading} />
        </>
    );
};

export default TaskReport;
