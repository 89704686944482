import { Dialog, Grid, Typography } from '@mui/material';
import React from 'react'
import { dialogContainer, pv10 } from '../ShiftStyles';
import Title from '../../../Components/Title/Title';
import { UnAssignedUsersDialogPropsModel } from '../../../Model/ShiftModels';
import { convertUTCToLocal } from '../../../Utilities/Common';

const UnAssignedUsersDialog = ({ open, onClose, users }: UnAssignedUsersDialogPropsModel) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
            <Grid container sx={dialogContainer}>
                <Title title="User(s) already assigned with other shift" />
                <Grid item xs={12} display={"flex"} flexDirection="column" >
                    {users.map((user: any, index: number) => {
                        return (
                            <Grid item xs={12} sx={pv10} display="flex" justifyContent="space-between" >
                                <Typography key={index}>{user.displayName}</Typography>
                                -
                                <Typography key={index}>{user.shiftName}</Typography>
                                -
                                <Typography key={index}>{convertUTCToLocal(user.fromDate, "MMMM D, YYYY h:mm A")}</Typography>
                                -
                                <Typography key={index}>{convertUTCToLocal(user.toDate, "MMMM D, YYYY h:mm A")}</Typography>
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>
        </Dialog>
    )
}

export default UnAssignedUsersDialog;