import React from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import { pv10, removeTxt, shiftIntervalCardContainer, shiftIntervalTitleText } from './ShiftStyles';
import { ShiftIntervalCardPropModel } from '../../Model/ShiftModels';
import Theme from '../../theme/theme';
import dayjs from 'dayjs';

const ShiftIntervalCard = (props: ShiftIntervalCardPropModel) => {

    const { interval, handleIntervalRemove, handleIntervalEdit, shiftFormik } = props;

    return <Grid item xs={12} sx={shiftIntervalCardContainer}>
        <Grid container sx={pv10}>
            <Grid item xs={6}>
                <Typography sx={shiftIntervalTitleText}>
                    {shiftFormik.values.shiftName}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography sx={shiftIntervalTitleText}>
                    {`${dayjs(shiftFormik.values.startTime).format("hh A")} - ${dayjs(shiftFormik.values.endTime).format("hh A")}`}
                </Typography>
            </Grid>
        </Grid>
        <Grid container sx={pv10}>
            <Grid item xs={6}>
                {interval.shiftIntervalName}
            </Grid>
            <Grid item xs={6}>
                {`${interval.shiftIntervalDuration} minutes`}
            </Grid>
        </Grid>
        <Divider />
        <Grid container sx={pv10}>
            <Grid item xs={6}>
                <Typography sx={removeTxt} onClick={() => handleIntervalRemove(interval.Id)}>
                    Remove
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography sx={removeTxt} onClick={() => handleIntervalEdit(interval)}>
                    Edit
                </Typography>
            </Grid>
        </Grid>
    </Grid>;
};

export default ShiftIntervalCard;