import { SxProps, Theme, Typography } from "@mui/material";
import React from "react";
interface Props {
    label: string|number;
    color:string;
    required ?: boolean;
}
const TextLabel: React.FC<Props> = ({ label,color, required = false }) => {
    return (
        <Typography
            variant="subtitle2"
            className="lead"
            style={{ color: color?color:'black' }}
            mb={1}
        >
            {label}{required && <span style={{ color:"red", fontWeight:'bold'}}> *</span>}
        </Typography>
        
    )
}
export default TextLabel
