import { Box, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DataTable from '../../../Components/DataTable/DataTable'
import { ColDef } from 'ag-grid-community';
import Title from '../../../Components/Title/Title';
import Theme from '../../../theme/theme';
import { useAppDispatch, useAppSelector } from '../../../Redux/Store';
import { AttendanceModel, GetReportParamsModel, GetTaskParamsModel } from '../../../Model/ReportModel';
import { GetAttendanceReportData } from '../../../Redux/Thunks/ReportThunk';
import { getStoreId } from '../../../Utilities/Store';
import dayjs from 'dayjs';
interface Props {
    startedOn: Date;
    completedOn: Date;
}

const AttendanceReport = () => {
    const [rowData, setRowData] = useState<AttendanceModel[]>([]);
    const { Attendance } = useAppSelector(state => state.report)
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (Attendance && Attendance.length >= 1) {
            let data: any = Attendance.map((tabData: AttendanceModel, index: number) => {
                return {
                    No: index + 1,
                    date: tabData.startedOn ? new Date(tabData.startedOn).toLocaleDateString('en-CA') : "",
                    userName: tabData.userName,
                    status: tabData.attendanceStatus,
                    shiftName: tabData.shiftName,
                    startTime: tabData.startedOn ? new Date(tabData.startedOn).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }) : "",
                    endTime: tabData.completedOn ? new Date(tabData.completedOn).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }) : "",
                    totalHours: tabData.totalHours ? parseFloat(tabData.totalHours).toFixed(2) : 0,
                    totalCompletedTask: tabData.totalCompletedTask == null ? "0" : tabData.totalCompletedTask
                }
            });
            setRowData(data);
        }else{
            setRowData([]);
        }
    }, [Attendance]);

    const colDefs: ColDef[] = [
        { field: 'No', valueGetter: 'node.rowIndex + 1', },
        { field: 'date', headerName: "Date", },
        { field: 'userName', headerName: "User name" },
        { field: 'status', headerName: "Status" },
        { field: 'shiftName', headerName: "Shift name" },
        { field: 'startTime', headerName: "Start time" },
        { field: 'endTime', headerName: "End time" },
        { field: 'totalHours', headerName: "Total hours" },
        { field: 'totalCompletedTask', headerName: "Total completed tasks" },
    ];
    return (
        <Box>
            <Grid item xs={12}>
                <Title title="Attendance Report List" sx={{ color: Theme.palette.primary.main }} />
            </Grid>
            <Grid item xs={12} mb={1}>
                <DataTable rows={rowData ?? []} columnDefs={colDefs} />
            </Grid>
        </Box>
    )
}

export default AttendanceReport