import { Dialog, Grid } from "@mui/material";
import { FormikProps } from "formik";
import { Dispatch, SetStateAction } from "react";
import {
    shiftAssignentLocationInitial,
    shiftAssignmentInitialModel,
    shiftAssignmentShiftInitial,
    updateAssignedUsersModel,
} from "../../../Model/ShiftModels";
import { dialogContainer, pv10 } from "../ShiftStyles";
import Theme from "../../../theme/theme";
import TextLabel from "../../../Components/Label/TextLabel";
import DateTimePickerTextBox from "../../../Components/TextBox/DateTimePickerTextBox";
import CustomButton from "../../../Components/Button/Button";
import { LocationButtonStyles } from "../../../layouts/SideNavbarStyle";
import AutoComplete from "../../../Components/AutoComplete/AutoComplete";
import { AutoComplete as AutoCompleteModel } from "../../../Model/AutoCompleteModel";
import { convertUTCToLocal, replaceTime } from "../../../Utilities/Common";
import LocationAutoComplete from "../../../Components/AutoComplete/LocationAutoComplete";
import { Label } from "@mui/icons-material";
interface props {
    open: boolean;
    onClose: () => void;
    userAssignFormik: FormikProps<updateAssignedUsersModel>;
    selectedLocation: AutoCompleteModel | null;
    selectedShift: AutoCompleteModel | null;
    setSelectedLocation: Dispatch<SetStateAction<shiftAssignentLocationInitial | null>>;
    setSelectedShift: Dispatch<SetStateAction<AutoCompleteModel | null>>;
    shiftAssignmentInitialData: shiftAssignmentInitialModel;
}

const ShiftAllocationUpdateForm = ({
    onClose,
    open,
    userAssignFormik,
    selectedLocation,
    setSelectedLocation,
    shiftAssignmentInitialData,
    selectedShift,
    setSelectedShift
}: props) => {
    const onFromDateChange = (date: Date) => {
        userAssignFormik.setFieldValue("fromDate", date);
    };

    const onToDateChange = (date: Date) => {
        userAssignFormik.setFieldValue("toDate", date);
    };

    const onSelectLocation = async (id: number | string) => {
        userAssignFormik.setFieldValue("locationId", id);
        const selectedLocation = shiftAssignmentInitialData.locations.find((item: shiftAssignentLocationInitial) => item.id === id);
        setSelectedLocation(selectedLocation ?? null);
    };

    const onSelectShift = (id: number | string) => {
        let shift =
            shiftAssignmentInitialData.shifts.find(
                (item: shiftAssignmentShiftInitial) => item.id === id
            ) ?? null;
        setSelectedShift(shift);
        if (shift !== null) {
            let startDate = replaceTime(userAssignFormik.values.fromDate, shift.startTime);
            let endDate = replaceTime(userAssignFormik.values.toDate, shift.endTime);
            userAssignFormik.setFieldValue("fromDate", startDate);
            userAssignFormik.setFieldValue("toDate", endDate);
        }

        userAssignFormik.setFieldValue("shiftId", id);
    };

    const onLocationClear = () => {
        userAssignFormik.setFieldValue("locationId", 0);
        setSelectedLocation(null);
    };

    const onShiftClear = () => {
        userAssignFormik.setFieldValue("shiftId", 0);
        setSelectedShift(null);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth='md' PaperProps={{ sx: { borderRadius: '13px', padding: '10px', overflow: 'visible' } }} fullWidth>
            <Grid container sx={dialogContainer}>
                <Grid
                    container
                    spacing={1}
                    sx={pv10}
                    display='flex'
                    justifyContent='center'
                    alignItems='baseline'
                >
                    <Grid item xs={6} md={3.9} marginTop={5}>
                        <TextLabel
                            label='Shifts'
                            color={Theme.palette.common.black}
                            required
                        />
                        <AutoComplete
                            value={selectedShift}
                            data={shiftAssignmentInitialData.shifts}
                            title='Shifts'
                            onClick={onSelectShift}
                            onClear={onShiftClear}
                            error={
                                userAssignFormik.touched.shiftId &&
                                Boolean(userAssignFormik.errors.shiftId)
                            }
                            helperText={
                                userAssignFormik.touched.shiftId
                                    ? userAssignFormik.errors.shiftId
                                    : ""
                            }
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <TextLabel
                            label='From Date'
                            color={Theme.palette.common.black}
                            required
                        />
                        <DateTimePickerTextBox
                            placeholder='From date'
                            isDatePicker={true}
                            inputValue={convertUTCToLocal(
                                userAssignFormik.values.fromDate,
                                "MMMM D, YYYY"
                            )}
                            dateValue={convertUTCToLocal(userAssignFormik.values.fromDate)}
                            handleChange={onFromDateChange}
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <TextLabel
                            label='To Date'
                            color={Theme.palette.common.black}
                            required
                        />
                        <DateTimePickerTextBox
                            placeholder='To date'
                            isDatePicker={true}
                            inputValue={convertUTCToLocal(
                                userAssignFormik.values.toDate,
                                "MMMM D, YYYY"
                            )}
                            dateValue={convertUTCToLocal(userAssignFormik.values.toDate)}
                            handleChange={onToDateChange}
                            minimumDate={userAssignFormik.values.fromDate}
                        />
                    </Grid>
                </Grid>
                <Grid container
                    sx={pv10}
                    display='flex'
                    alignItems='baseline'>
                    <Grid item xs={6} md={3.9}>
                        <TextLabel
                            label='Locations'
                            color={Theme.palette.common.black}
                            required
                        />
                        <LocationAutoComplete
                            value={selectedLocation}
                            data={shiftAssignmentInitialData.locations}
                            title='Location'
                            onClick={onSelectLocation}
                            onClear={onLocationClear}
                            error={
                                userAssignFormik.touched.locationId &&
                                Boolean(userAssignFormik.errors.locationId)
                            }
                            helperText={
                                userAssignFormik.touched.locationId
                                    ? userAssignFormik.errors.locationId
                                    : ""
                            }
                            required={true}
                        />
                        {/* <AutoComplete
                            value={selectedLocation}
                            data={shiftAssignmentInitialData.locations}
                            title='Location'
                            onClick={onSelectLocation}
                            onClear={onLocationClear}
                            error={
                                userAssignFormik.touched.locationId &&
                                Boolean(userAssignFormik.errors.locationId)
                            }
                            helperText={
                                userAssignFormik.touched.locationId
                                    ? userAssignFormik.errors.locationId
                                    : ""
                            }
                        /> */}
                    </Grid>
                </Grid>
                <Grid container sx={pv10}>
                    <Grid item md={6}></Grid>
                    <Grid item xs={6} md={3}>
                        <CustomButton
                            onClick={() => {
                                userAssignFormik.resetForm();
                            }}
                            variant='outlined'
                            sx={{ ...LocationButtonStyles, marginBottom: "10px" }}
                        >
                            {"Clear"}
                        </CustomButton>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <CustomButton
                            onClick={() => userAssignFormik.handleSubmit()}
                            variant='outlined'
                            sx={{ ...LocationButtonStyles, marginBottom: "10px" }}
                        >
                            {"Assign"}
                        </CustomButton>
                    </Grid>
                </Grid>
                {/* <Grid container sx={pv10}>
                </Grid> */}
            </Grid>
        </Dialog>
    );
};

export default ShiftAllocationUpdateForm;
