import React, { useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { Box, Grid, IconButton, TextField } from "@mui/material";
import Theme from "../../theme/theme";
import TextLabel from "../../Components/Label/TextLabel";
import DateTimePickerTextBox from "../../Components/TextBox/DateTimePickerTextBox";
import AutoComplete from "../../Components/AutoComplete/AutoComplete";
import { useFormik } from "formik";
import { numberToDateTime, ReportTypes } from "../../Utilities/Common";
import { AutoComplete as AutoCompleteType } from "../../Model/AutoCompleteModel";
import dayjs from "dayjs";
import { floorButtonStyles } from "../Floor/FloorStyles";
import CustomButton from "../../Components/Button/Button";
import AttendanceReport from "./AttendanceReport/AttendanceReport";
import TaskReport from "./TaskReport/TaskReport";
import { getStoreId } from "../../Utilities/Store";
import {
  AttenderListModel,
  AttenderModel,
  floorAutoCompleteModel,
  GetReportParamsModel,
} from "../../Model/ReportModel";
import {
  GetAttendanceReportData,
  GetAttenderList,
} from "../../Redux/Thunks/ReportThunk";
import { useAppDispatch, useAppSelector } from "../../Redux/Store";
import { GetTaskReportData } from "../../Redux/Thunks/ReportThunk";
import { GetTaskParamsModel } from "../../Model/ReportModel";
import { assetStyles } from "../Asset/AssetStyles";
import Title from "../../Components/Title/Title";
import SelectUserTable from "./SelectedAttender/SelectAttender";
import IconTextBox from "../../Components/TextBox/IconTextBox";
import DescriptionIcon from "@mui/icons-material/Description";
import { GetLocationDetails } from "../../Redux/Thunks/LocationThunk";
import { unwrapResult } from "@reduxjs/toolkit";
import { debounce } from 'lodash';
import { Patientstyle } from "../Patient/PatientStyle";

const Report = () => {

  const { Attender } = useAppSelector((state) => state.report);
  const { locationDetails } = useAppSelector((state) => state.location);


  const [selectedReportType, setSelectedReportType] =
    useState<AutoCompleteType | null>(null);
  const [reportData, setReportData] = useState({
    fromDate: "",
    toDate: "",
    reportType: "",
    attender: "",
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectedUserNames, setSelectedUserNames] = useState<string[]>([]);
  const [blocks, setBlocks] = useState<AutoCompleteType[]>([]);
  const [floors, setFloors] = useState<floorAutoCompleteModel[]>([]);
  const [selectedBlock, setSelectedBlock] = useState<AutoCompleteType | null>(null);
  const [selectedFloor, setSelectedFloor] = useState<floorAutoCompleteModel | null>(null);
  const [searchTerm, setSearchTerm] = useState('');

  const dispatch = useAppDispatch();

  const reportTypeData = [
    { id: 1, label: "Task Report" },
    { id: 2, label: "Attendance Report" },
  ];

  useEffect(() => {
    dispatch(GetAttenderList());
    getLocationData();
  }, []);

  const reportInitialValues = {
    fromDate: ReportTypes.getReportStartDate(new Date()),
    toDate: ReportTypes.getReportEndDate(new Date()),
    reportType: "",
    attender: "",
  };

  const reportFormik = useFormik({
    initialValues: reportInitialValues,
    validationSchema: yup.object().shape({
      fromDate: yup.date().required("required"),
      toDate: yup.date().required("required"),
      reportType: yup.string().required("required"),
      attender: yup.string(),
    }),
    onSubmit: async (values) => {
      setReportData({
        fromDate: "",
        toDate: "",
        reportType: "",
        attender: "",
      });
      const startedOnDayjsObject = dayjs(values.fromDate);
      const startedOnDateString = startedOnDayjsObject.utc().toISOString();
      const completedOnDayjsObject = dayjs(values.toDate);
      const completedOnDateString = completedOnDayjsObject.utc().toISOString();
      setReportData({
        fromDate: startedOnDateString,
        toDate: completedOnDateString,
        reportType: values.reportType,
        attender: values.attender,
      });
      if (values.reportType == ReportTypes.taskReport) {
        const taskParam: GetTaskParamsModel = {
          From: values.fromDate,
          To: values.toDate,
          StoreId: await getStoreId(),
          SearchParam: "",
          UserId: selectedUsers ?? [],
          blockId: selectedBlock !== null && Number(selectedBlock?.id) > 0 ? Number(selectedBlock?.id) : null,
          floorId: selectedFloor !== null && Number(selectedFloor?.id) > 0 ? Number(selectedFloor?.id) : null,
        };
        await dispatch(GetTaskReportData(taskParam));
      }
      if (values.reportType == ReportTypes.attendanceReport) {
        const attendanceParams: GetReportParamsModel = {
          fromDate: values.fromDate,
          toDate: values.toDate,
          storeId: await getStoreId(),
          UserId: selectedUsers ?? [],
        };
        await dispatch(GetAttendanceReportData(attendanceParams));
      }
    },
  });

  const getLocationData = async () => {
    const res = unwrapResult(await dispatch(GetLocationDetails()));
    if (res.data !== null) {
      let blocks: AutoCompleteType[] = res.data.block.map((item: any) => {
        return {
          id: item.blockId,
          label: item.blockName,
        }
      });
      setBlocks(blocks);

      let floors: floorAutoCompleteModel[] = res.data.floor.map((item: any) => {
        return {
          id: item.floorId,
          label: item.floorName,
          blockId: item.blockId
        }
      });
      setFloors(floors);
    }
  }

  const handleAddUserSelection = () => {
    setOpenDialog(false);
  };

  const handleCloseUserSelection = () => {
    setOpenDialog(false);
    setSelectedUsers([]);
    setSelectedUserNames([]);
  };

  const onFromDateChange = (date: Date) => {
    reportFormik.setFieldValue("fromDate", ReportTypes.getReportStartDate(date));
  };

  const onToDateChange = (date: Date) => {
    reportFormik.setFieldValue("toDate", ReportTypes.getReportEndDate(date));
  };

  const setReportTypeClearValue = () => {
    setSelectedReportType(null);
    reportFormik.setFieldValue("reportType", "");
  };

  const setReportTypeValue = (id: number | string) => {
    reportFormik.setFieldValue(
      "reportType",
      reportTypeData.find((item: AutoCompleteType) => item.id === id)?.id || 0
    );

    const currentSelectedReportType: any =
      reportTypeData.find((data: AutoCompleteType) => data.id === id)?.label ||
      "";
    setSelectedReportType(currentSelectedReportType);
  };

  const handleBlockSelect = (id: number | string) => {
    let selectedBlock = blocks.find((item) => item.id === id);
    let filteredFloors: floorAutoCompleteModel[] = (locationDetails?.floor || []).filter((item) => item.blockId === id)
      .map((floorItem) => {
        return {
          id: floorItem.floorId,
          label: floorItem.floorName,
          blockId: floorItem.blockId
        }
      });
    setFloors(filteredFloors ?? []);
    setSelectedBlock(selectedBlock ?? null);
  }

  const handleBlockClear = () => {
    setSelectedBlock(null);
  }

  const handleFloorSelect = (id: number | string) => {
    let selectedFloor = floors.find((item) => item.id === id);
    setSelectedFloor(selectedFloor ?? null);
  }

  const handleFloorClear = () => {
    setSelectedFloor(null);
  }

  const debouncedSearch = useCallback(
    debounce(async (term) => {
      const taskParam: GetTaskParamsModel = {
        From: reportFormik.values.fromDate,
        To: reportFormik.values.toDate,
        StoreId: await getStoreId(),
        UserId: selectedUsers ?? [],
        SearchParam: term,
        blockId: selectedBlock !== null && Number(selectedBlock?.id) > 0 ? Number(selectedBlock?.id) : null,
        floorId: selectedFloor !== null && Number(selectedFloor?.id) > 0 ? Number(selectedFloor?.id) : null,
      };
      await dispatch(GetTaskReportData(taskParam));
    }, 1500),
    [reportFormik.values.fromDate, reportFormik.values.toDate, selectedUsers, selectedBlock, selectedFloor]
  );

  const handleChange = (e: any) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  const handleClear = () => {
    setSearchTerm('');
    reportFormik.resetForm();
    setSelectedReportType(null);
    setSelectedUsers([]);
    setSelectedUserNames([]);
    setSelectedBlock(null);
    setSelectedFloor(null);
  }


  return (
    <Box mr={2}>
      <Grid container>
        <Grid
          container
          spacing={2}
          style={{ ...assetStyles.ReportContainerStyle }}
        >
          <Grid item xs={11}>
            <Title title={"Report"} sx={{ color: "white" }} />
          </Grid>
          <Grid container display="flex" justifyContent="space-between" padding={2}>
            <Grid item xs={12} sm={6} md={2.9}>
              <TextLabel
                label="Report Type"
                color={Theme.palette.common.white}
                required
              />
              <AutoComplete
                color={Theme.palette.primary.contrastText}
                value={selectedReportType}
                data={reportTypeData}
                title="Report Type"
                required
                onClick={setReportTypeValue}
                onClear={setReportTypeClearValue}
                error={
                  reportFormik.touched.reportType &&
                  Boolean(reportFormik.errors.reportType)
                }
                helperText={
                  reportFormik.touched.reportType &&
                  reportFormik.errors.reportType
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2.9}>
              <TextLabel
                label="From date"
                color={Theme.palette.common.white}
                required
              />
              <DateTimePickerTextBox
                placeholder="From date"
                isDatePicker={true}
                inputValue={dayjs(reportFormik.values.fromDate).format(
                  "MMMM D, YYYY"
                )}
                dateValue={reportFormik.values.fromDate}
                handleChange={onFromDateChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2.9}>
              <TextLabel
                label="To date"
                color={Theme.palette.common.white}
                required
              />
              <DateTimePickerTextBox
                placeholder="To date"
                isDatePicker={true}
                inputValue={dayjs(reportFormik.values.toDate).format(
                  "MMMM D, YYYY"
                )}
                dateValue={reportFormik.values.toDate}
                handleChange={onToDateChange}
                minimumDate={reportFormik.values.fromDate}
              />
            </Grid>
            <Grid item xs={6} md={2.9} onClick={() => setOpenDialog(true)}>
              <TextLabel
                label="Attender Name(s)"
                color={Theme.palette.common.white}
              />
              <IconTextBox
                placeholder="Attender Name(s)"
                value={selectedUserNames.join(", ")}
                disabled={true}
              >
                <IconButton edge="end" onClick={() => setOpenDialog(true)}>
                  <DescriptionIcon />
                </IconButton>
              </IconTextBox>
            </Grid>
          </Grid>
          {reportFormik.values.reportType == ReportTypes.taskReport && <Grid container display="flex" padding={2}>
            <Grid item xs={12} sm={6} md={2.9}>
              <TextLabel
                label="Block"
                color={Theme.palette.common.white}
              />
              <AutoComplete
                color={Theme.palette.primary.contrastText}
                value={selectedBlock}
                data={blocks}
                title="Blocks"
                onClick={handleBlockSelect}
                onClear={handleBlockClear}
              />
            </Grid>
            <Grid item xs={12} ml={2} sm={6} md={2.9}>
              <TextLabel
                label="Floors"
                color={Theme.palette.common.white}
              />
              <AutoComplete
                color={Theme.palette.primary.contrastText}
                value={selectedFloor}
                data={selectedBlock === null ? [] : floors}
                title="Floor"
                onClick={handleFloorSelect}
                onClear={handleFloorClear}
              />
            </Grid>
          </Grid>}
          <Grid
            item
            mt={1}
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <CustomButton
              onClick={handleClear}
              variant='outlined'
              sx={{ ...Patientstyle.clearButton, marginRight: 2 }}
            >
              {"Clear"}
            </CustomButton>
            <CustomButton
              onClick={() => reportFormik.handleSubmit()}
              sx={{ ...floorButtonStyles, marginRight: 2 }}
            >
              Submit
            </CustomButton>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {reportData.reportType == ReportTypes.taskReport && (
            <TaskReport
              handleChange={handleChange}
              searchTerm={searchTerm}
            />
          )}
          {reportData.reportType == ReportTypes.attendanceReport && (
            <AttendanceReport />
          )}
        </Grid>
      </Grid>
      {openDialog && <SelectUserTable
        open={openDialog}
        onClose={handleCloseUserSelection}
        rowData={Attender}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        onAdd={handleAddUserSelection}
        setSelectedUserNames={setSelectedUserNames}
      />}
    </Box>
  );
};

export default Report;
