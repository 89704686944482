import { Grid, useMediaQuery } from "@mui/material";
import Theme from "../../theme/theme";
import { useEffect, useState } from "react";
import DashboardCount from "./DashboardCount";
import JobsList from "./jobs/JobsList";
import { useAppDispatch, useAppSelector } from "../../Redux/Store";
import { GetAssetAttenderCount } from "../../Redux/Thunks/DashboardThunk";
import * as signalR from "@microsoft/signalr";
import { setConnection } from "../../Redux/Slices/SignalRSlice";
import { GetCancelRequestList } from "../../Redux/Thunks/CancelRequestedThunk";
import AcceptedDialogBox from "../AcceptedJobDialog/AcceptedDialogBox";
import AddJobs from "./jobs/AddJobs";
import NotificationBox from "./Notificatonbox";
import { UserProfile } from "../../Redux/Thunks/LoginThunk";
import Loader from "../../Components/Loader/Loader";
import { GetJobList } from "../../Redux/Thunks/JobsThunk";

const Dashboard = () => {
  const toggleMenuComponent = useMediaQuery(Theme.breakpoints.down("md"));
  const { user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const connection = useAppSelector((state) => state.signalR.connection);

  const [showAlert, setShowAlert] = useState<boolean>(false);
  const handleNavigateAlert = () => {
    setShowAlert((prev) => !prev);
  };

  const { CancelRequestedList } = useAppSelector(
    (state) => state.cancelRequestTask
  );
  const { notificationData } = useAppSelector((state) => state.notification);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    const getDetails = async () => {
      await dispatch(GetCancelRequestList());
      await dispatch(GetJobList());
      await dispatch(GetAssetAttenderCount());
      setIsLoading(false);
    };
    getDetails();

    if (user === null) {
      getUserProfile();
    }
  }, []);

  useEffect(() => {
    const baseUrl = process.env.REACT_APP_BASEURL || "";
    const hub = process.env.REACT_APP_SIGNALR_HUB || "";
    if (connection?.state === "Disconnected" || connection === null) {
      const newConnection = new signalR.HubConnectionBuilder()
        .withUrl(`${baseUrl}${hub}`)
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build();
      dispatch(setConnection(newConnection));
    }
  }, []);

  const getUserProfile = async () => {
    await dispatch(UserProfile());
  };

  return (
    <>
      <Grid container>
        <Grid container spacing={1} mt={0.5} item display={"flex"}>
          <Grid
            item
            md={notificationData.length > 0 ? 8.5 : 12}
            lg={notificationData.length > 0 ? 9 : 12}
            mr={notificationData.length > 0 ? 0 : 2}
            xs={12}
          >
            <Grid item>
              <DashboardCount />
            </Grid>
            <Grid item mt={1}>
              <AddJobs />
            </Grid>
          </Grid>
          <Grid item m={0} pr={2} md={3.5} lg={3}>
            <NotificationBox />
          </Grid>
        </Grid>
        <Grid container item mt={1} spacing={1} mb={1}>
          <Grid
            mr={2}
            item
            md={12}
            xs={12}
            sx={{
              display: showAlert && toggleMenuComponent ? "none" : "block",
            }}
          >
            <JobsList />
          </Grid>
        </Grid>
        <AcceptedDialogBox />
      </Grid>
      <Loader openModal={isLoading} />
    </>
  );
};

export default Dashboard;
