import { createAsyncThunk } from "@reduxjs/toolkit";
import { getHeaderToken } from "../../Utilities/header";
import axios from "axios";
import { CreateLocationModel } from "../../Model/Location";
import { getStoreId } from "../../Utilities/Store";

export const GetLocationList = createAsyncThunk(
    "Location/getList",
    async () => {
        try {
            const URL = process.env.REACT_APP_BASEURL + `/api/FacilityLocation/${await getStoreId()}`;
            const response = await axios.get(URL, await getHeaderToken());
            return response.data;
        } catch (e: any) {
            return e.response;
        }
    }
);

export const GetLocationById = createAsyncThunk(
    "Location/getById",
    async (id: number) => {
        try {
            const URL = process.env.REACT_APP_BASEURL + `/api/FacilityLocation/${id}/${await getStoreId()}`;
            const response = await axios.get(URL, await getHeaderToken());
            return response.data;
        } catch (e: any) {
            return e.response;
        }
    }
);

export const GetLocationDetails = createAsyncThunk(
    "Location/getDetails",
    async () => {
        try {
            const URL = process.env.REACT_APP_BASEURL + `/api/FacilityLocation/locationdetails/${await getStoreId()}`;
            const response = await axios.get(URL, await getHeaderToken());
            return response.data;
        } catch (e: any) {
            return e.response;
        }
    }
);

export const CreateLocation = createAsyncThunk(
    "Location/createLocation",
    async (data: CreateLocationModel) => {
        try {
            const URL = process.env.REACT_APP_BASEURL + "/api/FacilityLocation/add";
            const response = await axios.post(URL, data, await getHeaderToken());
            return response.data;
        } catch (e: any) {
            return e.response;
        }
    }
);

export const updateLocation = createAsyncThunk(
    "Location/updateLocation",
    async (data: CreateLocationModel) => {
        try {
            const URL = process.env.REACT_APP_BASEURL + "/api/FacilityLocation/update";
            const response = await axios.put(URL, data, await getHeaderToken());
            return response.data;
        } catch (e: any) {
            return e.response;
        }
    }
);

export const deleteLocation = createAsyncThunk(
    "Location/deleteLocation",
    async (id: number) => {
        try {
            const URL = process.env.REACT_APP_BASEURL + `/api/FacilityLocation/delete/${id}/${await getStoreId()}`;
            const response = await axios.delete(URL, await getHeaderToken());
            return response.data;
        } catch (e: any) {
            return e.response;
        }
    }
);