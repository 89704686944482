import React from 'react'
import { SnackBarModel } from '../../Model/SnackBarModel'
import { Snackbar } from '@mui/material';


function SnackbarComponent(props: SnackBarModel) {
    const { setShowSnackBar, showSnackBar, snackBarMessage } = props;
    return (
        <Snackbar
            open={showSnackBar}
            autoHideDuration={2000}
            onClose={() => setShowSnackBar(false)}
            message={snackBarMessage}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            ContentProps={{
                sx: {
                    display: "block",
                    textAlign: "center",
                    color: "white",
                },
            }}
        />
    )
}

export default SnackbarComponent;
