import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  PatientResponse,
  getAllPatients,
  getSinglePatient,
  updatePatient,
} from "../Thunks/PatientThunk";
import { PatientModel } from "../../Model/PatientModel";
import { DataSourceItem } from "../../Model/AddUserModels";
import { PatientDataModel } from "../../Model/PatientModel";

export interface PatientState {
  isLoading: boolean;
  message: string;
  patientListData: PatientDataModel[];
  patientByIdData: PatientDataModel | null;
  // data : any[],
}

const initialState: PatientState = {
  isLoading: false,
  message: "",
  patientListData: [],
  patientByIdData: null,
};

const PatientSlice = createSlice({
  name: "Patient",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllPatients.pending, (state, action) => {
        state.isLoading = true;
        state.message = "Patients data Loading";
      })
      .addCase(getAllPatients.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.status == true) {
          state.patientListData = action.payload.data;
          state.message = action.payload.message;
        } else {
          state.patientListData = [];
          state.message = action.payload.message;
        }
      })
      .addCase(getAllPatients.rejected, (state) => {
        state.isLoading = false;
        state.message = "Requested patient data Not available";
      })
      .addCase(getSinglePatient.pending, (state, action) => {
        state.isLoading = true;
        state.message = "Patient Data Loading";
      })
      .addCase(getSinglePatient.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.status == true) {
          state.patientByIdData = action.payload.data;
          state.message = action.payload.message;
        } else {
          state.patientByIdData = null;
          state.message = action.payload.message;
        }
      })
      .addCase(getSinglePatient.rejected, (state) => {
        state.isLoading = false;
        state.message = "Requested patient data Not available";
      })
      .addCase(updatePatient.pending, (state, action) => {
        state.isLoading = true;
        state.message = "Patient Data Loading";
      })
      .addCase(updatePatient.fulfilled, (state, action) => {
        if (action.payload.status == true) {
          state.message = action.payload.message;
        } else {
          state.message = action.payload.message;
        }
      })
      .addCase(updatePatient.rejected, (state) => {
        state.isLoading = false;
        state.message = "Requested patient data Not available";
      });
  },
});
export const PatientReducer = PatientSlice.reducer;
