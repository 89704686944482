import { createAsyncThunk } from "@reduxjs/toolkit";
import { getHeaderToken } from "../../Utilities/header";
import axios from "axios";
import { getStoreId } from "../../Utilities/Store";
import { shiftAssignmentInitialDataModel, ShiftFormSubmitModel, updateAssignedUsersModel, UserShiftAssignmentParamsModel, UserShiftAssignmentDeleteModel, } from "../../Model/ShiftModels";

const url = process.env.REACT_APP_BASEURL;

export const createShift = createAsyncThunk(
    "shift/create",
    async (data: ShiftFormSubmitModel) => {
        try {
            const URL = `${url}/api/Shift/create`;
            const response = await axios.post(URL, data, await getHeaderToken());
            return response.data;
        }
        catch (error: any) {
            return error.response;
        }
    }
);
export const updateShift = createAsyncThunk(
    "shift/update",
    async (data: ShiftFormSubmitModel) => {
        try {
            const URL = `${url}/api/Shift/update`;
            const response = await axios.put(URL, data, await getHeaderToken());
            return response.data;
        }
        catch (error: any) {
            return error.response;
        }
    }
);

export const getShiftList = createAsyncThunk(
    "shift/getList",
    async () => {
        try {
            const URL = `${url}/api/Shift/${await getStoreId()}`;
            const response = await axios.get(URL, await getHeaderToken());
            return response.data;
        }
        catch (error: any) {
            return error.response;
        }
    }
);

export const getShift = createAsyncThunk(
    "shift/getById",
    async (shiftId: number) => {
        try {
            const URL = `${url}/api/Shift/${shiftId}/${await getStoreId()}`;
            const response = await axios.get(URL, await getHeaderToken());
            return response.data;
        }
        catch (error: any) {
            return error.response;
        }
    }
)

export const getIntervalTypes = createAsyncThunk(
    "shift/intervalTypes",
    async () => {
        try {
            const URL = `${url}/api/Shift/ShiftBreak`;
            const response = await axios.get(URL, await getHeaderToken());
            return response.data;
        }
        catch (error: any) {
            return error.response;
        }
    }
);

export const deleteShift = createAsyncThunk(
    "shift/delete",
    async (shiftId: number) => {
        try {
            const URL = `${url}/api/Shift/${shiftId}/${await getStoreId()}`;
            const response = await axios.delete(URL, await getHeaderToken());
            return response.data;
        }
        catch (error: any) {
            return error.response;
        }
    }
)

export const deleteShiftAssignment = createAsyncThunk(
    "shift/deleteShiftAssignment",
    async (shiftDetails: UserShiftAssignmentDeleteModel) => {
        try {
            const URL = `${url}/api/Shift/delete-user-assignment/${shiftDetails.userShiftAssignmentId}/${shiftDetails.shiftId}/${await getStoreId()}`;
            const response = await axios.delete(URL, await getHeaderToken());
            return response.data;
        }
        catch (error: any) {
            return error.response;
        }
    }
)

export const getShiftAssignmentInitialData = createAsyncThunk(
    "shift/assignmentInitialData",
    async (props: shiftAssignmentInitialDataModel) => {
        try {
            const URL = `${url}/api/Shift/shiftassignment/initial`;
            const response = await axios.post(URL, props, await getHeaderToken());
            return response.data;
        }
        catch (error: any) {
            return error.response;
        }
    }
)

export const assignUserShift = createAsyncThunk(
    "shift/assign",
    async (data: UserShiftAssignmentParamsModel) => {
        try {
            const URL = `${url}/api/Shift/assignment`;
            const response = await axios.post(URL, data, await getHeaderToken());
            return response.data;
        }
        catch (error: any) {
            return error.response;
        }
    }
)

export const getAssignedUsers = createAsyncThunk(
    "shift/assignedUsers",
    async (shiftId: number) => {
        try {
            const URL = `${url}/api/Shift/users/${shiftId}/${await getStoreId()}`;
            const response = await axios.get(URL, await getHeaderToken());
            return response.data;
        }
        catch (error: any) {
            return error.response;
        }
    }
)

export const updateAssignedUsers = createAsyncThunk(
    "shift/updateAssignedUsers",
    async (data: updateAssignedUsersModel) => {
        try {
            const URL = `${url}/api/Shift/shiftassignment/update`;
            const response = await axios.put(URL, data, await getHeaderToken());
            return response.data;
        }
        catch (error: any) {
            return error.response;
        }
    }
)
