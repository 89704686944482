import { createSlice } from "@reduxjs/toolkit";
import * as signalR from '@microsoft/signalr';

interface InitialState {
    connection: signalR.HubConnection | null;
}

let initialState: InitialState = { connection: null };

const SignalRSlice = createSlice({
    name: "SignalR",
    initialState,
    reducers: {
        setConnection: (state, action) => {
            state.connection = action.payload
        }
    },
})

export const { setConnection } = SignalRSlice.actions;
export const signalRReducer = SignalRSlice.reducer;