import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'
import { DialogModel } from '../../Model/DialogBox'
import Theme from '../../theme/theme';

const DialogBox = (props: DialogModel) => {
    const { handleAccept, handleCancel, message, open } = props;
    return (
        <Dialog
            open={open}
            onClose={handleCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText sx={{ color: Theme.palette.primary.main }} id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary" autoFocus>
                    No
                </Button>
                <Button onClick={handleAccept} color="primary">
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DialogBox
