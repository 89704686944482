import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, ThemeProvider, createTheme } from '@mui/material';
import React, { ReactElement, useEffect, useState } from 'react';
import { selectComponentButtonStyles } from '../../../Components/SelectionbarStyles';
import { useAppDispatch, useAppSelector } from '../../../Redux/Store';
import { CreateJob, GetJobInitialDetails, GetJobList } from '../../../Redux/Thunks/JobsThunk';
import AutoComplete from '../../../Components/AutoComplete/AutoComplete';
import { AutoComplete as AutoCompleteprops } from '../../../Model/AutoCompleteModel';
import { Location } from '../../../Model/Location'
import { AssetDataModel } from '../../../Model/AddAssetModel';
import { UserDataSourceModel, UserEntity } from '../../../Model/UserModel';
import { useFormik } from 'formik';
import * as yup from "yup"
import WhiteAutoComplete from '../../../Components/AutoComplete/WhiteAutoComplete';
import TextBox from '../../../Components/TextBox/TextBox';
import { JobCreate, taskPriority } from '../../../Model/JobModel';
import SnackbarComponent from '../../../Components/SnackBar/Snackbar';
import Loader from '../../../Components/Loader/Loader';
import {
    LocalizationProvider,
    MobileDateTimePicker,
    MobileTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs';
import Theme, { datePickerTheme } from '../../../theme/theme';
import TextLabel from '../../../Components/Label/TextLabel';
import { Patientstyle } from '../../Patient/PatientStyle';
import { getStoreId } from '../../../Utilities/Store';
import { pink } from '@mui/material/colors';
import { GetAssetAttenderCount } from '../../../Redux/Thunks/DashboardThunk';
import LocationAutoComplete from '../../../Components/AutoComplete/LocationAutoComplete';
import { GetLocationList } from '../../../Redux/Thunks/LocationThunk';
import { AutoComplete as AutoCompleteModel } from '../../../Model/AutoCompleteModel';
import AssetAutoComplete from '../../../Components/AutoComplete/AssetAutoComplete';
const AddJobs = () => {
    const dispatch = useAppDispatch()
    const { JobInitial } = useAppSelector(state => state.job);
    const [selectedAttender, setSelectedAttender] = useState<AutoCompleteprops | null>(null);
    const [attenderData, setAttenderData] = useState<AutoCompleteprops[]>([]);
    const [selectedAsset, setSelectedAsset] = useState<AutoCompleteprops | null>(null);
    const [assetData, setAssetData] = useState<AutoCompleteprops[]>([]);
    const [assetPassData, setAssetPassData] = useState<any[]>([])
    const [selectedSourceLocation, setSelectedSourceLocation] = useState<AutoCompleteprops | null>(null);
    const [SourceData, setSourceData] = useState<any[]>([]);
    const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [selectedDestinationLocation, setSelectedDestinationLocation] = useState<AutoCompleteprops | null>(null);
    const [DestinationData, setDestinationData] = useState<any[]>([]);
    const [mainLocattionData, setmainLocationData] = useState<any[]>([]);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [dateValue, setDateValue] = useState<Date | undefined>();
    const [priorityLevelData, setPriorityLevelData] = useState<AutoCompleteprops[]>([]);
    const [selectedPriorityLevel, setSelectedPriorityLevel] = useState<number>(3);
    const [isHighPriority, setIsHightPriority] = useState<boolean>(false);
    const [locationData, setLocationData] = useState<any[]>([])
    const { locations } = useAppSelector(state => state.location)

    let initialValues = {
        source: '',
        destination: '',
        asset: '',
        attender: '',
        description: '',
        ipId: '',
        patient: '',
    }
    const formikjob = useFormik({
        initialValues: initialValues,
        validationSchema: yup.object({
            source: yup.number().required("Required"),
            destination: yup.number().required("Required"),
            asset: yup.string().required("Required"),
            attender: yup.string().required("Required"),

        }),
        onSubmit: async (values, { resetForm }: any) => {
            let param: JobCreate = {
                AssigneeId: values.attender,
                SourceLocationId: Number(values.source),
                DestinationLocationId: Number(values.destination),
                AssetId: Number(values.asset),
                Description: values.description,
                StoreId: await getStoreId(),
                StatusId: dateValue ? 2 : 1,
                ScheduleTime: dateValue,
                PatientName: values.patient,
                PriorityId: selectedPriorityLevel
            }
            let res = await dispatch(CreateJob(param));
            if (res && res.meta.requestStatus === "rejected") {
                setSnackBarMessage("Connection Refused");
                setShowSnackBar(true);
                return;
            }
            if (res && res.payload.status == true || res.payload.status == 200) {
                await dispatch(GetJobList());
                setSnackBarMessage("job added sucessfully!!");
                setShowSnackBar(true);
                resetForm();
                setSelectedAsset(null);
                setSelectedAttender(null);
                setSelectedDestinationLocation(null);
                setSelectedSourceLocation(null);
                let undefinedValue;
                setDateValue(undefinedValue);
                await dispatch(GetJobInitialDetails());
                await dispatch(GetAssetAttenderCount());
                setIsHightPriority(false);
            } else {
                setSnackBarMessage(res.payload.data);
                setShowSnackBar(true);
            }
        }
    });

    useEffect(() => {
        dispatch(GetJobInitialDetails());
        dispatch(GetLocationList());
    }, [])

    useEffect(() => {
        if (JobInitial != null) {
            let newlocation = JobInitial.locations && JobInitial.locations.map((data: Location) => {
                return { label: data.locationName, id: data.locationId };
            })
            let newAssetData: AutoCompleteprops[] = JobInitial.assets && JobInitial.assets.map((data: AssetDataModel) => {
                return { label: data.assetName, id: data.assetId };
            })
            let newAttenderData: AutoCompleteprops[] = JobInitial.users && JobInitial.users.map((data: UserDataSourceModel) => {
                return { label: data.displayName, id: data.userId };
            });
            let newPriorityData: AutoCompleteprops[] = JobInitial.taskPriority && JobInitial.taskPriority.map((data: taskPriority) => {
                return { label: data.label, id: data.id }
            });
            // formikjob.setFieldValue("priority", formikjob.values.priority !== null ? formikjob.values.priority : "3");
            // setSelectedPriorityLevel({ label: 'Low', id: 3 });
            setSourceData(newlocation);
            setAssetData(newAssetData)
            setAttenderData(newAttenderData);
            setDestinationData(newlocation);
            setmainLocationData(newlocation);
            setPriorityLevelData(newPriorityData);
            setAssetPassData(JobInitial.assets);
            setLocationData(JobInitial.locations)
        }
    }, [JobInitial]);

    var changeSorceLocationValue = async (id: number | string) => {
        formikjob.setFieldValue("source", id);
        let newDestination = await mainLocattionData.filter((item: AutoCompleteprops) => item.id !== id)
        const currentSelectedSource: any = await SourceData.length > 0
        ? SourceData.find((item: any) => item.id === id)
        : null;
        setSelectedSourceLocation(currentSelectedSource);
        setDestinationData(newDestination);
    }
    var changeDestinationLocationValue = async (id: number | string) => {
        formikjob.setFieldValue("destination", id);
        const currentSelectedDestination: any = await DestinationData.length > 0
        ? DestinationData.find((item: AutoCompleteModel) => item.id === id)
        : null;
        setSelectedDestinationLocation(currentSelectedDestination);
        let newSource =await mainLocattionData.filter((item: AutoCompleteprops) => item.id !== id);
        setSourceData(newSource);
    }
    var changeAttenderValue = (id: number | string) => {
        formikjob.setFieldValue("attender", id);
        let currentSelectedAttender: any = attenderData.length > 0 ? attenderData.map((data: AutoCompleteprops) => {
            if (id == data.id) {
                return data.label;
            }
        }).filter((data: any) => data != undefined) : [];
        setSelectedAttender(currentSelectedAttender);
    }
    var changeAssetValue = (id: number | string) => {
        formikjob.setFieldValue("asset", id);
        const currentSelectedAsset: any = assetData.length > 0 ? assetData.find((item: AutoCompleteModel) => item.id === id)
            : null;
        setSelectedAsset(currentSelectedAsset);
    }
    const handleClearSource = () => {

        formikjob.values.source = "";
        setSelectedSourceLocation(null);
        let filterSource: any = mainLocattionData.filter((item) => item.id !== selectedDestinationLocation?.id);
        setSourceData(filterSource);
        setDestinationData(mainLocattionData)
    }
    const handleClearDestination = () => {

        formikjob.values.destination = "";
        let filterDestination: any = mainLocattionData.filter((item) => item.id !== selectedSourceLocation?.id);
        setSelectedDestinationLocation(null);
        setSourceData(mainLocattionData)
        setDestinationData(filterDestination);
    }
    const handleClearAsset = () => {
        formikjob.values.asset = "";
        setSelectedAsset(null);
    }
    const handleClearAttender = () => {
        formikjob.values.attender = "";
        setSelectedAttender(null);
    }

    const handleDateChange = (date: Date) => {
        setDateValue(new Date(date));
    }
    const handlePriorityChange = (event: any) => {
        if (event.target.checked) {
            setSelectedPriorityLevel(1);
        } else {
            setSelectedPriorityLevel(3);
        }
        setIsHightPriority(event.target.checked);
    };

    return (
        <Grid container justifyContent="center">
            <Grid item xs={12} md={12}>
                <Grid
                    container
                    sx={{
                        backgroundImage: (Theme) => `linear-gradient(100deg, rgba(46,37,134,1) 75%, rgba(93,81,204,1) 100%);`,
                        padding: "1.5rem",
                        borderRadius: "1rem",
                        width: "100%",
                        boxShadow: (Theme) => Theme.shadows[10],
                    }}
                >
                    <Grid
                        container
                        spacing={2}
                        sx={{ justifyContent: { xs: "center", lg: "left", md: "left" } }}
                    >
                        <Grid item xs={8} md={4} sm={6}>
                            <TextLabel label="Source Location" color={Theme.palette.primary.contrastText} required />
                            <LocationAutoComplete
                                value={selectedSourceLocation}
                                data={locationData.filter((data) => data.locationId !== selectedDestinationLocation?.id)}
                                required={true}
                                onClear={handleClearSource}
                                error={formikjob.touched.source && Boolean(formikjob.errors.source)}
                                helperText={formikjob.touched.source && formikjob.errors.source}
                                title="Source Location"
                                onClick={changeSorceLocationValue}
                            // locationData={JobInitial?.locations??locations}
                            />
                            {/* <AutoComplete
                                color={Theme.palette.primary.contrastText}
                                value={selectedSourceLocation}
                                data={SourceData ?? []}
                                required={true}
                                onClear={handleClearSource}
                                error={formikjob.touched.source && Boolean(formikjob.errors.source)}
                                helperText={formikjob.touched.source && formikjob.errors.source}
                                title="Source Location"
                                onClick={changeSorceLocationValue}
                            /> */}
                        </Grid>
                        <Grid item xs={8} md={4} sm={6}>
                            <TextLabel label="Destination Location" color={Theme.palette.primary.contrastText} required />
                            <LocationAutoComplete
                                value={selectedDestinationLocation}
                                data={locationData.filter((data) => data.locationId !== selectedSourceLocation?.id)}
                                required={true}
                                onClear={handleClearDestination}
                                error={formikjob.touched.destination && Boolean(formikjob.errors.destination)}
                                helperText={formikjob.touched.destination && formikjob.errors.destination}
                                title="Destination Location"
                                onClick={changeDestinationLocationValue}
                            // locationData={JobInitial?.locations??locations}
                            />
                            {/* <AutoComplete
                                color={Theme.palette.primary.contrastText}
                                value={selectedDestinationLocation}
                                data={DestinationData ?? []}
                                required={true}
                                onClear={handleClearDestination}
                                error={formikjob.touched.destination && Boolean(formikjob.errors.destination)}
                                helperText={formikjob.touched.destination && formikjob.errors.destination}
                                title="Destination Location"
                                onClick={changeDestinationLocationValue}
                            /> */}
                        </Grid>
                        <Grid item xs={8} md={4} sm={6}>
                            <TextLabel label="Asset" color={Theme.palette.primary.contrastText} required />
                            <AssetAutoComplete
                                color={Theme.palette.primary.contrastText}
                                value={selectedAsset}
                                data={JobInitial?.assets ?? []}
                                title="Asset"
                                onClick={changeAssetValue}
                                required={true}
                                onClear={handleClearAsset}
                                error={formikjob.touched.asset && Boolean(formikjob.errors.asset)}
                                helperText={formikjob.touched.asset && formikjob.errors.asset}
                            />
                            {/* <AutoComplete
                                color={Theme.palette.primary.contrastText}
                                value={selectedAsset}
                                data={assetData ?? []}
                                title="Asset"
                                onClick={changeAssetValue}
                                required={true}
                                onClear={handleClearAsset}
                                error={formikjob.touched.asset && Boolean(formikjob.errors.asset)}
                                helperText={formikjob.touched.asset && formikjob.errors.asset}
                            /> */}
                        </Grid>
                        <Grid item xs={8} md={4} sm={6} sx={{ justifyContent: "left" }}>
                            <TextLabel label="Attender" color={Theme.palette.primary.contrastText} required />
                            <AutoComplete
                                color={Theme.palette.primary.contrastText}
                                value={selectedAttender}
                                data={attenderData ?? []}
                                title="Attender"
                                onClick={changeAttenderValue}
                                required={true}
                                onClear={handleClearAttender}
                                error={formikjob.touched.attender && Boolean(formikjob.errors.attender)}
                                helperText={formikjob.touched.attender && formikjob.errors.attender}
                            />
                        </Grid>

                        {/* //Priority */}
                        {/* <Grid item xs={8} md={4} sm={6}>
                            <TextLabel label="Priority" color={Theme.palette.primary.contrastText} />
                            <AutoComplete
                                color={Theme.palette.primary.contrastText}
                                // value={selectedPriorityLevel!==null?selectedPriorityLevel:[{label:'Low',id:3}]}
                                value={selectedPriorityLevel}
                                data={priorityLevelData}
                                title="Priority"
                                onClick={changePriorityValue}
                                required={false}
                                onClear={handleClearPriority}
                                error={formikjob.touched.priority && Boolean(formikjob.errors.priority)}
                                helperText={formikjob.touched.priority && formikjob.errors.priority}
                            />
                        </Grid> */}
                        <Grid item xs={8} md={4} sm={6} sx={{ justifyContent: "left" }}>
                            <TextLabel label="Description" color={Theme.palette.primary.contrastText} />
                            <TextBox
                                type="text"
                                formik={formikjob}
                                error={
                                    Boolean(formikjob.touched.description) &&
                                    Boolean(formikjob.errors.description)
                                }
                                fieldpropsName="description"
                                helpertext={
                                    formikjob.touched.description
                                        ? String(formikjob.errors.description)
                                        : ""
                                }
                                label="Description"
                                sx={Patientstyle.textboxStyles}
                            />

                        </Grid>
                        <Grid item xs={8} md={4} sm={6} sx={{ justifyContent: "left" }}>
                            <TextLabel label="IP Id / Patient Name" color={Theme.palette.primary.contrastText} />
                            <TextBox
                                type="text"
                                formik={formikjob}
                                error={
                                    Boolean(formikjob.touched.patient) &&
                                    Boolean(formikjob.errors.patient)
                                }
                                fieldpropsName="patient"
                                helpertext={
                                    formikjob.touched.patient
                                        ? String(formikjob.errors.patient)
                                        : ""
                                }
                                label="IP Id / Patient Name"
                                sx={Patientstyle.textboxStyles}
                            />

                        </Grid>
                        <Grid item xs={8} md={2} sm={4} sx={{ justifyContent: "left" }}>
                            <FormGroup>
                                {priorityLevelData.filter((data) => data.label === "High").map((priority) => (
                                    <FormControlLabel
                                        key={priority.id}
                                        control={
                                            <Checkbox
                                                checked={isHighPriority}
                                                onChange={handlePriorityChange}
                                                name={priority.label}
                                                sx={{
                                                    color: pink[800],
                                                    '&.Mui-checked': {
                                                        color: pink[600],
                                                    },
                                                }}
                                            />
                                        }
                                        label="Emergency"
                                        style={{ color: Theme.palette.primary.contrastText }}
                                    />
                                ))}
                            </FormGroup>
                        </Grid>
                        <Grid item xs={8} md={10} sm={8}>
                            <Box sx={{ display: "flex", justifyContent: "flex-end", float: "right" }}>
                                <Button onClick={() => {
                                    setIsDatePickerOpen(true);
                                }} variant="contained" color='success' sx={selectComponentButtonStyles}>
                                    Schedule
                                </Button>
                                <ThemeProvider theme={datePickerTheme}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <MobileTimePicker
                                            sx={{ display: "none" }}
                                            value={dayjs(dateValue)}
                                            open={isDatePickerOpen}
                                            onClose={() => setIsDatePickerOpen(false)}
                                            onChange={(date: any) => handleDateChange(date)}
                                            onAccept={() => {
                                                formikjob.handleSubmit();
                                            }}
                                        />
                                    </LocalizationProvider>
                                </ThemeProvider>
                                <Button onClick={() => {
                                    formikjob.handleSubmit();
                                }} variant="contained" color='success' sx={selectComponentButtonStyles}>
                                    Assign Now
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <SnackbarComponent
                setShowSnackBar={setShowSnackBar}
                showSnackBar={showSnackBar}
                snackBarMessage={snackBarMessage}
            />
        </Grid>
    )
}
export default AddJobs
