import React, { useState } from 'react';
import { IconButton, InputAdornment, OutlinedInput, SxProps, TextField, Theme as MuiTheme, Grid, createTheme, ThemeProvider } from '@mui/material';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import Theme, { datePickerTheme } from '../../theme/theme';
import {
    LocalizationProvider,
    MobileTimePicker,
    MobileDateTimePicker,
    MobileDatePicker
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

interface timePickerProps {
    color?: string
    sx?: SxProps<MuiTheme>;
    placeholder: string;
    dateValue: Date | string;
    inputValue: string;
    isDateTimePicker?: boolean
    isDatePicker?: boolean
    handleChange: (date: Date) => void;
    minimumDate?: Date | null;
}

const DateTimePickerTextBox = ({ color,
    sx,
    placeholder,
    dateValue,
    inputValue,
    handleChange,
    isDateTimePicker = false,
    isDatePicker = false,
    minimumDate = null }: timePickerProps) => {

    const [showDateTimePicker, setShowDateTimePicker] = useState(false);

    return (
        <ThemeProvider theme={datePickerTheme}>
            <Grid>
                <OutlinedInput
                    readOnly
                    fullWidth
                    value={inputValue}
                    inputProps={{
                        style: { color: "black", cursor: "pointer" },
                        classes: {
                            notchedOutline: {
                                borderColor: color ? color : "black",
                            },
                            input: {
                                "&::placeholder": {
                                    color: "rgba(0, 0, 0, 0.92)",
                                },
                            },
                        },
                    }}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => { setShowDateTimePicker(true) }}
                                edge="end"
                            >
                                {!isDateTimePicker && !isDatePicker
                                    ? <AccessTimeFilledIcon />
                                    : <CalendarMonthIcon />}
                            </IconButton>
                        </InputAdornment>
                    }
                    placeholder={placeholder}
                    onClick={() => { setShowDateTimePicker(true) }}
                    sx={{
                        "&  .MuiFormHelperText-root.Mui-error": {
                            backgroundColor: "transparent",
                            margin: 0,
                        },
                        "&.custom-border .MuiOutlinedInput-root": {
                            borderColor: color ? color : "white",
                        },
                        "& .MuiInputBase-input": {
                            color: color ? color : "",
                            padding: "11px !important",
                            fontSize: "small",
                            "&::placeholder": {
                                color: "black",
                            },
                        },
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                                borderColor: color ? color : "",
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: color ? color : "",
                            },
                        },
                        backgroundColor: color ? color : Theme.palette.common.white,
                        borderRadius: "10px",
                        ...sx,
                    }}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    {isDateTimePicker && <MobileDateTimePicker
                        sx={{ display: "none" }}
                        value={dayjs(dateValue)}
                        open={showDateTimePicker}
                        onClose={() => setShowDateTimePicker(false)}
                        onChange={(date: any) => handleChange(date)}
                        onAccept={() => {
                            setShowDateTimePicker(false);
                        }}
                        minDateTime={dayjs(minimumDate)}
                    />}
                    {isDatePicker && <MobileDatePicker
                        sx={{ display: "none" }}
                        value={dayjs(dateValue)}
                        open={showDateTimePicker}
                        onClose={() => setShowDateTimePicker(false)}
                        onChange={(date: any) => handleChange(date)}
                        onAccept={() => {
                            setShowDateTimePicker(false);
                        }}
                        minDate={dayjs(minimumDate)}
                    />}
                    {!isDateTimePicker && !isDatePicker && <MobileTimePicker
                        sx={{ display: "none" }}
                        value={dayjs(dateValue)}
                        open={showDateTimePicker}
                        onClose={() => setShowDateTimePicker(false)}
                        onChange={(date: any) => handleChange(date)}
                        onAccept={() => {
                            setShowDateTimePicker(false);
                        }}
                        minTime={dayjs(minimumDate)}
                    />}
                </LocalizationProvider>
            </Grid>
        </ThemeProvider>
    )
}

export default DateTimePickerTextBox;