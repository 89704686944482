import Theme from "../../theme/theme";
export const Patientstyle: any = {
  containerStyles: {
    backgroundColor: Theme.palette.common.layout.commonBlue,
    borderRadius: "15px",
    padding: "1rem",
    display: "flex",
    justifyContent: "space-between",
  },
  titleStyles: {
    color: "white",
  },
  selectBoxStyle: {
    color: "black",
    width: {
      sm: "100%",
      md: "95%",
    },
    borderRadius: "10px !important",
  },
  textboxStyles: {
    "& .MuiInputBase-root": {
      backgroundColor: "white",
      color: "black",
      borderRadius: "10px",
      width: {
        sm: "100%",
        md: "95%",
      },
      outline: "none",
    },
    "& .MuiFormHelperText-root": {
      backgroundColor: "transparent",
      color: "red",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:hover:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
  },
  buttonStyles: {
    backgroundColor: Theme.palette.common.layout.patientButton,
    "&:hover": {
      backgroundColor: Theme.palette.common.layout.buttonHoverBackground,
    },
    "&.Mui-disabled": {
      backgroundColor: Theme.palette.common.layout.disabledButton,
    },
  },
  clearButton: {
    border: `1px solid ${Theme.palette.common.layout.borderclr}`,
    color: "white",
    "&:hover": {
      backgroundColor: Theme.palette.common.layout.clearButton,
      border: `1px solid ${Theme.palette.common.layout.borderclr}`,
    },
  },
  accordionStyles: {
    maxWidth: "95%",
    borderRadius: "2%",
    "&:before": {
      display: "none",
    },
    "& .MuiAccordion-root": {
      borderRadius: "15px",
    },
    "& .MuiPaper-root": {
      borderRadius: "15px",
    },
    "& .MuiAccordionSummary-root": {
      borderRadius: "15px 15px 0 0",
    },
    "& .MuiAccordionDetails-root": {
      borderRadius: "0 0 15px 15px",
    },
  },
};
