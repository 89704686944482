import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getHeaderToken } from "../../Utilities/header";
import { DataSourceItem, UserListValue } from "../../Model/AddUserModels";
import { AsyncThunkConfig } from "@reduxjs/toolkit/dist/createAsyncThunk";
import { PatientModel } from "../../Model/PatientModel";
import { PatientDataModel } from "../../Model/PatientModel";
import { getStoreId } from "../../Utilities/Store";

export interface PatientResponse {
  status: boolean;
  data: PatientDataModel[];
  statusCode: number;
  message: string;
}

export interface SinglePatientResponse {
  status: boolean;
  data: PatientDataModel;
  statusCode: number;
  message: string;
}

export interface ErrorResponse {
  status: number;
  message: string;
  errors?: { [key: string]: string[] };
  data: any | string;
  statusCode: number;
}

const BASE_URL = process.env.REACT_APP_BASEURL;
export const getAllPatients = createAsyncThunk(
  "Patient/GetAllPatientByStoreId",
  async () => {
    try {
      const URL = `${BASE_URL}/api/Patient/patientsList/${await getStoreId()}`;
      const response = await axios.get(URL, await getHeaderToken());
      return response.data as PatientResponse;
    } catch (e: any) {
      return e.response.data as ErrorResponse;
    }
  }
);

export const createPatient = createAsyncThunk(
  "Patient/createPatient",
  async (patient: any) => {
    try {
      const URL = `${BASE_URL}/api/Patient/createPatient`;
      const response = await axios.post(URL, patient, await getHeaderToken());
      return response.data as PatientResponse;
    } catch (e: any) {
      return e.response.data as ErrorResponse;
    }
  }
);

export interface GetPatientByIdArgs {
  patientId: number;
  storeId: number;
}

export const getSinglePatient = createAsyncThunk(
  "GetPatientById",
  async ({ patientId }: { patientId: number }) => {
    try {
      const URL = `${BASE_URL}/api/Patient/patientDetails/${patientId}/${await getStoreId()}`;
      const response = await axios.get(URL, await getHeaderToken());
      return response.data as SinglePatientResponse;
    } catch (e: any) {
      return e.response.data as ErrorResponse;
    }
  }
);

export const updatePatient = createAsyncThunk(
  "updatePatient",
  async (patient: object) => {
    try {
      const URL = `${BASE_URL}/api/Patient/update/patient`;
      const response = await axios.put(URL, patient, await getHeaderToken());
      return response.data as PatientResponse;
    } catch (e: any) {
      return e.response.data as ErrorResponse;
    }
  }
);
