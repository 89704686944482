import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/Store";
import {
  CreateLocation,
  GetLocationById,
  GetLocationList,
  deleteLocation,
  updateLocation,
} from "../../Redux/Thunks/LocationThunk";
import { Grid, Tooltip, Box, IconButton, Fab, Container } from "@mui/material";
import DataTable from "../../Components/DataTable/DataTable";
import { ColDef } from "ag-grid-community";
import { useNavigate } from "react-router-dom";
import AddLocation from "./AddLocation";
import Title from "../../Components/Title/Title";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "../../Components/Loader/Loader";
import { useFormik } from "formik";
import * as yup from "yup";
import { getStoreId } from "../../Utilities/Store";
import { AddLocationModal, CreateLocationModel } from "../../Model/Location";
import { AutoComplete as AutoCompleteType } from "../../Model/AutoCompleteModel";
import SnackbarComponent from "../../Components/SnackBar/Snackbar";
import DialogBox from "../../Components/DialogBox/DialogBox";

const LocationList: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { locations, location, isLoading } = useAppSelector(
    (state) => state.location
  );
  const [tableData, setTableData] = useState<any>([]);
  const [id, setId] = useState(0);
  const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [deleteLocationId, setDeleteLocationId] = useState(0);
  const [selectedBlock, setSelectedBlock] = useState<AutoCompleteType | null>(
    null
  );
  const [selectedFloor, setSelectedFloor] = useState<AutoCompleteType | null>(
    null
  );
  const [selectedLocationType, setSelectedLocationType] =
    useState<AutoCompleteType | null>(null);
  const [locationTableData, setLocationTableData] = useState<any[]>([]);
  useEffect(() => {
    dispatch(GetLocationList());
  }, []);
  useEffect(() => {
    if (locations && locations.length > 0) {
      setLocationTableData(locations.filter((data) => data.locationId != 0));
    }
  }, [locations]);

  const initialValues: AddLocationModal = {
    block: "",
    floor: "",
    locationType: "",
    room: "",
  };

  const formikLocation = useFormik({
    initialValues: initialValues,
    validationSchema: yup.object({
      block: yup.string().required("Required"),
      floor: yup.string().required("Required"),
      locationType: yup.string().required("Required"),
      room: yup
        .string()
        .transform((value: string) => (value ? value.toUpperCase() : value))
        .required("Required"),
    }),
    onSubmit: async (values, { resetForm }: any) => {
      if (
        location &&
        location.locationName == values.room &&
        location.blockId == Number(values.block) &&
        location.floorId == Number(values.floor) &&
        location.locationTypeId == Number(values.locationType)
      ) {
        setSnackBarMessage("Please change details to update");
        setShowSnackBar(true);
        return;
      }
      if (
        Number(values.block) < 1 ||
        Number(values.floor) < 1 ||
        Number(values.locationType) < 1
      ) {
        setSnackBarMessage("Please provide all details");
        setShowSnackBar(true);
        return;
      }
      if (id < 1) {
        var details: CreateLocationModel = {
          BlockId: Number(values.block),
          FloorId: Number(values.floor),
          LocationTypeId: Number(values.locationType),
          StoreId: await getStoreId(),
          LocationName: values.room,
        };
        let res = await dispatch(CreateLocation(details));
        if (res && res.meta.requestStatus === "rejected") {
          setSnackBarMessage("Connection Refused");
          setShowSnackBar(true);
          return;
        }
        if (
          (res && res.payload && res.payload.status == true) ||
          res.payload.status == 200
        ) {
          setSnackBarMessage("Location added sucessfully!!");
          setShowSnackBar(true);
          await dispatch(GetLocationList());
          resetForm();
          setId(0);
          setSelectedBlock(null);
          setSelectedFloor(null);
          setSelectedLocationType(null);
        } else {
          setSnackBarMessage(res.payload.data);
          setShowSnackBar(true);
        }
      } else {
        var details: CreateLocationModel = {
          LocationId: id,
          BlockId: Number(values.block),
          FloorId: Number(values.floor),
          LocationTypeId: Number(values.locationType),
          StoreId: await getStoreId(),
          LocationName: values.room,
        };
        let res = await dispatch(updateLocation(details));
        if (res && res.meta.requestStatus === "rejected") {
          setSnackBarMessage("Connection Refused");
          setShowSnackBar(true);
          return;
        }
        if (
          (res && res.payload && res.payload.status == true) ||
          res.payload.status == 200
        ) {
          await dispatch(GetLocationList());
          setSnackBarMessage("Location updated sucessfully!!");
          setShowSnackBar(true);
          resetForm();
          setId(0);
          setSelectedBlock(null);
          setSelectedFloor(null);
          setSelectedLocationType(null);
        } else {
          setSnackBarMessage(res.payload.data);
          setShowSnackBar(true);
        }
      }
      await dispatch(GetLocationList());
    },
  });

  const TokenRenderer = (props: any) => {
    return (
      <Tooltip title="type" placement="bottom-start">
        <Box>{props.data.locationTypeName}</Box>
      </Tooltip>
    );
  };

  const onEdit = async (data: any) => {
    if (data.locationId > 0) {
      formikLocation.setFieldValue("floor", "");
      setSelectedFloor(null);
      await dispatch(GetLocationById(data.locationId));
    }
    setId(data.locationId);
  };

  const onClear = () => {
    setSelectedLocationType(null);
    setSelectedFloor(null);
    setSelectedBlock(null);
    setId(0);
    formikLocation.resetForm();
  }

  const onDelete = async (data: any) => {
    setDeleteLocationId(data.locationId);
    setShowDialog(true);
  };

  const handleDisagree = () => {
    setShowDialog(false);
  };

  const onBlockChange = (block: AutoCompleteType | null) => {
    if (block !== selectedBlock && block !== null && id > 0) {
      formikLocation.setFieldValue("floor", "");
      setSelectedFloor(null);
    }
    setSelectedBlock(block);
  };

  const handleAgree = async () => {
    setShowDialog(false);
    let res = await dispatch(deleteLocation(deleteLocationId));
    if (res.meta.requestStatus === "rejected") {
      setSnackBarMessage("Connection Refused");
      setShowSnackBar(true);
      return;
    }
    if (
      (res.payload && res.payload.status && res.payload.status == true) ||
      res.payload.status == 200
    ) {
      setSnackBarMessage("location deleted sucessfully!!");
      setShowSnackBar(true);
      dispatch(GetLocationList());
    } else {
      setSnackBarMessage(res.payload.data);
      setShowSnackBar(true);
    }
  };

  const ActionRenderer = (props: any) => {
    return (
      <div>
        <Box aria-label="Actions">
          <Tooltip title="Edit" placement="bottom-start">
            <IconButton onClick={() => onEdit(props.data)}>
              <EditOutlinedIcon color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" placement="bottom-start">
            <IconButton onClick={() => onDelete(props.data)}>
              <DeleteIcon color="primary" />
            </IconButton>
          </Tooltip>
        </Box>
      </div>
    );
  };

  const columnDefs: ColDef[] = [
    {
      field: "no",
      headerName: "No",
      maxWidth: 75,
      valueGetter: 'node.rowIndex + 1',
    },
    {
      field: "locationName",
      headerName: "Name",
      minWidth: 75,
    },
    {
      field: "locationTypeName",
      headerName: "Type",
      minWidth: 104,
      cellRenderer: TokenRenderer,
    },
    {
      field: "floorName",
      headerName: "Floor",
      minWidth: 150,
    },
    {
      field: "blockName",
      headerName: "Block",
      minWidth: 150,
    },
    { field: "actions", headerName: "Actions", cellRenderer: ActionRenderer },
  ];

  return (
    <Box pr={2} pt={1}>
      <Grid spacing={1.5} container padding={{ md: 1.4, sm: 2 }}>
        <Grid md={12} xs={12}>
          <AddLocation
            formikLocation={formikLocation}
            setSelectedBlock={onBlockChange}
            setSelectedFloor={setSelectedFloor}
            setSelectedLocationType={setSelectedLocationType}
            selectedBlock={selectedBlock}
            selectedFloor={selectedFloor}
            selectedLocationType={selectedLocationType}
            id={id}
            onClear={onClear}
            setShowSnackBar={setShowSnackBar}
            setSnackBarMessage={setSnackBarMessage}
          />
        </Grid>
        <Title title="Locations" />
        {locations && locations.filter((data) => data.locationId != 0) && (
          <DataTable rows={locationTableData} columnDefs={columnDefs} />
        )}
        <Loader openModal={isLoading} />
        <SnackbarComponent
          setShowSnackBar={setShowSnackBar}
          showSnackBar={showSnackBar}
          snackBarMessage={snackBarMessage}
        />
        <DialogBox
          message={"Do you want to delete the location?"}
          handleAccept={() => handleAgree()}
          handleCancel={handleDisagree}
          open={showDialog}
        />
      </Grid>
    </Box>
  );
};

export default LocationList;
