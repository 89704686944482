import React from 'react';
import './Loader.css';
import { Dialog } from '@mui/material';

const Loader = (props: any) => {
  const { openModal } = props;


  return (
    <Dialog open={openModal} fullWidth maxWidth="xs">
      <div className="full-screen-background">
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      </div>
    </Dialog>
  );
};

export default Loader;



