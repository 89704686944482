import { Box, Card, Grid, IconButton, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import DeleteIcon from "@mui/icons-material/Delete";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useAppDispatch, useAppSelector } from '../../../Redux/Store';
import { deleteShiftAssignment, getAssignedUsers, getShiftAssignmentInitialData, updateAssignedUsers } from '../../../Redux/Thunks/ShiftThunk';
import { convertUTCToLocal } from '../../../Utilities/Common';
import { ColDef } from 'ag-grid-community';
import Title from '../../../Components/Title/Title';
import Label from '../../../Components/Label/Label';
import DataTable from '../../../Components/DataTable/DataTable';
import Theme from '../../../theme/theme';
import ShiftAllocationUpdateForm from './ShiftAllocationUpdateForm';
import { shiftAssignentLocationInitial, shiftAssignmentInitialDataModel, updateAssignedUsersModel, UserShiftAssignmentDeleteModel } from '../../../Model/ShiftModels';
import { useFormik } from 'formik';
import { unwrapResult } from '@reduxjs/toolkit';
import SnackbarComponent from '../../../Components/SnackBar/Snackbar';
import { getStoreId } from '../../../Utilities/Store';
import { AutoComplete } from '../../../Model/AutoCompleteModel';
import * as yup from 'yup';
import Loader from '../../../Components/Loader/Loader';
import DialogBox from '../../../Components/DialogBox/DialogBox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ShiftView = () => {

    const [searchParams] = useSearchParams();
    const shiftId = searchParams.get("shiftId");

    const { isLoading, AssignedShiftUsers, shiftAssignmentInitialData } = useAppSelector((state) => state.shift);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const [deleteShiftAssignmentData, setDeleteShiftAssignmentData] = useState<any>();
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [selectedLocation, setSelectedLocation] = useState<shiftAssignentLocationInitial | null>(null);
    const [selectedShift, setSelectedShift] = useState<AutoComplete | null>(null);
    const [locations, setLocations] = useState<any[]>([])
    const initialValues: updateAssignedUsersModel = {
        userShiftAssignmentId: 0,
        storeId: 0,
        userId: "",
        displayName: "",
        shiftId: 0,
        fromDate: new Date(),
        toDate: new Date(),
        shiftName: "",
        locationId: 0
    }

    const handleDisagree = () => {
        setShowDialog(false);
    }

    const onDelete = async () => {
        let deleteParam: UserShiftAssignmentDeleteModel = {
            userShiftAssignmentId: deleteShiftAssignmentData?.userShiftAssignmentId,
            storeId: await getStoreId(),
            shiftId: AssignedShiftUsers?.shiftId
        };
        let res = await dispatch(deleteShiftAssignment(deleteParam));
        if (res.meta.requestStatus === "rejected") {
            setSnackBarMessage("Connection Refused");
            setShowSnackBar(true);
            return;
        }
        if (
            (res.payload && res.payload.status && res.payload.status == true) ||
            res.payload.status == 200
        ) {
            setSnackBarMessage("Shift Allocation deleted successfully!!");
            setShowSnackBar(true);
            setShowDialog(false);

        } else {
            setSnackBarMessage(res.payload.data);
            setShowSnackBar(true);
        }
        let params = {
            storeId: await getStoreId(),
            fromDate: new Date(),
            toDate: new Date()
        }
        await getUsers();
        await dispatch(getShiftAssignmentInitialData(params));
    }

    const userAssignFormik = useFormik({
        initialValues,
        validationSchema: yup.object().shape({
            locationId: yup.number().min(1, "Required").required("Required"),
        }),
        onSubmit: async (values) => {
            let params = { ...values, storeId: await getStoreId() };
            const result = unwrapResult(await dispatch(updateAssignedUsers(params)));
            if (result === undefined) {
                setSnackBarMessage("Something went wrong!");
                setShowSnackBar(true);
                return;
            }
            if (result.status === true && result.data) {
                userAssignFormik.resetForm();
                setSnackBarMessage(result.message);
                setShowSnackBar(true);
                setOpenUpdateDialog(false);
            } else {
                setSnackBarMessage(result.data);
                setShowSnackBar(true);
            }
            await getUsers();
        }
    })

    useEffect(() => {
        getUsers();
        getShiftAssignmentInitial();
    }, [])

    const getShiftAssignmentInitial = async () => {
        let params: shiftAssignmentInitialDataModel = {
            storeId: await getStoreId(),
            fromDate: new Date(),
            toDate: new Date()
        }
        await dispatch(getShiftAssignmentInitialData(params));
    }

    const getUsers = async () => {
        if (shiftId) {
            await dispatch(getAssignedUsers(isNaN(Number(shiftId)) ? 0 : Number(shiftId)));
        }
    }

    const startDateRenderer = (props: any) => {
        return <Grid>
            {convertUTCToLocal(props.data.fromDate, "MMMM D, YYYY h:mm A")}
        </Grid>
    }

    const endDateRenderer = (props: any) => {
        return <Grid>
            {convertUTCToLocal(props.data.toDate, "MMMM D, YYYY h:mm A")}
        </Grid>
    }
    const confirmDelete = (data: any) => {
        setDeleteShiftAssignmentData(data);
        setShowDialog(true);
        setDialogMessage("Are you sure want to delete shift assignment?")
    }

    const ActionRenderer = (props: any) => {
        return (
            <div>
                <Box aria-label="Actions">
                    <Tooltip title="Edit" placement="bottom-start">
                        <IconButton onClick={() => onEdit(props.data)}>
                            <EditOutlinedIcon color="primary" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete" placement="bottom-start">
                        <IconButton onClick={() => confirmDelete(props.data)}>
                            <DeleteIcon color="primary" />
                        </IconButton>
                    </Tooltip>
                </Box>
            </div>
        );
    };

    const onEdit = async (props: any) => {
        userAssignFormik.setValues({
            userShiftAssignmentId: props.userShiftAssignmentId,
            userId: props.userId,
            displayName: props.displayName,
            shiftId: AssignedShiftUsers.shiftId,
            fromDate: props.fromDate,
            toDate: props.toDate,
            storeId: 0,
            shiftName: AssignedShiftUsers.shiftName,
            locationId: props.locationId
        })

        let locationData = shiftAssignmentInitialData.locations.map((item: any) => {
            return { id: item.locationId, label: item.locationName }
        }).filter(data => data !== undefined)
        setLocations(locationData)
        const selectedLocation = shiftAssignmentInitialData.locations.find((item: shiftAssignentLocationInitial) => item.id === props.locationId);
        let selectedShift = shiftAssignmentInitialData.shifts.find(shift => shift.id === props.shiftId);
        setSelectedLocation(selectedLocation ?? null);
        setSelectedShift(selectedShift ?? null);
        setOpenUpdateDialog(true);
    }

    const onUpdateDialogClose = () => {
        userAssignFormik.resetForm();
        setOpenUpdateDialog(false);
    }

    const handleBackClick = () => {
        navigate(-1);
    }

    const colDefs: ColDef[] = [
        { field: "no", headerName: "No", maxWidth: 75, valueGetter: 'node.rowIndex + 1' },
        { field: "actions", headerName: "Actions", maxWidth: 150, cellRenderer: ActionRenderer },
        { field: 'displayName', headerName: "Attender Name", },
        { field: 'locationName', headerName: "Location Name" },
        { field: 'fromDate', headerName: "From", cellRenderer: startDateRenderer },
        { field: 'toDate', headerName: "To", cellRenderer: endDateRenderer },
    ];

    return (
        <Box marginTop={3} paddingRight={4}>
            <Title
                title="Shift Allocation Details"
                sx={{ color: Theme.palette.common.black }}
            />
            <Card elevation={3} sx={{ marginTop: 1 }}>
                <Grid container sx={{ padding: "25px 20px" }}>
                    <Grid item xs={12} md={1}>
                        <ArrowBackIcon sx={{ color: Theme.palette.primary.main, cursor: "pointer" }} onClick={handleBackClick} />
                    </Grid>
                    <Grid xs={6} md={2.5}>
                        <Label label="Shift Name"
                            value={AssignedShiftUsers.shiftName}
                            valueColor={Theme.palette.primary.main} />
                    </Grid>
                    <Grid xs={6} md={2.5}>
                        <Label label="Start Time"
                            value={convertUTCToLocal(AssignedShiftUsers.startTime, "hh:mm A")}
                            valueColor={Theme.palette.primary.main} />
                    </Grid>
                    <Grid xs={6} md={2.5}>
                        <Label label="End Time"
                            value={convertUTCToLocal(AssignedShiftUsers.endTime, "hh:mm A")}
                            valueColor={Theme.palette.primary.main} />
                    </Grid>
                    <Grid xs={6} md={2.5}>
                        <Label label="Total Attenders"
                            value={AssignedShiftUsers.assignedUsers.length.toString()}
                            valueColor={Theme.palette.primary.main} />
                    </Grid>
                </Grid>
            </Card>
            <Box marginTop={3}>
                <DataTable rows={AssignedShiftUsers.assignedUsers} columnDefs={colDefs} />
            </Box>
            <SnackbarComponent
                setShowSnackBar={setShowSnackBar}
                showSnackBar={showSnackBar}
                snackBarMessage={snackBarMessage}
            />
            <ShiftAllocationUpdateForm
                open={openUpdateDialog}
                onClose={onUpdateDialogClose}
                userAssignFormik={userAssignFormik}
                selectedLocation={selectedLocation}
                selectedShift={selectedShift}
                setSelectedLocation={setSelectedLocation}
                setSelectedShift={setSelectedShift}
                shiftAssignmentInitialData={shiftAssignmentInitialData}
            />
            <Loader openModal={isLoading} />
            <DialogBox
                message={dialogMessage}
                handleAccept={() => onDelete()}
                handleCancel={handleDisagree}
                open={showDialog}
            />
        </Box>
    )
}

export default ShiftView
