import Theme from "../theme/theme";
export const iconButtonStyle = {
  backgroundColor: "#fff",
  width: "3.5rem",
  height: "3.5rem",
  borderRadius: "50%",
  // margin: "2.9rem 0",
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "center",
};
export const LayoutStyle = {
  backgroundColor: Theme.palette.primary.dark,
  borderRadius: "1rem",
  width: "2rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

///
export const InactiveButtonStyle = {
  backgroundColor: "#5d51cc",
  color: "#fff",
  width: "3.5rem",
  height: "3.5rem",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const SetBackgroundStyle = {
  width: "100%",
  marginTop: "10px",
  backgroundColor: Theme.palette.common.layout.commonBlue,
  borderRadius: "15px",
  padding: "15px",
  outline: "solid 2px #2e2586",
  // zIndex: 1,
  // opacity: "0.9",
};

export const LocationFieldsStyles = {
  backgroundColor: "white",
  color: "black",
  borderRadius: "15px",
};

export const LocationButtonStyles = {
  backgroundColor: Theme.palette.common.layout.patientButton,
  color: "black",
  "&:hover": {
    backgroundColor: Theme.palette.common.layout.buttonHoverBackground,
  },
};
