import { createSlice } from "@reduxjs/toolkit";
import { BlockAndFloor, Location, LocationData } from "../../Model/Location";
import {
  GetLocationById,
  GetLocationDetails,
  GetLocationList,
} from "../Thunks/LocationThunk";

interface InitialState {
  isLoading: boolean;
  locations: LocationData[];
  location: LocationData;
  locationDetails: BlockAndFloor | null;
}

let initialState: InitialState = {
  isLoading: false,
  locations: [{ locationId: 0 }],
  location: { locationId: 0 },
  locationDetails: null,
};

const LocationSlice = createSlice({
  name: "Location",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetLocationList.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(GetLocationList.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.status == true && action.payload.data.length > 0) {
          state.locations = action.payload.data;
        } else {
          state.locations = [{ locationId: 0 }];
        }
      })
      .addCase(GetLocationList.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(GetLocationDetails.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(GetLocationDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.status == true) {
          state.locationDetails = action.payload.data;
        } else {
          state.locationDetails = null;
        }
      })
      .addCase(GetLocationDetails.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(GetLocationById.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(GetLocationById.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.status == true) {
          state.location = action.payload.data;
        } else {
          state.location = { locationId: 0 };
        }
      })
      .addCase(GetLocationById.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const locationReducer = LocationSlice.reducer;
