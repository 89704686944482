import React, { useState, useEffect, ReactNode } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, SxProps } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Theme from '../../theme/theme';
import Title from '../Title/Title';

interface formAccordionPropsModel {
    children: ReactNode;
    title: string;
    style?: SxProps;
    backPage?:()=>void;
    isExpanded?:boolean;
}

const FormAccordion = ({ children, title, style, backPage, isExpanded }: formAccordionPropsModel) => {
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        if (isExpanded) {
            setExpanded(true);
        }
    }, [isExpanded]);

    const handleChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded);
    };
    return (
        <Accordion
            elevation={2}
            sx={{ ...style, marginTop: 1.5, pr: 1.5, backgroundColor: Theme.palette.common.layout.commonBlue, borderRadius: "10px !important" }}
            expanded={expanded}
            onChange={handleChange}
        >
            <AccordionSummary
                expandIcon={<ExpandMore color="secondary" />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{ color: Theme.palette.primary.dark }}
            >
                {backPage ? <ArrowBackIcon sx={{ margin: "6px 10px 0px 0px" }} color='secondary' onClick={backPage} /> : null}
                <Title
                    title={title}
                    sx={{ color: "white" }}
                />
            </AccordionSummary>
            <AccordionDetails sx={{ display: "flex" }}>
                {children}
            </AccordionDetails>
        </Accordion>
    );
};

export default FormAccordion;






