import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Player } from "@lottiefiles/react-lottie-player";
import LoginAnimation from "../../Assets/login_animation.json";
import pageAnimation from "../../Assets/side_animation.json";
import TextField from "@mui/material/TextField";
import store, { useAppDispatch, useAppSelector } from "../../Redux/Store";
import { UserLogin, UserLoginVerify, UserProfile } from "../../Redux/Thunks/LoginThunk";
import React, { useEffect, useState } from "react";
import { inputStyle } from "./LoginStyles";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import DisplayError from "../../Components/DisplayError";
import Snackbar from '@mui/material/Snackbar';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Formik, useFormik } from "formik";
import { LoginSchema, UserPermissions, UserVerifyParams } from "../../Model/Login";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import theme from "../../theme/theme";
import { unwrapResult } from "@reduxjs/toolkit";
import { Avatar, Dialog, DialogTitle, List, ListItem, ListItemAvatar, ListItemButton, ListItemText } from "@mui/material";
import { blue } from '@mui/material/colors';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import { GetStoreList } from "../../Redux/Thunks/StoreThunk";
import Loader from "../../Components/Loader/Loader";
import LoginSvg from '../../Assets/images/login_svg.svg'
import TextLabel from "../../Components/Label/TextLabel";
import Theme from "../../theme/theme";
import CustomButton from "../../Components/Button/Button";
import { Patientstyle } from "../Patient/PatientStyle";
import { log } from "console";
import SnackbarComponent from "../../Components/SnackBar/Snackbar";
interface LoginData {
    username: string;
    password: string;
    otp: string;
}

const initialValues = {
    username: "",
    password: "",
    otp: "123456",
};

const Login = () => {
    const [userPerissionList, setUserPermissionList] = useState<UserPermissions[]>([])
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [errorPass, setErrorPass] = useState<string>("");
    const [isLoading, setIsLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [userId, setUserId] = useState<string>("")
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const handleSnackClose = () => {
        setSnackbarOpen(false);
    };
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false)
    };
    const handleListItemClick = async (value: any) => {
        const userVerifyParams: UserVerifyParams = {
            userId: userId,
            storeId: value.storeId
        }
        let response = await dispatch(UserLoginVerify(userVerifyParams));
        let userVerifyRes = unwrapResult(response);
        if (userVerifyRes === undefined) {
            setSnackBarMessage("Something went wrong!");
            setShowSnackBar(true);
            return
        }
        if (userVerifyRes.status && userVerifyRes.data && userVerifyRes.status === 200) {
            Cookies.set("storeId", value.storeId);
            Cookies.set("userId", userId);
            navigate("/dashboard");
        } else {
            setSnackBarMessage(userVerifyRes.data);
            setShowSnackBar(true);
        }
    };

    const { values, handleSubmit, errors, setValues, handleBlur, touched, setErrors } =
        useFormik({
            initialValues: initialValues,
            validationSchema: LoginSchema,
            onSubmit: async () => {
                setIsLoading(true);
                try {
                    const loginPayload: LoginData = values;
                    let res = await dispatch(UserLogin(loginPayload));
                    let result = unwrapResult(res);
                    if (
                        result.status === 200 &&
                        result.data !== null &&
                        result.data.token.accessToken !== ""
                    ) {
                        let profileDetails = await dispatch(UserProfile());
                        let id:string=profileDetails.payload.data.id;
                        setUserId(id)
                        let permissionList = await profileDetails.payload.data.userPermissions.filter((data: UserPermissions) => data.roleName !== "Attender View Only");
                        setUserPermissionList(await permissionList);
                        if (profileDetails.payload && !profileDetails.payload.status) {
                            setSnackbarOpen(true);
                            setErrorPass("Can't get user details");
                            return;
                        }
                        if (permissionList.length <= 1) {
                            const storeId: any = await permissionList[0].storeId;
                            Cookies.set("storeId", storeId);
                            Cookies.set("userId", id);
                            navigate("/dashboard");
                        } else {
                            setOpen(true);
                        }
                        setSnackbarOpen(false);
                    }
                    else if (result.status === 400) {
                        if (result.data.startsWith("No user related")) {
                            setErrors({ username: "Incorrect!!!" });
                            setSnackbarOpen(true);
                            setErrorPass(result.data);

                        } else if (result.data === "Invalid credentials") {
                            setErrors({ password: result.data })
                            setSnackbarOpen(true);
                            setErrorPass(result.data);
                        } else if (result.data.startsWith("User")) {
                            setSnackbarOpen(true);
                            setErrorPass(result.data);
                        }
                    }
                } catch (e: any) {
                    setSnackbarOpen(true);
                    setErrorPass("Internal server error");
                    throw e;
                } finally {
                    setIsLoading(false);
                }
            }
        });

    const changeHandler = (e: React.ChangeEvent<any>) => {
        const { name, value } = e.target;
        setValues((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const action = (
        <>
            <IconButton size="small" color="inherit" onClick={handleSnackClose}>
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    );
    return (
        <>
            <Box
                component="form"
                onSubmit={handleSubmit}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: "100vh" }}
            >
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={4000}
                    onClose={handleSnackClose}
                    message={<DisplayError errorMsg={errorPass} />}
                    action={action}
                />
                <Grid container justifyContent="space-around" alignItems="center" sx={{ display: { md: 'flex' } }}>
                    <Grid lg={0.8} />
                    <Grid
                        item
                        xs={12}
                        sm={8}
                        md={5}
                        lg={5}
                        display={{ xs: "none", sm: "none", md: "flex", xl: "flex" }}
                        p={3}
                    >
                        <img
                            src={LoginSvg}
                            alt="Logo"
                            width="100%"
                            height="100%"
                        />
                    </Grid>
                    <Grid
                        item
                        xs={11}
                        sm={8}
                        md={5}
                        lg={4}
                        display="flex"
                        justifyContent="center"
                        flexDirection="column"
                        gap="2rem"
                        sx={{
                            backgroundColor: '#f3effb',
                            overflow: 'hidden',
                            margin: '10px 0px',
                            borderRadius: '10px',
                            padding: { xs: 3, sm: 5 },
                            border: '1px solid #d9c7fc'
                        }}
                    >
                        <Typography variant="h3" sx={{ fontWeight: 500, textAlign: 'center' }}>
                            Sign in to account
                        </Typography>

                        <Box>
                            <TextLabel label="UserName" color={Theme.palette.primary.light} />
                            <TextField
                                variant="outlined"
                                color="secondary"
                                sx={inputStyle}
                                onChange={changeHandler}
                                value={values.username}
                                onBlur={handleBlur}
                                fullWidth
                                name="username"
                                className={errors.username && touched.username ? 'shake' : ''}
                                helperText={
                                    errors.username &&
                                    touched.username && (
                                        <p style={{ color: theme.palette.error.main, margin: '0px' }}>
                                            {errors.username}
                                        </p>
                                    )
                                }
                            />
                        </Box>
                        <Box>
                            <TextLabel label="Password" color={Theme.palette.primary.light} />
                            <TextField
                                type="password"
                                variant="outlined"
                                color="secondary"
                                sx={inputStyle}
                                onChange={changeHandler}
                                value={values.password}
                                onBlur={handleBlur}
                                fullWidth
                                name="password"
                                className={errors.password && touched.password ? 'shake' : ''}
                                helperText={
                                    errors.password &&
                                    touched.password && (
                                        <p style={{ color: theme.palette.error.main, margin: '0px' }}>
                                            {errors.password}
                                        </p>
                                    )
                                }
                            />
                        </Box>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isLoading}
                            sx={{ padding: '10px 0px' }}
                        >
                            {isLoading == true ? (
                                <CircularProgress style={{ color: theme.palette.primary.contrastText }} />
                            ) : (
                                'Login'
                            )}
                        </Button>
                    </Grid>
                    <Grid lg={0.8} />
                </Grid>

                <Dialog open={open} onClose={handleClose} sx={{ padding: '20px 20' }}>
                    <DialogTitle variant="h3" sx={{ color: 'black' }}>Set backup account</DialogTitle>
                    <List sx={{ pt: 2 }}>
                        {userPerissionList.map((item: UserPermissions, index) => (
                            <ListItem
                                key={index}
                                disableGutters
                            // sx={{ backgroundColor: selectedItem === item.storeId ? '#f0f0f0' : 'inherit' }}
                            >
                                <ListItemButton onClick={() => handleListItemClick(item)}>
                                    <ListItemAvatar>
                                        <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                                            <ShareLocationIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText sx={{ color: 'black' }} primary={item.storeName} />
                                </ListItemButton>
                            </ListItem>
                        ))}

                    </List>
                </Dialog>
            </Box>
            <Loader openModal={isLoading} />
            <SnackbarComponent
                setShowSnackBar={setShowSnackBar}
                showSnackBar={showSnackBar}
                snackBarMessage={snackBarMessage}
            />
        </>
    );
};
export default Login;
