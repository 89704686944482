import { Box, Grid } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import TextBox from "../../Components/TextBox/TextBox";
import { AutoComplete as AutoCompleteType } from "../../Model/AutoCompleteModel";
import Title from "../../Components/Title/Title";
import AutoComplete from "../../Components/AutoComplete/AutoComplete";
import { useAppDispatch, useAppSelector } from "../../Redux/Store";
import { AddAssetModel, AssetTypeListModel } from "../../Model/AddAssetModel";
import {
  CreateAsset,
  GetAssetDetails,
  GetAssetTypeList,
  GetByAssetID,
  UpdateAsset,
} from "../../Redux/Thunks/AssetThunk";
import { GetLocationDetails, GetLocationList } from "../../Redux/Thunks/LocationThunk";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import SnackbarComponent from "../../Components/SnackBar/Snackbar";
import { Location, LocationData } from "../../Model/Location";
import { SetBackgroundStyle } from "../../layouts/SideNavbarStyle";
import Loader from "../../Components/Loader/Loader";
import Theme from "../../theme/theme";
import { assetStyles } from "./AssetStyles";
import CustomButton from "../../Components/Button/Button";
import BulkUploadButton from "../../Components/BulkUpload/BulkUploadButton";
import { Patientstyle } from "../Patient/PatientStyle";
import TextLabel from "../../Components/Label/TextLabel";
import { clearAsset } from "../../Redux/Slices/AssetSlice";
import FormAccordion from "../../Components/Accordion/FormAccordion";
import { getStoreId } from "../../Utilities/Store";
import LocationAutoComplete from "../../Components/AutoComplete/LocationAutoComplete";
// import { AutoComplete as AutoCompleteModel } from '../../../Model/AutoCompleteModel';
import { AutoComplete as AutoCompleteModel } from "../../Model/AutoCompleteModel";
export interface AddAssetprops {
  id: number;
  isUpdated: boolean;
  setId: Dispatch<SetStateAction<number>>;
}

function Asset(props: AddAssetprops) {
  var { id, setId } = props;


  const [isUpdate, setIsUpdate] = useState<number>(props.id);
  const [selectedAssetType, setSelectedAssetType] = useState<AutoCompleteType | null>(null);
  const [AssetTypeData, setAssetTypeData] = useState<any>([]);
  const [selectedLocationType, setSelectedLocationType] = useState<AutoCompleteType | null>(null);
  const [LocationTypeData, setLocationTypeData] = useState<any>([]);
  const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const { assetTypeList, asset, isLoading } = useAppSelector(
    (store) => store.asset
  );
  const { locations } = useAppSelector((store) => store.location);
  const dispatch = useAppDispatch();

  const initialValues = {
    assetName: "",
    AssetType: "",
    LocationType: "",
  };

  const formikAsset = useFormik({
    initialValues: initialValues,
    validationSchema: yup.object({
      AssetType: yup.string().required("Required"),
      LocationType: yup.string().required("Required"),
      assetName: yup.string().required("Required"),
    }),
    onSubmit: async (values, { resetForm }: any) => {
      if (
        asset &&
        asset.assetName == values.assetName &&
        asset.assetTypeId == Number(values.AssetType) &&
        asset.locationId == Number(values.LocationType)
      ) {
        setSnackBarMessage("Please change details to update");
        setShowSnackBar(true);
        return;
      }

      if (Number(values.LocationType) < 1) {
        setSnackBarMessage("Please provide all details");
        setShowSnackBar(true);
        return;
      }

      if (isUpdate < 1) {
        var details: AddAssetModel = {
          assetName: values.assetName,
          assetTypeName: "",
          locationId: Number(values.LocationType),
          storeId: await getStoreId(),
          assetTypeId: Number(values.AssetType),
          assetId: 0,
        };

        let res: any = await dispatch(CreateAsset(details));

        if (res.meta.requestStatus === "rejected") {
          setSnackBarMessage("Connection Refused");
          setShowSnackBar(true);
          return;
        }
        if (res.payload.status == true || res.payload.status == 200) {
          setSnackBarMessage("Asset added sucessfully!!");
          setShowSnackBar(true);
          await dispatch(GetAssetDetails());
          await dispatch(GetLocationList());
          setIsUpdate(0);
          setSelectedAssetType(null);
          setSelectedLocationType(null);
          resetForm();
        } else if (res.payload.status == false || res.payload.status == 400) {
          setSnackBarMessage(res.payload.message.data);
          setShowSnackBar(true);
        } else if (res.payload.status == false || res.payload.status == 500) {
          setSnackBarMessage(res.payload.data);
          setShowSnackBar(true);
        }
      } else {
        var details: AddAssetModel = {
          assetName: values.assetName,
          assetTypeName: "",
          locationId: Number(values.LocationType),
          storeId: await getStoreId(),
          assetTypeId: Number(values.AssetType),
          assetId: id,
        };
        let res: any = await dispatch(UpdateAsset(details));
        if (res.meta.requestStatus === "rejected") {
          setSnackBarMessage("Connection Refused");
          setIsUpdate(0);
          setShowSnackBar(true);
          return;
        }
        if (res.payload.status == true || res.payload.status == 200) {
          setSnackBarMessage("Asset updated sucessfully!!");
          setShowSnackBar(true);
          await GetDetails();
          await dispatch(GetAssetDetails());
          await dispatch(GetLocationList());
          setId(0);
          setIsUpdate(0);
          setSelectedLocationType(null);
          resetForm();
        } else if (res.payload.status == false || res.payload.status == 400) {
          setSnackBarMessage(res.payload.message);
          setShowSnackBar(true);
        } else if (res.payload.status == false || res.payload.status == 500) {
          setSnackBarMessage(res.payload.message);
          setShowSnackBar(true);
        }
      }
    },
  });
  var setAssetTypevalue = (id: number | string) => {
    formikAsset.setFieldValue("AssetType", id.toString());
    let currentSelectedAssetType =
      AssetTypeData.length > 0
        ? AssetTypeData.map((data: AutoCompleteType) => {
          if (id == data.id) {
            return data.label;
          }
        }).filter((data: any) => data != undefined)
        : [];
    setSelectedAssetType(currentSelectedAssetType);
  };

  const setLocationTypevalue = (id: number | string) => {
    formikAsset.setFieldValue("LocationType", id.toString());

    const currentSelectedLocationType = LocationTypeData.length > 0
      ? LocationTypeData.find((item: AutoCompleteModel) => item.id === id)
      : null;
    if (currentSelectedLocationType) {
      setSelectedLocationType(currentSelectedLocationType);
    } else {
      setSelectedLocationType(null);
    }
  };
  useEffect(() => {
    let newAssetTypeData;
    if (assetTypeList && assetTypeList?.length > 0) {
      newAssetTypeData = assetTypeList.map((data: AssetTypeListModel) => {
        return { label: data.assetTypeName, id: data.assetTypeId };
      }).filter(data => data.label !== undefined);
      setAssetTypeData(newAssetTypeData ?? null);
    }
  }, [assetTypeList?.length]);

  useEffect(() => {
    let newLocationTypeData;
    if (locations && locations?.length > 0) {
      newLocationTypeData = locations.map((data: LocationData) => {
        return { label: data.locationName, id: data.locationId };
      }).filter((data) => data.label != undefined);
      setLocationTypeData(newLocationTypeData.length > 0 ? newLocationTypeData : null);
    }
  }, [locations?.length || locations[0].locationId]);

  useEffect(() => {
    const GetAssetDetailById = async () => {
      if (id > 0) {
        await dispatch(GetByAssetID(id));
      }
    };
    GetAssetDetailById();
    setIsUpdate(props.id);
  }, [id]);
  const updateAssetDataSet = async () => {
    if (asset && isUpdate > 0) {
      formikAsset.setFieldValue("assetName", asset.assetName);
      formikAsset.setFieldValue("AssetType", asset.assetTypeId?.toString());
      formikAsset.setFieldValue("LocationType", asset.locationId?.toString());

      let currentSelectedAssetType = await AssetTypeData.map(
        (data: AutoCompleteType) => {
          if (asset.assetTypeId == data.id) {
            return data.label;
          }
        }
      ).filter((data: any) => data != undefined);

      setSelectedAssetType(currentSelectedAssetType);

      let currentSelectedLocationType = await (LocationTypeData.length > 0)
        ? LocationTypeData.find(
          (item: AutoCompleteModel) => item.id == asset.locationId?.toString()
        ) : null;

      setSelectedLocationType(await currentSelectedLocationType);
    }
  }
  useEffect(() => {
    updateAssetDataSet()
  }, [asset?.assetId]);

  const GetDetails = async () => {
    await dispatch(GetLocationDetails());
  };
  useEffect(() => {
    GetDetails();
  }, []);

  useEffect(() => {
    const GetAssetTypeDetails = async () => {
      await dispatch(GetAssetTypeList());
    };
    GetAssetTypeDetails();
  }, []);
  const setAssetTypeClearValue = () => {
    setSelectedAssetType(null);
    formikAsset.setFieldValue("AssetType", null);
  };
  const setLocationTypeClearValue = () => {
    setSelectedLocationType(null);
    formikAsset.setFieldValue("LocationType", null);
  };


  return (
    <>
      <FormAccordion isExpanded={isUpdate ? true : false} title={isUpdate ? "Update Asset" : "Add Asset"} style={{ ...assetStyles.containerStyles }}>
        <Grid container >
          <Grid container spacing={2} >
            <Grid item md={4} xs={5.5}>
              <TextLabel
                label="Asset Name "
                color={Theme.palette.primary.contrastText}
                required
              />
              <TextBox
                type="text"
                formik={formikAsset}
                error={
                  Boolean(formikAsset.touched.assetName) &&
                  Boolean(formikAsset.errors.assetName)
                }
                fieldpropsName="assetName"
                helpertext={
                  formikAsset.touched.assetName
                    ? String(formikAsset.errors.assetName)
                    : ""
                }
                sx={Patientstyle.textboxStyles}
                label="AssetName" />
            </Grid>
            <Grid item md={3.9} xs={5.5}>
              <TextLabel
                label="Asset Type"
                color={Theme.palette.primary.contrastText}
                required
              />

              <AutoComplete
                color={Theme.palette.primary.contrastText}
                value={selectedAssetType ?? null}
                data={AssetTypeData}
                title="AssetType"
                onClick={setAssetTypevalue}
                required={true}
                onClear={setAssetTypeClearValue}
                error={
                  formikAsset.touched.AssetType &&
                  Boolean(formikAsset.errors.AssetType)
                }
                helperText={
                  formikAsset.touched.AssetType && formikAsset.errors.AssetType
                }
              />
            </Grid>
            <Grid item md={3.9} xs={5.5}>
              <TextLabel
                label="Location"
                color={Theme.palette.primary.contrastText}
                required
              />
              <LocationAutoComplete
                value={selectedLocationType}
                data={locations ?? []}
                title='Location'
                onClick={setLocationTypevalue}
                onClear={setLocationTypeClearValue}
                required={true}
                error={
                  formikAsset.touched.LocationType &&
                  Boolean(formikAsset.errors.LocationType)
                }
                helperText={
                  formikAsset.touched.LocationType && formikAsset.errors.LocationType
                }
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end" spacing={2} marginTop={2}>
            <Grid item>
              <CustomButton
                onClick={() => formikAsset.handleSubmit()}
                variant="outlined"
                sx={assetStyles.buttonStyles.submitButtonStyles}
              >
                {isUpdate ? "Update Asset" : "Add Asset"}
              </CustomButton>
            </Grid>
            <Grid item>
              <CustomButton
                onClick={() => {
                  setIsUpdate(0);
                  setSelectedAssetType(null);
                  setSelectedLocationType(null);
                  formikAsset.resetForm();
                }}
                variant="outlined"
                sx={assetStyles.buttonStyles.cancelButtonStyles}
              >Clear</CustomButton>
            </Grid>
          </Grid>
        </Grid>


      </FormAccordion >

      <SnackbarComponent
        setShowSnackBar={setShowSnackBar}
        showSnackBar={showSnackBar}
        snackBarMessage={snackBarMessage}
      />
      <Loader openModal={isLoading} />
    </>
  );
}
export default Asset;
