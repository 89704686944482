import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  SxProps,
  Theme,
} from "@mui/material";
import { useState } from "react";
import { Patientstyle } from "../../pages/Patient/PatientStyle";

interface SelectBoxProps {
  formik: any;
  label: string;
  fieldpropsName: string;
  error: boolean;
  helpertext: string;
  value?: string;
  // value?: { value: string | number; label: string; id: number };
  options: { value: string | number; label: string; id: number }[];
  color?: string;

  placeHolder?: string;
  sx?: SxProps<Theme>;
}

function SelectBox(props: SelectBoxProps) {
  const {
    formik,
    label,
    fieldpropsName,
    error,
    helpertext,
    value,
    options,
    color,
    placeHolder = "",
    sx,
  } = props;

  const [showMenuItem, setShowMenuItem] = useState<boolean>(true);

  const handleSelectFocus = () => {
    setShowMenuItem(false);
  };

  return (
    <div>
      <FormControl fullWidth variant="outlined" error={error}>
        <Select
          displayEmpty
          value={value}
          onChange={(e) => {
            formik.setFieldValue(fieldpropsName, e.target.value);
          }}
          onFocus={handleSelectFocus}
          style={{ color: color ? color : "black" }}
          className={error && !value ? "shake" : ""}
          inputProps={{
            style: { color: color ? color : "black" },
          }}
          placeholder={placeHolder}
          // helperText={error ? helpertext : ''}

          sx={{
            "&.custom-border .MuiOutlinedInput-root": {
              borderColor: color ? color : "white",
            },
            "& .MuiInputBase-input": {
              color: color ? color : "",
              // padding: '11px !important',
              padding: "8.5px !important",
              fontSize: "small",
              "&::placeholder": {
                color: "black",
              },
            },

            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: color ? color : "",
              },
              "&:hover fieldset": {
                borderColor: color ? color : "",
              },
              "&.Mui-focused fieldset": {
                borderColor: color ? color : "",
              },
            },
            backgroundColor: color ? color : "",
            borderRadius: "3px",
            ...Patientstyle.selectBoxStyle,
          }}
        >
          {showMenuItem && (
            <MenuItem value="" disabled>
              <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>{label}</span>
            </MenuItem>
          )}

          {options.map((option) => (
            <MenuItem
              sx={{ color: "black" }}
              key={option.id}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{helpertext}</FormHelperText>
      </FormControl>
    </div>
  );
}

export default SelectBox;
