import { createAsyncThunk } from "@reduxjs/toolkit";
import { getHeaderToken } from "../../Utilities/header";
import axios from "axios";
import { DeleteFloorEntity } from "../../Model/FloorModel";
import { JobCreate, JobStatusUpdate } from "../../Model/JobModel";
import { getStoreId } from "../../Utilities/Store";
import { UpdateTaskParams } from "../../Model/CancelRequestModel";


export const GetJobInitialDetails = createAsyncThunk(
    "Job/getInitial",
    async () => {
        try {
            const URL = process.env.REACT_APP_BASEURL + `/api/Task/initial/${await getStoreId()}`;
            const response = await axios.get(URL, await getHeaderToken());
            return response.data;
        } catch (e: any) {
            return e.response;
        }
    }
);

//Get all job list
export const GetJobList = createAsyncThunk(
    "Job/getJobList",
    async () => {
        try {
            const URL = process.env.REACT_APP_BASEURL + `/api/Task/list/${await getStoreId()}`;
            const response = await axios.get(URL, await getHeaderToken());
            return response.data;
        } catch (e: any) {
            return e.response;
        }
    }
);
//Create job 
export const CreateJob = createAsyncThunk(
    "Job/create",
    async (data: JobCreate) => {
        try {
            const URL = process.env.REACT_APP_BASEURL + "/api/Task/create";
            const response = await axios.post(URL, data, await getHeaderToken());
            return response.data;
        } catch (e: any) {
            return e.response;
        }
    }
);

export const UpdateJobStatus = createAsyncThunk(
    "Job/updateStatus",
    async (data: UpdateTaskParams) => {
        try {
            const URL = process.env.REACT_APP_BASEURL + `/api/Task/updateStatus`;
            const response = await axios.put(URL, data, await getHeaderToken());
            return response.data;
        } catch (e: any) {
            return e.response;
        }
    }
);
