export const ScrollBarStyle =
{
    overflowY: 'auto',
    width: '100%',
    padding: { sm: 0, md: 0.5 },
    '&::-webkit-scrollbar': {
        width: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#888',
        borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#555',
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'thin',
    'scrollbar-color': '#888 transparent',
}
