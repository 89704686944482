import { Box, Button, Chip, Grid, Menu, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddJobs from './AddJobs';
import { useAppDispatch, useAppSelector } from '../../../Redux/Store';
import { GetJobInitialDetails, GetJobList, UpdateJobStatus } from '../../../Redux/Thunks/JobsThunk';
import DataTable from '../../../Components/DataTable/DataTable';
import { ColDef } from 'ag-grid-community';
import { TaskStatusNames } from '../../../Utilities/TaskStatuses';
import Theme from '../../../theme/theme';
import { JobStatusUpdate } from '../../../Model/JobModel';
import SnackbarComponent from '../../../Components/SnackBar/Snackbar';
import { UpdateTaskParams } from '../../../Model/CancelRequestModel';
import { GetCancelRequestList } from '../../../Redux/Thunks/CancelRequestedThunk';
import { GetAssetAttenderCount } from '../../../Redux/Thunks/DashboardThunk';
import { GetNotificationList } from '../../../Redux/Thunks/NotificationThunk';
import { getStoreId } from '../../../Utilities/Store';
import { convertUTCToLocal } from '../../../Utilities/Common';

const JobsList = () => {
    const dispatch = useAppDispatch();
    const { JobList, JobInitial } = useAppSelector((state) => state.job);
    const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
    const [snackBarMessage, setSnackBarMessage] = useState("");

    const AssetRenderer = (params: any) => {
        return (
            <Box>
                <Grid container gap="0.5rem">
                    <Grid item>
                        {params.data.assetName} - {params.data.assetTypeName}
                    </Grid>
                </Grid>
            </Box>
        );
    };
    const ActionsRenderer = (params: any) => {
        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
        const open = Boolean(anchorEl);
        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
            setAnchorEl(null);
        };
        const handleChangeStatus = async (id: number) => {
            let changeTask: UpdateTaskParams = {
                taskId: params.data.taskId,
                statusId: id,
                taskNumber: params.data.taskNumber,
                assigneeId: params.data.assigneeId,
                storeId: await getStoreId(),
            }
            let updateStatus = await dispatch(UpdateJobStatus(changeTask));
            if (updateStatus && updateStatus.meta.requestStatus === "rejected") {
                setSnackBarMessage("Connection Refused");
                setShowSnackBar(true);
                return;
            }
            if (updateStatus.payload && updateStatus.payload.status == true || updateStatus.payload.status == 200) {
                setSnackBarMessage("job status updated sucessfully!!");
                setShowSnackBar(true);
                await dispatch(GetJobList());
                await dispatch(GetJobInitialDetails());
                await dispatch(GetCancelRequestList());
                await dispatch(GetAssetAttenderCount());
            } else {
                setSnackBarMessage(updateStatus.payload.statusText);
                setShowSnackBar(true);
            }
        }
        
        return (
            <Box sx={{ position: 'relative' }}>
                {
                    (TaskStatusNames.Completed !== params.data.status) && (TaskStatusNames.Cancelled !== params.data.status) ?
                        <>
                            <Button
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                variant='text'
                                size='small'
                            >Change Status</Button>

                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}>{TaskStatusNames.taskStatus.filter((data) => (data.statusId !== TaskStatusNames.Scheduled) && (data.statusId !== TaskStatusNames.Rejected)).filter((data)=>data.statusId!==params.data.status).map((data) => (
                                    <MenuItem sx={{ color: Theme.palette.common.black }} key={data.statusId} onClick={() => handleChangeStatus(data.statusId)}>{data.statusName}</MenuItem>
                                ))}</Menu>
                        </> : <p
                            style={{
                                paddingLeft: '0.5px',
                                margin: 0,
                                color:TaskStatusNames.Completed == params.data.status
                                ? "green"
                                : "red",
                            }}
                        >
                           {params.data.taskStatusName}
                        </p>

                }
            </Box>
        );
    };
    const StatusRenderer = (param: any) => {
        return (
            <Box>
                <Grid container gap="0.5rem">
                    <Grid item>
                        {TaskStatusNames.getStatusName(Number(param.data.status))}
                    </Grid>
                </Grid>
            </Box>
        );
    }
    const PriorityRenderer = (params: any) => {
        const priorityValue = JobInitial?.taskPriority.find((data) => data.id === params.data.priorityId)?.label || "Low";
        return (
            <Box>
                <Grid container gap="0.5rem">
                    <Grid item>
                        {priorityValue}
                    </Grid>
                </Grid>
            </Box>
        );
    };
    const ScheduleRenderer = (param: any) => {
        return (
            <Box>
                <Grid container gap="0.5rem">
                    <Grid item>
                        {param.data.scheduleTime ? convertUTCToLocal(param.data.scheduleTime, "DD-MM-YYYY hh:mm A") : "No Schedule"}
                    </Grid>
                </Grid>
            </Box>
        );
    }
    const colDefs: ColDef[] = [
        { field: "Actions", headerName: "Actions", cellRenderer: ActionsRenderer, filter: "agSetColumnFilter", resizable: true, minWidth: 160 },
        { field: "taskNumber", headerName: "Task Number", flex: 1, filter: "agSetColumnFilter", resizable: true, minWidth: 150 },
        { headerName: "Status", flex: 1, cellRenderer: StatusRenderer, filter: "agSetColumnFilter", resizable: true, minWidth: 150 },
        { field: "sourceLocationName", headerName: "Source", flex: 1, cellStyle: { textAlign: 'center' }, filter: "agNumberColumnFilter", resizable: true, minWidth: 140 },
        { field: "destinationLocationName", headerName: "Destination", flex: 1, filter: "agNumberColumnFilter", resizable: true, minWidth: 160 },
        { field: "attenderName", headerName: "Attender Name", flex: 1, filter: "agSetColumnFilter", resizable: true, minWidth: 150 },
        { headerName: "Priority", cellRenderer: PriorityRenderer, flex: 1, filter: "agSetColumnFilter", resizable: true, minWidth: 150 },
        { field: "assetName", headerName: "Asset Name", cellRenderer: AssetRenderer, flex: 1, resizable: true, minWidth: 200 },
        { field: "patientName", headerName: "Patient Name", flex: 1, cellStyle: { textAlign: 'center' }, filter: "agNumberColumnFilter", resizable: true, minWidth: 150 },
        { field: "scheduleTime", cellRenderer: ScheduleRenderer, headerName: "Scheduled Time", flex: 1, cellStyle: { textAlign: 'center' }, filter: "agNumberColumnFilter", resizable: true, minWidth: 250 },
    ];

    return (
        <Box>
            <Grid container >
                <Grid item md={12}>
                    <DataTable rows={JobList ? JobList : []} columnDefs={colDefs} />
                </Grid>
            </Grid>
            <SnackbarComponent
                setShowSnackBar={setShowSnackBar}
                showSnackBar={showSnackBar}
                snackBarMessage={snackBarMessage}
            />
        </Box>
    );
};

export default JobsList;
