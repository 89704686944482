import { Grid, IconButton } from "@mui/material";
import TextLabel from "../../../Components/Label/TextLabel";
import Theme from "../../../theme/theme";
import { shiftAssignmentPropsModel, shiftAssignmentShiftInitial } from "../../../Model/ShiftModels";
import AutoComplete from "../../../Components/AutoComplete/AutoComplete";
import { AutoComplete as AutoCompleteModel } from "../../../Model/AutoCompleteModel";
import IconTextBox from "../../../Components/TextBox/IconTextBox";
import DescriptionIcon from "@mui/icons-material/Description";
import DateTimePickerTextBox from "../../../Components/TextBox/DateTimePickerTextBox";
import dayjs from "dayjs";
import { pv10 } from "../ShiftStyles";
import { LocationButtonStyles } from "../../../layouts/SideNavbarStyle";
import CustomButton from "../../../Components/Button/Button";
import { Patientstyle } from "../../Patient/PatientStyle";
import UserSelection from "./UserSelection";
import { replaceTime } from "../../../Utilities/Common";
import LocationAutoComplete from "../../../Components/AutoComplete/LocationAutoComplete";

const ShiftAllocationForm = ({
    shiftAssignmentFormik,
    shiftAssignmentInitialData,
    openDialog,
    setOpenDialog,
    selectedUsers,
    setSelectedUsers,
    selectedLocation,
    selectedShift,
    selectedUserNames,
    setSelectedLocation,
    setSelectedShift,
    setSelectedUserNames,
    getShiftAssignmentInitial
}: shiftAssignmentPropsModel) => {
    const onSelectShift = async (id: number | string) => {
        let selectedShift =
            shiftAssignmentInitialData.shifts.find(
                (item: shiftAssignmentShiftInitial) => item.id === id
            ) ?? null;
        setSelectedShift(selectedShift);
        if (selectedShift !== null) {
            let startDate = replaceTime(shiftAssignmentFormik.values.fromDate, selectedShift.startTime);
            let endDate = replaceTime(shiftAssignmentFormik.values.toDate, selectedShift.endTime);
            shiftAssignmentFormik.setFieldValue("fromDate", startDate);
            shiftAssignmentFormik.setFieldValue("toDate", endDate);
            await getShiftAssignmentInitial(startDate, endDate);
        }
        shiftAssignmentFormik.setFieldValue("shiftId", id);
    };

    const onSelectLocation = (id: number | string) => {
        let selectedLocation =
            shiftAssignmentInitialData.locations.map((location) => {
                return {
                    id: location.locationId,
                    label: location.locationName
                }
            }).find(
                (item: AutoCompleteModel) => item.id === id
            ) ?? null;
        setSelectedLocation(selectedLocation);
        shiftAssignmentFormik.setFieldValue("locationId", id);
    };

    const onShiftClear = () => {
        setSelectedShift(null);
    };

    const onLocationClear = () => {
        setSelectedLocation(null);
    };

    const onFromDateChange = async (date: Date) => {
        shiftAssignmentFormik.setFieldValue("fromDate", date);

        const toDate = shiftAssignmentFormik.values.toDate;
        if (date > toDate) {
            shiftAssignmentFormik.setFieldValue("toDate", date);
        }

        await getShiftAssignmentInitial(date, null);
    };

    const onToDateChange = async (date: Date) => {
        shiftAssignmentFormik.setFieldValue("toDate", date);
        await getShiftAssignmentInitial(null, date);
    };

    const handleClear = async () => {
        shiftAssignmentFormik.resetForm();
        setSelectedShift(null);
        setSelectedLocation(null);
        setSelectedUsers([]);
        setSelectedUserNames([]);
        await getShiftAssignmentInitial();
    };

    const handleCloseUserSelection = () => {
        setOpenDialog(false);
        setSelectedUsers([]);
        setSelectedUserNames([]);
    };

    const handleAddUserSelection = () => {
        setOpenDialog(false);
    };

    return (
        <Grid item xs={12}>
            <Grid spacing={5} container>
                <Grid item xs={6} md={4}>
                    <TextLabel label='Shifts' color={Theme.palette.common.white} required />
                    <AutoComplete
                        value={selectedShift}
                        data={shiftAssignmentInitialData.shifts}
                        title='Shift'
                        onClick={onSelectShift}
                        onClear={onShiftClear}
                        error={
                            shiftAssignmentFormik.touched.shiftId &&
                            Boolean(shiftAssignmentFormik.errors.shiftId)
                        }
                        helperText={
                            shiftAssignmentFormik.touched.shiftId
                                ? shiftAssignmentFormik.errors.shiftId
                                : ""
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextLabel label='From Date' color={Theme.palette.common.white} required />
                    <DateTimePickerTextBox
                        placeholder='From date'
                        isDatePicker={true}
                        inputValue={dayjs(shiftAssignmentFormik.values.fromDate).format(
                            "MMMM D, YYYY"
                        )}
                        dateValue={shiftAssignmentFormik.values.fromDate}
                        handleChange={onFromDateChange}
                        minimumDate={new Date()}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextLabel label='To Date' color={Theme.palette.common.white} required />
                    <DateTimePickerTextBox
                        placeholder='To date'
                        isDatePicker={true}
                        inputValue={dayjs(shiftAssignmentFormik.values.toDate).format(
                            "MMMM D, YYYY"
                        )}
                        dateValue={shiftAssignmentFormik.values.toDate}
                        handleChange={onToDateChange}
                        minimumDate={shiftAssignmentFormik.values.fromDate}
                    />
                </Grid>
            </Grid>
            <Grid spacing={5} container sx={pv10}>
                <Grid item xs={6} md={4} onClick={() => setOpenDialog(true)}>
                    <TextLabel label='Attender Name(s)' color={Theme.palette.common.white} required />
                    <IconTextBox
                        placeholder='Attender Name(s)'
                        value={selectedUserNames.join(", ")}
                        disabled={true}
                    >
                        <IconButton edge='end' onClick={() => setOpenDialog(true)}>
                            <DescriptionIcon />
                        </IconButton>
                    </IconTextBox>
                </Grid>
                <Grid item xs={6} md={4}>
                    <TextLabel label='Locations' color={Theme.palette.common.white} required />
                    <LocationAutoComplete
                        value={selectedLocation}
                        data={shiftAssignmentInitialData.locations}
                        title='Location'
                        onClick={onSelectLocation}
                        onClear={onLocationClear}
                        error={
                            shiftAssignmentFormik.touched.locationId &&
                            Boolean(shiftAssignmentFormik.errors.locationId)
                        }
                        helperText={
                            shiftAssignmentFormik.touched.locationId
                                ? shiftAssignmentFormik.errors.locationId
                                : ""
                        }
                        required={true}
                    />
                    {/* <AutoComplete
                        value={selectedLocation}
                        data={shiftAssignmentInitialData.locations}
                        title='Location'
                        onClick={onSelectLocation}
                        onClear={onLocationClear}
                        error={
                            shiftAssignmentFormik.touched.locationId &&
                            Boolean(shiftAssignmentFormik.errors.locationId)
                        }
                        helperText={
                            shiftAssignmentFormik.touched.locationId
                                ? shiftAssignmentFormik.errors.locationId
                                : ""
                        }
                    /> */}
                </Grid>
            </Grid>
            <Grid spacing={5} container>
                <Grid item md={8}></Grid>
                <Grid item xs={6} md={2}>
                    <CustomButton
                        onClick={handleClear}
                        variant='outlined'
                        sx={Patientstyle.clearButton}
                    >
                        {"Clear"}
                    </CustomButton>
                </Grid>
                <Grid item xs={6} md={2}>
                    <CustomButton
                        onClick={() => shiftAssignmentFormik.handleSubmit()}
                        variant='outlined'
                        sx={{ ...LocationButtonStyles, marginBottom: "10px" }}
                    >
                        {"Assign Now"}
                    </CustomButton>
                </Grid>
            </Grid>
            <UserSelection
                open={openDialog}
                onClose={handleCloseUserSelection}
                rowData={shiftAssignmentInitialData.users}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                onAdd={handleAddUserSelection}
                setSelectedUserNames={setSelectedUserNames}
            />
        </Grid>
    );
};

export default ShiftAllocationForm;
