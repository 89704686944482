import { SxProps, TextField, Theme as MuiTheme } from "@mui/material";
import React from "react";
import Theme from "../../theme/theme";

interface TextBoxProps {
  formik: any;
  label: string;
  fieldpropsName: string;
  error: boolean | undefined;
  helpertext: string;
  value?: string;
  type?: "text" | "password" | "number" | "email";
  upperCase?: boolean;
  color?: string;
  sx?: SxProps<MuiTheme>;
  disable?: boolean;
}

function TextBox(props: TextBoxProps) {
  const {
    formik,
    label,
    fieldpropsName,
    error,
    helpertext,
    type,
    value,
    upperCase,
    color,
    sx,
    disable
  } = props;

  return (
    <div>
      <TextField
        autoComplete="new-password"
        disabled={disable}
        name={fieldpropsName}
        value={value}
        placeholder={label}
        variant="outlined"
        type={type}
        fullWidth
        InputLabelProps={{
          style: { color: color ? color : "black" },
        }}
        inputProps={{
          style: { color: "black" },
          classes: {
            notchedOutline: {
              borderColor: color ? color : "black",
            },
            input: {
              "&::placeholder": {
                color: "rgba(0, 0, 0, 0.92)",
              },
            },
          },
        }}
        InputProps={{
          style: {
            color: color ? color : "black",
          },
          classes: {
            notchedOutline: {
              borderColor: color ? color : "black",
            },
            input: {
              "&::placeholder": {
                color: "rgba(0, 0, 0, 0.92)",
              },
            },
          },
        }}
        error={error}
        FormHelperTextProps={{
          sx: { backgroundColor: "transparent" },
        }}
        helperText={error ? helpertext : ""}
        className={error && !value ? 'shake' : ''}
        {...formik.getFieldProps(fieldpropsName)}
        onChange={(e) => {
          if (upperCase) {
            const uppercaseValue = e.target.value.toUpperCase();
            formik.setFieldValue(fieldpropsName, uppercaseValue);
          } else {
            formik.setFieldValue(fieldpropsName, e.target.value);
          }
        }}
        sx={{
          "&  .MuiFormHelperText-root.Mui-error": {
            backgroundColor: "transparent",
            margin: 0,
          },
          "&.custom-border .MuiOutlinedInput-root": {
            borderColor: color ? color : "white",
          },
          "& .MuiInputBase-input": {
            color: color ? color : "",
            padding: "11px !important",
            fontSize: "small",
            "&::placeholder": {
              color: "black",
            },
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: color ? color : "",
            },
            "&.Mui-focused fieldset": {
              borderColor: color ? color : "",
            },
          },
          backgroundColor: color ? color : "",
          borderRadius: "15px",
          ...sx,
        }}
      />
    </div>
  );
}

export default TextBox;
