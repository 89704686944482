import { combineReducers } from "@reduxjs/toolkit";
import { loginReducer } from "./Slices/LoginSlice";
import { UserProfile } from "./Thunks/LoginThunk";
import { userReducer } from "./Slices/User";
import { locationReducer } from "./Slices/LocationSlice";
import { alertReducer } from "./Slices/AlertSlice";
import { blockReducer } from "./Slices/BLockSlicce";
import { floorReducer } from "./Slices/FloorSclice";
import { assetReducer } from "./Slices/AssetSlice";
import { jobReducer } from "./Slices/JobsSlice";
import { ListUserReducer } from "./Slices/ListUserSlice";
import { PatientReducer } from "./Slices/PatientSlice";
import { StoreReducer } from "./Slices/StoreSlice";
import { notificationReducer } from "./Slices/NotificationSlice";
import { cancelRequestReducer } from "./Slices/CancelRequestedSlice";
import { dashboardReducer } from "./Slices/DashboardSlice";
import { signalRReducer } from "./Slices/SignalRSlice";
import { shiftReducer } from "./Slices/ShiftSlice";
import { reportReducer } from "./Slices/ReportSlice";

export default combineReducers({
    login: loginReducer,
    user: userReducer,
    location: locationReducer,
    alert: alertReducer,
    block: blockReducer,
    floor: floorReducer,
    asset: assetReducer,
    job: jobReducer,
    listUser: ListUserReducer,
    patient: PatientReducer,
    store: StoreReducer,
    notification: notificationReducer,
    cancelRequestTask: cancelRequestReducer,
    dashboard: dashboardReducer,
    signalR: signalRReducer,
    shift: shiftReducer,
    report: reportReducer,
})
