import React, { useState, useRef, useMemo, useEffect } from 'react';
import { Container, Grid, Typography, Button, Box, Tooltip, IconButton } from '@mui/material';
import { Player } from '@lottiefiles/react-lottie-player';

import { useFormik } from 'formik';
import * as yup from 'yup';
import './Block.css'
import { ColDef } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import TextBox from '../../Components/TextBox/TextBox';
import DataTable from '../../Components/DataTable/DataTable';
import AddBlockButton from '../../Assets/Add_block_button.json';
import EditIcon from '@mui/icons-material/Edit';
import { useAppDispatch, useAppSelector } from '../../Redux/Store';
import { AddBlock, GetBlockById, GetBlockList, UpdateBlock, deleteBlock } from '../../Redux/Thunks/BlockThunk';
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from '@mui/icons-material/Delete';
import SnackbarComponent from '../../Components/SnackBar/Snackbar';
import Loader from '../../Components/Loader/Loader';
import { Patientstyle } from '../Patient/PatientStyle';
import Title from '../../Components/Title/Title';
import CustomButton from '../../Components/Button/Button';
import TextLabel from '../../Components/Label/TextLabel';
import Theme from '../../theme/theme';
import { floorButtonStyles, floorContainerStyles, floorTextBoxStyles } from '../Floor/FloorStyles';
import DialogBox from '../../Components/DialogBox/DialogBox';
import { IBlockEntityById } from '../../Model/BlockModel';
import _ from 'lodash';
import FormAccordion from '../../Components/Accordion/FormAccordion';
import { assetStyles } from '../Asset/AssetStyles';
import { useNavigate } from 'react-router-dom';
import { getStoreId } from '../../Utilities/Store';
const Block: React.FC = () => {
  const [rowData, setRowData] = useState([]);
  const [selectedblockId, setSelectedblockId] = useState(0);
  const dispatch = useAppDispatch();
  const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const { Blocks, Block, isLoading } = useAppSelector((state) => state.block);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [deleteBlockId, setDeleteBlockId] = useState(0);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [blockDataById, setBlockDataById] = useState<any[]>([])
  const navigate = useNavigate()
  useEffect(() => {
    dispatch(GetBlockList());
  }, []);

  useEffect(() => {
    if (selectedblockId > 0) {
      formikBlock.setFieldValue('Block', Block.blockName)
    }
  }, [Block]);

  useEffect(() => {
    if (Blocks && Blocks.length > 0) {
      let data = Blocks.map((tabData: any, index: number) => {
        return {
          index: index + 1,
          blockName: tabData.blockName,
          company: tabData.companyName,
          blockId: tabData.blockId,
          storeName: tabData.storeName
        }
      });
      setRowData(data);
    }
  }, [Blocks]);

  const formikBlock = useFormik({
    initialValues: {
      Block: '',
    },
    validationSchema: yup.object({
      Block: yup.string().required('Required Block Name'),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (selectedblockId < 1) {
        let param = {
          blockName: values.Block,
          storeId: await getStoreId(),
        }
        let res = await dispatch(AddBlock(param));
        if (res && res.meta.requestStatus === "rejected") {
          setSnackBarMessage("Connection Refused");
          setShowSnackBar(true);
          return;
        }
        if (res && res.payload && res.payload.status == true || res.payload.status == 200) {
          setSnackBarMessage("Block added sucessfully!!");
          setShowSnackBar(true);
          await dispatch(GetBlockList());
          resetForm();
        } else {
          setSnackBarMessage(res.payload.data);
          setShowSnackBar(true);
        }
      }
      else {
        let param = {
          blockName: values.Block,
          storeId: await getStoreId(),
          blockId: Block.blockId
        }
        const areBlocksEqual = _.isEqual(param, blockDataById);
        if (areBlocksEqual) {
          setSnackBarMessage("Not update needed!");
          setShowSnackBar(true);
        } else {
          let res = await dispatch(UpdateBlock(param));
          if (res && res.meta.requestStatus === "rejected") {
            setSnackBarMessage("Connection Refused");
            setShowSnackBar(true);
            return;
          }
          if (res && res.payload && res.payload.status == true || res.payload.status == 200) {
            setSnackBarMessage("Block updated sucessfully!!");
            setShowSnackBar(true);
            await dispatch(GetBlockList());
            resetForm();
            setSelectedblockId(0);
            setIsUpdate(false);
          } else {
            setSnackBarMessage(res.payload.data);
            setShowSnackBar(true);
          }
        }
      }

    },
  });

  const onEdit = async (data: any) => {
    setSelectedblockId(data.blockId);
    let res = await dispatch(GetBlockById(data.blockId));
    const blockData: IBlockEntityById = res.payload.data;

    let param: any = {
      blockName: blockData.blockName,
      storeId: blockData.storeId,
      blockId: blockData.blockId
    }

    setBlockDataById(param)
    setIsUpdate(true);
  };

  const onDelete = async () => {
    let res = await dispatch(deleteBlock(deleteBlockId));
    if (res && res.meta.requestStatus === "rejected") {
      setSnackBarMessage("Connection Refused");
      setShowSnackBar(true);
      return;
    }
    if (res && res.payload && res.payload.status == true || res.payload.status == 200) {
      setSnackBarMessage("Block deleted sucessfully|!");
      setShowSnackBar(true);
      await dispatch(GetBlockList());
      setSelectedblockId(0);
      setIsUpdate(false);
      dispatch(GetBlockList());
      setShowDialog(false);

    } else {
      setSnackBarMessage(res.payload.data);
      setShowSnackBar(true);
    }
  };

  const ActionRenderer = (props: any) => {
    return (
      <Box aria-label="Actions">
        <Tooltip title="Edit" placement="bottom-start">
          <IconButton onClick={() => onEdit(props.data)}>
            <EditOutlinedIcon color="primary" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete" placement="bottom-start">
          <IconButton onClick={() => confirmdelete(props.data.blockId)}>
            <DeleteIcon color="primary" />
          </IconButton>
        </Tooltip>
      </Box>
    );
  };
  const confirmdelete = (id: number) => {
    setDeleteBlockId(id);
    setShowDialog(true);
    setDialogMessage("Are you sure want to delete block?")
  }
  const handleDisagree = () => {
    setShowDialog(false);
  }
  const colDefs: ColDef[] = [
    { field: 'index' },
    { field: 'blockName', headerName: "Blocks", },
    { field: 'storeName', headerName: "Store" },
    { field: "actions", headerName: "Actions", cellRenderer: ActionRenderer }
  ];
  const handleNavigate = () => {
    navigate('/locations')
  }
  return (
    <Box mr={2} pt={1}>
      <FormAccordion isExpanded={isUpdate} title={isUpdate == true ? "Update Block" : "Add Block"} backPage={handleNavigate} style={{ ...assetStyles.containerStyles }}>
        <Grid container >
          <Grid container spacing={2} >
            <Grid item xs={12}>
              <form onSubmit={formikBlock.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={4}>
                    <TextLabel label="Block" color={Theme.palette.common.white} required/>
                    <TextBox
                      type='text'
                      formik={formikBlock}
                      error={Boolean(formikBlock.touched.Block) && Boolean(formikBlock.errors.Block)}
                      fieldpropsName='Block'
                      helpertext={formikBlock.touched.Block ? String(formikBlock.errors.Block) : ""}
                      label='Block'
                      sx={Patientstyle.textboxStyles}
                    />
                  </Grid>
                  <Grid item mt={4} display={'flex'}>
                    <CustomButton
                      onClick={() => formikBlock.handleSubmit()}
                      sx={{ ...floorButtonStyles, marginRight: 1 }}
                    >
                      {isUpdate == true ? (
                        "Update Block"
                      ) : (
                        "Add Block"
                      )}
                    </CustomButton>
                    <CustomButton
                      variant="contained"
                      onClick={() => {
                        formikBlock.resetForm();
                        setIsUpdate(false);
                      }}
                      sx={Patientstyle.clearButton}
                      text="Clear"
                      formik={formikBlock}
                      isLoading={isLoading}
                    >Clear</CustomButton>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </FormAccordion>
      <Grid item xs={12}>
        <Title title="Block List" sx={{ color: Theme.palette.primary.main }} />
      </Grid>
      <Grid item xs={12}>
        <DataTable rows={rowData} columnDefs={colDefs} />
      </Grid>
      <DialogBox
        message={dialogMessage}
        handleAccept={() => onDelete()}
        handleCancel={handleDisagree}
        open={showDialog}
      />
      <SnackbarComponent
        setShowSnackBar={setShowSnackBar}
        showSnackBar={showSnackBar}
        snackBarMessage={snackBarMessage}
      />
      <Loader openModal={isLoading} />
    </Box>
  );
};

export default Block;
