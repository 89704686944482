export interface BlockEntity {
    blockId: number;
    blockName: string;
    createdBy: string;
    createdOn: Date;
    updatedBy: string;
    updatedOn: Date;
}

export interface IBlockEntityById {
    blockId: number;
    blockName: string;
    companyId: number;
    companyName: string;
    createdBy: string;
    createdOn: string;
    isDeleted: number;
    storeId: number;
    storeName: string | null;
    updatedBy: string | null;
    updatedOn: string;
}
interface InitialState {
    isLoading: boolean;
    Blocks: any;
    Block: any
}

export let blockInitialState: InitialState = {
    isLoading: false,
    Blocks: [],
    Block: null
};
