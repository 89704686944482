import React from 'react'
import { IconButton, InputAdornment, OutlinedInput, SxProps, TextField, Theme as MuiTheme, Grid } from '@mui/material';
import Theme from '../../theme/theme';


interface iconTextBoxPropsModel {
    color?: string
    sx?: SxProps<MuiTheme>;
    placeholder: string;
    children?: React.ReactNode;
    disabled?: boolean;
    handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    value?: string;
}

const IconTextBox = ({ placeholder, color, sx, children, disabled = false, value = "", handleChange = () => { } }: iconTextBoxPropsModel) => {
    return (
        <Grid>
            <OutlinedInput
                readOnly={disabled}
                fullWidth
                value={value}
                onChange={handleChange}
                inputProps={{
                    style: { color: "black", cursor: disabled ? "pointer" : "text" },
                    classes: {
                        notchedOutline: {
                            borderColor: color ? color : "black",
                        },
                        input: {
                            "&::placeholder": {
                                color: "rgba(0, 0, 0, 0.92)",
                            },
                        },
                    },
                }}
                endAdornment={
                    <InputAdornment position="end">
                        {children}
                    </InputAdornment>
                }
                placeholder={placeholder}
                sx={{
                    "&  .MuiFormHelperText-root.Mui-error": {
                        backgroundColor: "transparent",
                        margin: 0,
                    },
                    "&.custom-border .MuiOutlinedInput-root": {
                        borderColor: color ? color : "white",
                    },
                    "& .MuiInputBase-input": {
                        color: color ? color : "",
                        padding: "11px !important",
                        fontSize: "small",
                        "&::placeholder": {
                            color: "black",
                        },
                    },
                    "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                            borderColor: color ? color : "",
                        },
                        "&.Mui-focused fieldset": {
                            borderColor: color ? color : "",
                        },
                    },
                    backgroundColor: color ? color : Theme.palette.common.white,
                    borderRadius: "10px",
                    ...sx,
                }}
            />
        </Grid>
    )
}

export default IconTextBox