import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { getHeaderToken } from "../../Utilities/header";
import { CreateUser, UserListValue, SingleUser } from '../../Model/AddUserModels'

export const getUserFromServer = createAsyncThunk(
    "User/getUser",
    async () => {
        try {
            const URL = process.env.REACT_APP_BASEURL + "/api/User/initial";
            const headers = { headers: { "Client-Type": process.env.REACT_APP_CLIENT_TYPE, ...(await getHeaderToken()).headers } }
            const response = await axios.get(URL, headers);
            return response.data;
        } catch (e: any) {
            return e.response;
        }
    }
);


export const createUserInServer = createAsyncThunk(
    "User/getUser",
    async (user: CreateUser, { rejectWithValue }) => {
        try {
            const CreateUserURL = `${process.env.REACT_APP_BASEURL}/api/User/create`;
            const response = await axios.post(CreateUserURL, user, await getHeaderToken());
            return response.data;
        } catch (e: any) {
            if (e.response && e.response.status === 409) {
                return rejectWithValue("User credentials already exist. Please try again with different credentials.");
            }
            console.error(e);
            return rejectWithValue(e.response ? e.response.data : e.message);
        }
    }
);

export const getSingleUserByIdFromServer = createAsyncThunk(
    "sigleUser/getUser",
    async (singleUser: string, { rejectWithValue }) => {
        try {
            const ListUserURL = `${process.env.REACT_APP_BASEURL}/api/User/profile/${singleUser}`;
            const response = await axios.get(ListUserURL, await getHeaderToken());
            return response.data;

        } catch (e: any) {
            if (e.response && e.response.status === 409) {
                return rejectWithValue("List user credentials already exist. Please try again with different credentials.");
            }
            console.error(e);
            return rejectWithValue(e.response ? e.response.data : e.message);
        }
    }
);


export const getAllListUserFromServer = createAsyncThunk(
    "User/getUser",
    async (listUser: UserListValue, { rejectWithValue }) => {
        try {
            const ListUserURL = `${process.env.REACT_APP_BASEURL}/api/User/list`;
            const response = await axios.post(ListUserURL, listUser, await getHeaderToken());
            return response.data;

        } catch (e: any) {
            if (e.response && e.response.status === 409) {
                return rejectWithValue("List user credentials already exist. Please try again with different credentials.");
            }
            console.error(e);
            return rejectWithValue(e.response ? e.response.data : e.message);
        }
    }
);

export const updateUserInServer = createAsyncThunk(
    "User/updateUser",
    async (user: any, { rejectWithValue }) => {
        try {
            const UpdateUserURL = `${process.env.REACT_APP_BASEURL}/api/User/update`;
            const response = await axios.post(UpdateUserURL, user, await getHeaderToken());
            return response.data;
        } catch (e: any) {
            if (e.response && e.response.status === 409) {
                return rejectWithValue("User credentials already exist. Please try again with different credentials.");
            }
            console.error(e);
            return rejectWithValue(e.response ? e.response.data : e.message);
        }
    }
);