import { createAsyncThunk } from "@reduxjs/toolkit";
import { getHeaderToken } from "../../Utilities/header";
import axios from "axios";
import { getStoreId } from "../../Utilities/Store";

export const GetBlockList = createAsyncThunk(
    "Block/getList",
    async () => {
        try {
            const URL = process.env.REACT_APP_BASEURL + `/api/Block/${await getStoreId()}`;
            const response = await axios.get(URL, await getHeaderToken());
            return response.data;
        } catch (e: any) {
            return e.response;
        }
    }
);

export const GetBlockById = createAsyncThunk(
    "Block/getById",
    async (id: number) => {
        try {
            ;
            const URL = process.env.REACT_APP_BASEURL + `/api/Block/${id}/${await getStoreId()}`;
            const response = await axios.get(URL, await getHeaderToken());
            return response.data;
        } catch (e: any) {
            return e.response;
        }
    }
);

export const AddBlock = createAsyncThunk(
    "Block/AddBlock",
    async (data: any) => {
        try {
            ;
            const URL = process.env.REACT_APP_BASEURL + "/api/Block/add";
            const response = await axios.post(URL, data, await getHeaderToken());
            return response.data;
        } catch (e: any) {
            return e.response;
        }
    }
);

export const UpdateBlock = createAsyncThunk(
    "Block/UpdateBlock",
    async (data: any) => {
        try {
            ;
            const URL = process.env.REACT_APP_BASEURL + "/api/Block/update";
            const response = await axios.put(URL, data, await getHeaderToken());
            return response.data;
        } catch (e: any) {
            return e.response;
        }
    }
);

export const deleteBlock = createAsyncThunk(
    "Location/deleteBlock",
    async (id: number) => {
        try {
            ;
            const URL = process.env.REACT_APP_BASEURL + `/api/Block/delete/${id}/${await getStoreId()}`;
            const response = await axios.delete(URL, await getHeaderToken());
            return response.data;
        } catch (e: any) {
            return e.response;
        }
    }
);