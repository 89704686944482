import { LocationButtonStyles } from "../../layouts/SideNavbarStyle";
import { Grid } from "@mui/material";
import Theme from "../../theme/theme";
import TextLabel from "../../Components/Label/TextLabel";
import TextBox from "../../Components/TextBox/TextBox";
import { Patientstyle } from "../Patient/PatientStyle";
import { shiftPropsModel } from "../../Model/ShiftModels";
import CustomButton from "../../Components/Button/Button";
import { pv10 } from "./ShiftStyles";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Virtual } from "swiper/modules";
import ShiftIntervalCard from "./ShiftIntervalCard";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ShiftIntervalForm from "./ShiftIntervalForm";
import { nanoid } from "@reduxjs/toolkit";
import DateTimePickerTextBox from "../../Components/TextBox/DateTimePickerTextBox";
import dayjs from "dayjs";
import { useState } from "react";
import { AutoComplete } from "../../Model/AutoCompleteModel";

const ShiftForm = (props: shiftPropsModel) => {
    const {
        shiftFormik,
        shiftIntervalFormik,
        intervals,
        handleIntervalRemove,
        openDialog,
        setOpenDialog,
        handleClear,
        handleIntervalEdit,
        intervalTypes,
        setIntervalTypes,
    } = props;

    const [selectedIntervalType, setSelectedIntervalType] =
        useState<AutoComplete | null>(null);

    const onDialogClose = () => {
        setOpenDialog(false);
        setSelectedIntervalType(null);
        shiftIntervalFormik.resetForm();
    };

    const onAddInterval = () => {
        setOpenDialog(true);
    };

    const handleSave = () => {
        if (shiftIntervalFormik.values.Id === "") {
            shiftIntervalFormik.setFieldValue("Id", nanoid());
        }
        shiftIntervalFormik.handleSubmit();
    };

    const onStartTimeChange = (date: Date) => {
        shiftFormik.setFieldValue("startTime", date);
    };

    const onEndTimeChange = (date: Date) => {
        shiftFormik.setFieldValue("endTime", date);
    };

    return (
        <Grid item xs={12}>
            <Grid
                container
                spacing={1}
                display='flex'
                alignItems='flex-end'
                sx={pv10}
            >
                <Grid item xs={12} sm={6} md={3}>
                    <TextLabel label='Name' color={Theme.palette.common.white} required />
                    <TextBox
                        type='text'
                        formik={shiftFormik}
                        error={
                            Boolean(shiftFormik.touched.shiftName) &&
                            Boolean(shiftFormik.errors.shiftName)
                        }
                        fieldpropsName='shiftName'
                        helpertext={
                            shiftFormik.touched.shiftName
                                ? String(shiftFormik.errors.shiftName)
                                : ""
                        }
                        label='Name'
                        sx={Patientstyle.textboxStyles}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <TextLabel label='Start time' color={Theme.palette.common.white} required />
                    <DateTimePickerTextBox
                        placeholder='Start time'
                        inputValue={dayjs(shiftFormik.values.startTime).format("hh:mm A")}
                        dateValue={shiftFormik.values.startTime}
                        handleChange={onStartTimeChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <TextLabel label='End time' color={Theme.palette.common.white} required />
                    <DateTimePickerTextBox
                        placeholder='End time'
                        inputValue={dayjs(shiftFormik.values.endTime).format("hh:mm A")}
                        dateValue={shiftFormik.values.endTime}
                        handleChange={onEndTimeChange}
                    />
                </Grid>
                <Grid item md={0.9}></Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <CustomButton
                        onClick={() => onAddInterval()}
                        variant='outlined'
                        sx={LocationButtonStyles}
                    >
                        {"Add Interval"}
                    </CustomButton>
                </Grid>
            </Grid>
            <Grid container sx={pv10} display='flex'>
                <Grid
                    item
                    md={0.5}
                    display={intervals.length > 3 ? "flex" : "none"}
                    alignSelf='center'
                >
                    <div className='swiper-button-prev'>
                        <ArrowBackIosIcon fontSize='large' sx={{ color: "white" }} />
                    </div>
                </Grid>
                <Grid item xs={12} md={9}>
                    <Grid item xs={12}>
                        {intervals.length > 0 && (
                            <Swiper
                                slidesPerView={3}
                                spaceBetween={10}
                                modules={[Navigation, Virtual]}
                                navigation={{
                                    nextEl: ".swiper-button-next",
                                    prevEl: ".swiper-button-prev",
                                }}
                                grabCursor={true}
                                virtual
                                className='mySwiper'
                            >
                                {intervals.map((interval, index) => {
                                    return (
                                        <SwiperSlide>
                                            <ShiftIntervalCard
                                                handleIntervalRemove={handleIntervalRemove}
                                                interval={interval}
                                                handleIntervalEdit={handleIntervalEdit}
                                                shiftFormik={shiftFormik}
                                            />
                                        </SwiperSlide>
                                    );
                                })}
                            </Swiper>
                        )}
                    </Grid>
                </Grid>
                <Grid
                    item
                    md={0.5}
                    display={intervals.length > 3 ? "flex" : "none"}
                    alignSelf='center'
                >
                    <div className='swiper-button-next'>
                        <ArrowForwardIosIcon fontSize='large' sx={{ color: "white" }} />
                    </div>
                </Grid>
                {intervals.length <= 3 && <Grid item md={1}></Grid>}
                <Grid item xs={12} md={2} flexDirection={"column"} alignSelf='flex-end'>
                    <CustomButton
                        onClick={() => shiftFormik.handleSubmit()}
                        variant='outlined'
                        sx={{ ...LocationButtonStyles, marginBottom: "10px" }}
                    >
                        {shiftFormik.values.shiftId > 0 ? "Update Shift" : "Add Shift"}
                    </CustomButton>
                    <CustomButton
                        onClick={handleClear}
                        variant='outlined'
                        sx={Patientstyle.clearButton}
                    >
                        {"Clear"}
                    </CustomButton>
                </Grid>
                <ShiftIntervalForm
                    open={openDialog}
                    onClose={onDialogClose}
                    intervalTypes={intervalTypes}
                    onSave={handleSave}
                    shiftIntervalFormik={shiftIntervalFormik}
                    selectedIntervalType={selectedIntervalType}
                    setSelectedIntervalType={setSelectedIntervalType}
                />
            </Grid>
        </Grid>
    );
};

export default ShiftForm;
