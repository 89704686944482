import React from 'react';
import { Typography, Box, Grid } from '@mui/material';
export interface LabelProps {
    label: string;
    value: string;
    icon?: boolean;
    color?: string;
    valueColor?: string;
}
const Label: React.FC<LabelProps> = ({ label, value, icon, color, valueColor }) => {
    return (
        <Grid container display="flex" flexDirection="column" >
            <Typography style={{ marginBottom: 5, textAlign: "center" }} variant="h5" fontWeight="600" color={color ? color : 'textPrimary'}>
                {label}
            </Typography>
            <Typography style={{ marginBottom: 5, textAlign: "center" }} sx={{ fontSize: 18 }} fontWeight="600" color={valueColor ? valueColor : "textSecondary"}>
                {value}
            </Typography>
        </Grid>
    );
};

export default Label;