import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAllListUserFromServer } from "../Thunks/AddUsersThunk";

interface DataSourceItem {
  displayName: string;
  email: string | null;
  isActive: boolean;
  phoneNumber: string;
  roleName: string;
  storeName: string;
  userId: string;
}

interface InitialState {
  isLoading: boolean;
  message: string;
  listData: DataSourceItem[];
}

const initialState: InitialState = {
  isLoading: false,
  message: '',
  listData: []
};

const ListUserSlice = createSlice({
  name: "ListUser",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllListUserFromServer.fulfilled, (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        state.listData = action.payload.data.dataSource;
        state.message = action.payload.message;
      } else {
        state.listData = [];
        state.message = action.payload.message;
      }
    });
    builder.addCase(getAllListUserFromServer.pending, (state) => {
      state.isLoading = true;
      state.message = "The User List is Pending...";
    });
    builder.addCase(getAllListUserFromServer.rejected, (state, action) => {
      state.isLoading = false;
      state.message = "The User List was not found.";
    });
  }
});

export const ListUserReducer = ListUserSlice.reducer;
