import { getStoreId } from "./Store";

export const userListValue = async () => {
    return {
        "sortColumn": "",
        "sortOrder": "",
        "pageIndex": 0,
        "pageSize": 1000,
        "userParams": "",
        "search": [
            {
                "searchValue": "",
                "searchColumn": ""
            }
        ],
        "storeIds": [
            await getStoreId()
        ]
    };
}
