import { createSlice } from "@reduxjs/toolkit";
import { GetFloorById, GetFloorList } from "../Thunks/FloorThunk";
import { InitialState } from "../../Model/JobModel";
import { GetJobInitialDetails, GetJobList } from "../Thunks/JobsThunk";

let initialState: InitialState = {
    isLoading: false,
    JobInitial: null,
    JobList: null,
};

const JobSlice = createSlice({
    name: "job",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetJobInitialDetails.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(GetJobInitialDetails.fulfilled, (state, action) => {
                state.isLoading = false;
                if (action.payload.status == true) {
                    state.JobInitial = action.payload.data
                }
                else {
                    state.JobInitial = null;
                };
            })
            .addCase(GetJobInitialDetails.rejected, (state, action) => {
                state.isLoading = false;
            })
            .addCase(GetJobList.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(GetJobList.fulfilled, (state, action) => {

                state.isLoading = false;
                if (action.payload.status == true) {
                    state.JobList = action.payload.data
                }
            })
            .addCase(GetJobList.rejected, (state, action) => {
                state.isLoading = false;
            })
    }
})

export const jobReducer = JobSlice.reducer;