import DataTable from '../../Components/DataTable/DataTable';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import { shiftListPropModel } from '../../Model/ShiftModels';
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useAppDispatch } from '../../Redux/Store';

const ShiftList = ({ shifts, onEdit, onDelete, onView }: shiftListPropModel) => {
    const dispatch = useAppDispatch();
    const ActionRenderer = (props: any) => {
        return (
            <div>
                <Box aria-label="Actions">
                    <Tooltip title="Edit" placement="bottom-start">
                        <IconButton onClick={() => onEdit(props.data)}>
                            <EditOutlinedIcon color="primary" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete" placement="bottom-start">
                        <IconButton onClick={() => onDelete(props.data)}>
                            <DeleteIcon color="primary" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="View" placement="bottom-start">
                        <IconButton onClick={() => onView(props.data)}>
                            <RemoveRedEyeIcon color="primary" />
                        </IconButton>
                    </Tooltip>
                </Box>
            </div>
        );
    };

    const columnDefs = [
        { field: "no", headerName: "No", maxWidth: 75, valueGetter: 'node.rowIndex + 1' },
        { field: "actions", headerName: "Actions", cellRenderer: ActionRenderer },
        { headerName: "Shift Name", field: "shiftName", sortable: true, filter: true },
        { headerName: "Start Time", field: "startTime", sortable: true, filter: true },
        { headerName: "End Time", field: "endTime", sortable: true, filter: true },
        { headerName: "Duration", field: "duration", sortable: true, filter: true }
    ];

    return (
        <Grid container justifyContent="center">
            <DataTable rows={shifts} columnDefs={columnDefs} />
        </Grid>
    )
}

export default ShiftList;