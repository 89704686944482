import React from 'react'
import Title from '../../Components/Title/Title'
import TextLabel from '../../Components/Label/TextLabel'
interface Props {
    mainTitle: string;
    mainTitleColor: string;
    textLabel: string;
    labelColor: string;
}
const JobAlertText = (props: Props) => {
    return (
        <>
            <TextLabel label={props.mainTitle} color={props.mainTitleColor} />
            <TextLabel label={props.textLabel} color={props.labelColor} />
        </>
    )
}

export default JobAlertText