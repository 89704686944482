import React, { useMemo, useRef, useState } from 'react'
import { Box, Dialog, Grid, IconButton } from '@mui/material';
import { userSelectionPropsModel } from '../../../Model/ShiftModels';
import { AgGridReact } from 'ag-grid-react';
import { AgGridEvent, ColDef, GridReadyEvent } from 'ag-grid-community';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "../../Shift/Shift.css";
import { AutoComplete } from '../../../Model/AutoCompleteModel';
import IconTextBox from '../../../Components/TextBox/IconTextBox';
import SearchIcon from '@mui/icons-material/Search';
import CustomButton from '../../../Components/Button/Button';
import { LocationButtonStyles } from '../../../layouts/SideNavbarStyle';
import { convertUTCToLocal } from '../../../Utilities/Common';
import { dialogContainer, pv10 } from '../../Shift/ShiftStyles';
import { userSelectionModel } from '../../../Model/ReportModel';

const SelectUserTable = ({ open, onClose, onAdd, rowData, selectedUsers, setSelectedUsers, setSelectedUserNames }: userSelectionModel) => {

    const gridRef = useRef<AgGridReact<AutoComplete>>(null);

    const [searchText, setSearchText] = useState("");

    const onSortChanged = (e: AgGridEvent) => {
        e.api.refreshCells({ force: true });
    };

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            filter: true,
            flex: 1,
        };
    }, []);

    const handleRowSelect = (event: any) => {
        if (event.node.selected) {
            let filteredUser = selectedUsers.find((user) =>
                user === event.data.userId
            );
            if (!filteredUser) {
                setSelectedUsers((prevSelectedUsers) => [...prevSelectedUsers, event.data.userId]);
                setSelectedUserNames((prevSelectedUserNames) => [...prevSelectedUserNames, event.data.displayName]);
            }
        } else {
            setSelectedUsers((prevSelectedUsers) =>
                prevSelectedUsers.filter((userId) => userId !== event.data.userId)
            );
            setSelectedUserNames((prevSelectedUsers) =>
                prevSelectedUsers.filter((userId) => userId !== event.data.displayName)
            );
        }
    };

    const handleQuickFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchText(value);
        if (gridRef.current) {
            gridRef.current.api.setGridOption("quickFilterText", value);
        }
    };

    const onGridReady = (params: GridReadyEvent) => {
        if (selectedUsers.length > 0) {
            params.api.forEachNode((node) => {
                if (selectedUsers.includes(node.data.userId)) {
                    node.setSelected(true);
                }
            });
        }
    };

    const columnDefs = [
        {
            headerCheckboxSelection: true,
            checkboxSelection: true,
            field: '',
            maxWidth: 40,
        },
        { field: "no", headerName: "No", maxWidth: 400, valueGetter: 'node.rowIndex + 1' },
        { headerName: "Attender Name", maxWidth: 450, field: "displayName", sortable: true, filter: true }
    ];

    return (
        <Dialog open={open} maxWidth="md" fullWidth>
            <Grid container sx={dialogContainer}>
                <Grid spacing={1} container sx={pv10}>
                    <Grid item xs={12} md={7}>
                        <IconTextBox placeholder='Search...' handleChange={handleQuickFilter} value={searchText}>
                            <IconButton>
                                <SearchIcon />
                            </IconButton>
                        </IconTextBox>
                    </Grid>
                    <Grid item md={0.4}></Grid>
                    <Grid item xs={6} md={2.3}>
                        <CustomButton
                            onClick={onClose}
                            variant="outlined"
                            sx={{ ...LocationButtonStyles, marginBottom: "10px" }}
                        >
                            {"Cancel"}
                        </CustomButton>
                    </Grid>
                    <Grid item xs={6} md={2.3}>
                        <CustomButton
                            onClick={onAdd}
                            variant="outlined"
                            sx={{ ...LocationButtonStyles, marginBottom: "10px" }}
                        >
                            {"Add"}
                        </CustomButton>
                    </Grid>
                </Grid>
                <Box
                    className="ag-theme-quartz"
                    style={{
                        height: "52.5vh",
                        width: "100%",
                    }}
                >
                    <AgGridReact
                        ref={gridRef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        rowHeight={40}
                        onSortChanged={onSortChanged}
                        gridOptions={{
                            pagination: true,
                            paginationPageSize: 10,
                            paginationPageSizeSelector: [10, 20, 50, 100],
                            suppressRowClickSelection: true,
                        }}
                        onRowSelected={handleRowSelect}
                        rowSelection="multiple"
                        onGridReady={onGridReady}
                    />
                </Box>
            </Grid>
        </Dialog>
    )
}

export default SelectUserTable;