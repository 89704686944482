interface InitialState {
    isLoading: boolean;
    DashboardCount: FloorDetailsModel | null;
}

export interface DashboardCountprops {
    floorDetails: FloorDetail
}

export let dashboardInitialState: InitialState = {
    isLoading: false,
    DashboardCount: null,
};

interface AssetDetail {
    assetTypeName: string;
    totalAssets: number;
    availableAssets: number;
}

export interface AssetDetails {
    totalAsset: number;
    availableAsset: number;
    detailByType: AssetDetail[];
}

export interface AttenderDetails {
    totalAttenders: number;
    availableAttenders: number;
    assetDetails: AssetDetails;
}

export interface FloorDetail {
    floorName: string;
    floorDetails: AttenderDetails;
}

export interface TotalDetails {
    totalAttenders: number;
    availableAttenders: number;
    assetDetails: AssetDetails;
}

export interface FloorDetailsModel {
    totalDetails: TotalDetails;
    floorDetails: FloorDetail[];
}