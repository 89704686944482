import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const DisplayError = ({ errorMsg: errorMsg }: { errorMsg: string }) => {
    return (
        <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert variant="filled" severity="error">
                {errorMsg}
            </Alert>
        </Stack>
    )
}

export default DisplayError