import React from 'react';
import LabelValue from '../../../Components/Label/InfoPair';
import { Box, Card, Grid } from '@mui/material';
import { TaskDataModel, TaskDetailModel } from '../../../Model/ReportModel';
import Theme from '../../../theme/theme';
import TaskStatusDetails from './TaskStatusDetails';

const TaskDetails = (props: TaskDataModel) => {
    const { task, taskDetails } = props;
    return (
        <Box sx={{ padding: 2, border: '2px solid', borderRadius: '13px', borderColor: Theme.palette.common.layout.liteBrownTwo, marginTop: 2}}>
            <Grid container gap={2}>
                <Grid md={12}>
                    <Card sx={{ backgroundColor: Theme.palette.common.layout.lavenderBlue, borderRadius: '8px' }}>
                        <Grid container gap={6} p={2.5}>
                            <Grid md={3.6}>
                                <Box sx={{ marginBottom: 1.4 }}>
                                    <LabelValue label="Task number" value={task.taskNumber} valueColor={Theme.palette.common.layout.liteBrown} />
                                </Box>
                                <Box sx={{ marginBottom: 1.4 }}>
                                    <LabelValue label="Source" value={task.sourceLocationName} valueColor={Theme.palette.common.layout.liteBrown} />
                                </Box>
                                <Box sx={{ marginBottom: 1.4 }}>
                                    <LabelValue label="Destination" value={task.destinationLocationName} valueColor={Theme.palette.common.layout.liteBrown} />
                                </Box>
                                <Box sx={{ marginBottom: 1.4 }}>
                                    <LabelValue label="Asset" value={task.assetName} valueColor={Theme.palette.common.layout.liteBrown} />
                                </Box>
                                <Box sx={{ marginBottom: 1.4 }}>
                                    <LabelValue label="Created on" value={new Date(task.createdOn).toDateString()} valueColor={Theme.palette.common.layout.liteBrown} />
                                </Box>
                            </Grid>
                            <Grid md={3.6}>
                                <Box sx={{ marginBottom: 1.4 }}>
                                    <LabelValue label='Status' value={task.taskStatusName ? task.taskStatusName : ''} valueColor={Theme.palette.common.layout.liteBrown} />
                                </Box>
                                <Box sx={{ marginBottom: 1.4 }}>
                                    <LabelValue label="Task duration" value={task.actualCompletedTime ? task.actualCompletedTime + " Minutes" : ''} valueColor={Theme.palette.common.layout.liteBrown} />
                                </Box>
                                <Box sx={{ marginBottom: 1.4 }}>
                                    <LabelValue label="Priority" value={task.priorityId.toString()} valueColor={Theme.palette.common.layout.liteBrown} />
                                </Box>
                                <Box sx={{ marginBottom: 1.4 }}>
                                    <LabelValue label="Created by" value={task.creatorName} valueColor={Theme.palette.common.layout.liteBrown} />
                                </Box>
                            </Grid>
                            <Grid md={3.6}>
                                <Box sx={{ marginBottom: 1.4 }}>
                                    <LabelValue label="Attender name" value={task.attenderName} valueColor={Theme.palette.common.layout.liteBrown} />
                                </Box>
                                <Box sx={{ marginBottom: 1.3 }}>
                                    <LabelValue label="Scheduled time" value={task.scheduleTime ? new Date(task.scheduleTime).toDateString() : ''} valueColor={Theme.palette.common.layout.liteBrown} />
                                </Box>
                                <Box sx={{ marginBottom: 1.4 }}>
                                    <LabelValue label="Patient name" value={task.patientName} valueColor={Theme.palette.common.layout.liteBrown} />
                                </Box>
                                <Box sx={{ marginBottom: 1.4 }}>
                                    <LabelValue label="Employee number" value={task.employeeNumber} valueColor={Theme.palette.common.layout.liteBrown} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid md={12}>
                    {taskDetails && taskDetails.length > 0 && taskDetails[0].taskId != 0 ? <TaskStatusDetails currentStatus={task.taskStatusName ? task.taskStatusName : ''} data={taskDetails} /> : <div>No data</div>}
                </Grid>
                <Grid sx={{ height: '4px' }} />
            </Grid>
        </Box>
    );
}

export default TaskDetails;
