import "./App.css";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/login/Login";
import { Provider } from "react-redux";
import store, { useAppDispatch } from "./Redux/Store";
import { ThemeProvider } from "@mui/material";
import theme from "./theme/theme";
import Dashboard from "./pages/Dashboard/Dashboard";
import LocationList from "./pages/ManageLocations/LocationList";
import PrivateRoute from "./layouts/PrivateRoute";
import BLock from "./pages/Block/Block";
import Floor from "./pages/Floor/Floor";
import AssetList from "./pages/Asset/AssetList";
import AddUser from "./pages/User/AddUser";
import * as signalR from '@microsoft/signalr';
import PatientDetails from "./pages/Patient/PatientDetails";
import Shift from "./pages/Shift/Shift";
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import { useEffect } from "react";
import { setConnection } from "./Redux/Slices/SignalRSlice";
import Report from "./pages/Report/Report";
import ShiftView from "./pages/Shift/ShiftAllocation/ShiftView";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/locations"
              element={
                <PrivateRoute>
                  <LocationList />
                </PrivateRoute>
              }
            />
            <Route
              path="/bLock"
              element={
                <PrivateRoute>
                  <BLock />
                </PrivateRoute>
              }
            />
            <Route
              path="/floor"
              element={
                <PrivateRoute>
                  <Floor />
                </PrivateRoute>
              }
            />
            <Route
              path="/asset"
              element={
                <PrivateRoute>
                  <AssetList />
                </PrivateRoute>
              }
            />
            <Route
              path="/manageUser"
              element={
                <PrivateRoute>
                  <AddUser />
                </PrivateRoute>
              }
            />
            <Route
              path="/addPatients"
              element={
                <PrivateRoute>
                  <PatientDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/shift"
              element={
                <PrivateRoute>
                  <Shift />
                </PrivateRoute>
              }
            />
            <Route
              path="/report"
              element={
                <PrivateRoute>
                  <Report />
                </PrivateRoute>
              }
            />
            <Route
              path="/shift/view"
              element={
                <PrivateRoute>
                  <ShiftView />
                </PrivateRoute>
              }
            />
          </Routes>
        </Router>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
