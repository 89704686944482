import { createSlice } from "@reduxjs/toolkit";
import { GetCancelRequestList } from "../Thunks/CancelRequestedThunk";
import { CancelRequestTask } from "../../Model/CancelRequestModel";

interface InitialState {
    isLoading: boolean;
    CancelRequestedList: CancelRequestTask[];
    CancelRequest: CancelRequestTask|null
}

let initialState: InitialState = {
    isLoading: false,
    CancelRequestedList: [],
    CancelRequest: null
};

const CancelRequests = createSlice({
    name: "CancelRequest",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetCancelRequestList.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(GetCancelRequestList.fulfilled, (state, action) => {
                state.isLoading = false;
                if (action.payload.status == true) {
                    state.CancelRequestedList = action.payload.data
                }
                else {
                    state.CancelRequestedList = [];
                };
            })
            .addCase(GetCancelRequestList.rejected, (state, action) => {
                state.isLoading = false;
            })
    }

})

export const cancelRequestReducer = CancelRequests.reducer;