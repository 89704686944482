import dayjs from "dayjs";
import utcPlugin from "dayjs/plugin/utc";
dayjs.extend(utcPlugin);

// Date Time Functions :
export const numberToDateTime = (num: number): Date => {
  if (num >= 25) {
    throw new Error("The number must be less than 25");
  }

  const currentDate = new Date();
  const hour = Math.floor(num);
  const minuteCalc = num - hour;
  const minutes = Math.round(minuteCalc * 60);

  currentDate.setHours(hour);
  currentDate.setMinutes(minutes);
  currentDate.setSeconds(0);
  currentDate.setMilliseconds(0);
  return currentDate;
};

export const convertDateToTime = (dateTime: Date): string => {
  const date = new Date(dateTime);
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const time = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12;
  const minutesStr = minutes < 10 ? "0" + minutes : minutes.toString();

  return `${hours}:${minutesStr} ${time}`;
};

export const getDuration = (startDate: Date, endDate: Date) => {
  var difference = new Date(endDate).getTime() - new Date(startDate).getTime();

  var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
  difference -= daysDifference * 1000 * 60 * 60 * 24;

  var hoursDifference = Math.floor(difference / 1000 / 60 / 60);
  difference -= hoursDifference * 1000 * 60 * 60;

  var minutesDifference = Math.floor(difference / 1000 / 60);
  difference -= minutesDifference * 1000 * 60;

  var secondsDifference = Math.floor(difference / 1000);

  return `${hoursDifference} hrs ${minutesDifference} mins`;
};

export const convertUTCToLocal = (
  dateOrString: Date | string,
  format?: string
): string => {
  return dayjs.utc(dateOrString).local().format(format);
};

export const replaceTime = (
  sourceDate: Date | string | null,
  replacementDate: Date | string | null
) => {
  if (sourceDate !== null && replacementDate !== null) {
    const extractDate = dayjs(replacementDate);

    const hours = extractDate.hour();
    const minutes = extractDate.minute();
    const seconds = extractDate.second();
    const milliseconds = extractDate.millisecond();

    let newDate = dayjs(sourceDate);
    newDate = newDate
      .set("hour", hours)
      .set("minute", minutes)
      .set("second", seconds)
      .set("millisecond", milliseconds);
    return newDate.toDate();
  }
};

export const tomorrowDate = (): Date => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  return tomorrow;
};

export class ReportTypes {
  static taskReport = "1";
  static attendanceReport = "2";

  static getReportStartDate(date: Date) {
    const reportStartDate = dayjs(date).hour(0).minute(1).second(0).millisecond(0);
    return reportStartDate.toDate();
  }

  static getReportEndDate(date: Date) {
    const reportEndDate = dayjs(date).hour(23).minute(59).second(0).millisecond(0);
    return reportEndDate.toDate();
  }

}
