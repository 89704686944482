import { createSlice } from "@reduxjs/toolkit";
import { UserProfile } from "../Thunks/LoginThunk";
import { UserModel } from "../../Model/UserModel";

interface InitialState {
    isLoading: boolean;
    user: UserModel | null;
}

let initialState: InitialState = {
    isLoading: false,
    user: null,
};

const ProfileSlice = createSlice({
    name: "Profile",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(UserProfile.fulfilled, (state, action) => {
            state.isLoading = false;
            if (action.payload.status == true) {
                state.user = action.payload.data
            }
            else {
                state.user = null;
            }
        })
        builder.addCase(UserProfile.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(UserProfile.rejected, (state) => {
            state.isLoading = false;
        })
    }
});

export const userReducer = ProfileSlice.reducer;