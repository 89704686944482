import { createSlice } from "@reduxjs/toolkit";
import { dashboardInitialState } from "../../Model/DashboardModel";
import { GetAssetAttenderCount } from "../Thunks/DashboardThunk";

const DashboardSlice = createSlice({
    name: "Dashboard",
    initialState: dashboardInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetAssetAttenderCount.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(GetAssetAttenderCount.fulfilled, (state, action) => {
                state.isLoading = false;
                if (action.payload.status == true) {
                    state.DashboardCount = action.payload.data
                }
                else {
                    state.DashboardCount = null;
                };
            })
            .addCase(GetAssetAttenderCount.rejected, (state, action) => {
                state.isLoading = false;
            })
    }
})

export const dashboardReducer = DashboardSlice.reducer;