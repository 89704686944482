import React from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import { SxProps, Theme } from "@mui/material";

interface MuiButtonProps extends ButtonProps {
  onClick: () => void;
  text?: string|undefined;
  formik?: any;
  sx?: SxProps<Theme>;
  children: string | JSX.Element;
  isLoading?: boolean;
}

const CustomButton: React.FC<MuiButtonProps> = ({
  onClick,
  variant = "contained",
  color = "primary",
  text,
  formik,
  sx,
  children,
  isLoading = false,
}) => {
  const shouldDisable = isLoading;
  return (
    <Button
      onClick={onClick}
      variant={variant}
      color={color}
      sx={{
        width: 150,
        height: 35,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        whiteSpace: "nowrap",
        color: "black",
        borderRadius: "15px",
        ...sx,
      }}
      disabled={shouldDisable}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
