import { createAsyncThunk } from "@reduxjs/toolkit";
import { getHeaderToken } from "../../Utilities/header";
import axios from "axios";
import { getStoreId } from "../../Utilities/Store";

export const GetNotificationList = createAsyncThunk(
    "Notification/getList",
    async () => {
        try {
            const storeId = await getStoreId();
            const headerToken = await getHeaderToken();
            const URL = `${process.env.REACT_APP_BASEURL}/api/Dashboard/dashboardNotification/${storeId}`;
            const response = await axios.get(URL, headerToken);
            return response.data;
        } catch (e: any) {
            throw new Error(e.response || 'Error fetching notifications');
        }
    }
);
export const deleteNotification = createAsyncThunk(
    "Notification/delete",
    async (id: number) => {
        try {
            const URL = process.env.REACT_APP_BASEURL + `/api/Dashboard/delete/${id}`;
            const response = await axios.delete(URL, await getHeaderToken());
            return response;
        } catch (e: any) {
            return e.response;
        }
    }
)

// export const deleteFloor = createAsyncThunk(
//     "floor/deleteFloor",
//     async (data: DeleteFloorEntity) => {
//         try {
//             ;
//             const URL = process.env.REACT_APP_BASEURL + `/api/Floor/delete/${data.id}/${data.blockId}/1`;
//             const response = await axios.delete(URL, await getHeaderToken());
//             return response.data;
//         } catch (e: any) {
//             return e.response;
//         }
//     }
// );
