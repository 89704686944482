import Theme from "../../theme/theme";
export const floorContainerStyles = {
  backgroundColor: Theme.palette.common.layout.commonBlue,
  borderRadius: "15px",
  margin: "1rem 0 1rem 0",
  padding: "1rem",
};

export const floorTextBoxStyles = {
  backgroundColor: "white",
  borderRadius: "15px",
  "& .MuiFormHelperText-root": {
    backgroundColor: "transparent",
  },
};

export const floorButtonStyles = {
  color: "black",
  backgroundColor: Theme.palette.common.layout.patientButton,
  "&:hover": {
    backgroundColor: Theme.palette.common.layout.buttonHoverBackground,
  },
};
