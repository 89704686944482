import { createSlice } from "@reduxjs/toolkit";
import { GetFloorById, GetFloorList } from "../Thunks/FloorThunk";

interface InitialState {
    isLoading: boolean;
    Floors: any;
    floor: any
}

let initialState: InitialState = {
    isLoading: false,
    Floors: [],
    floor: null
};

const FloorSlice = createSlice({
    name: "Floor",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetFloorList.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(GetFloorList.fulfilled, (state, action) => {
                state.isLoading = false;
                if (action.payload.status == true) {
                    state.Floors = action.payload.data
                }
                else {
                    state.Floors = null;
                };
            })
            .addCase(GetFloorList.rejected, (state, action) => {
                state.isLoading = false;
            })
            .addCase(GetFloorById.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(GetFloorById.fulfilled, (state, action) => {
                state.isLoading = false;
                if (action.payload.status == true) {
                    state.floor = action.payload.data
                }
                else {
                    state.floor = null;
                };
            })
            .addCase(GetFloorById.rejected, (state, action) => {
                state.isLoading = false;
            })
    }
})

export const floorReducer = FloorSlice.reducer;