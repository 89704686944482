import Theme from "../../theme/theme";
export const assetStyles = {
  containerStyles: {
    border: `1px solid ${Theme.palette.common.layout.borderclr}`,
    padding: "1rem",
    borderRadius: "15px",
    marginTop: "1rem",
    marginBottom: "1rem",
    backgroundColor: Theme.palette.common.layout.backgroundClr,
  },
  ReportContainerStyle: {
    border: `1px solid ${Theme.palette.common.layout.borderclr}`,
    paddingBottom: "1rem",
    borderRadius: "15px",
    marginTop: "1rem",
    marginBottom: "1rem",
    marginLeft:'0',
    backgroundColor: Theme.palette.common.layout.backgroundClr,
  },
  buttonStyles: {
    submitButtonStyles: {
      color: "black",
      backgroundColor: Theme.palette.common.layout.patientButton,
      "&:hover": {
        backgroundColor: Theme.palette.common.layout.buttonHoverBackground,
      },
    },
    cancelButtonStyles: {
      color: "white",
      border: `1px solid ${Theme.palette.common.layout.borderclr}`,
      "&:hover": {
        backgroundColor: Theme.palette.common.layout.clearButton,
        border: `1px solid ${Theme.palette.common.layout.borderclr}`,
      },
    },
    uploadButtonStyles: {
      backgroundColor: Theme.palette.common.layout.patientButton,
      padding: "0.7rem",
      borderRadius: "15px",
      width: "10rem",
    },
  },
  textBoxStyles: {
    backgroundColor: "white",
    borderRadius: "15px",
    "& .MuiFormHelperText-root": {
      backgroundColor: "transparent",
    },
  },
};
