import Theme from "../theme/theme";
export const startbarContainerStyles = {
    // backgroundImage: `linear-gradient(to bottom, ${Theme.palette.primary.dark} 0 70%, ${Theme.palette.primary.light})`,
    padding: "0.38rem",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    // borderRadius:"2rem",
    marginTop: "5px",
    width: "100%",
    cursor: "pointer",
}
export const iconBackgroundStyles = {
    backgroundColor: Theme.palette.primary.light,
    color: Theme.palette.common.white,
    padding: { xs: "0.3rem", md: "0.5rem" },
    borderRadius: "50%",
}

export const TypographynumericStyles = {
    color: Theme.palette.common.white,
    fontWeight: "bold",
    fontSize: "18px"
}

export const textStylesforStartbar = {
    color: Theme.palette.background.paper,
    fontSize: "11px",
    // whiteSpace: "nowrap",
}
export const textStylesforNotificationbar = {
    color: Theme.palette.common.layout.black,
    fontSize: "10px",
    display:'block'
    // whiteSpace: "nowrap",
}

export const itemContainerStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}

export const AccordionStyle = {
    paddingRight: "0",
    paddingLeft: "0",
    boxShadow: "none",
    backgroundImage: `linear-gradient(100deg, rgba(46,37,134,1) 75%, rgba(93,81,204,1) 100%);`,
}

export const mobileDisplayStyles = {
    mainContainer: {
        display: { xs: "flex" },
        justifyContent: { xs: "center" },
    },
    titleContainer: {
        display: { xs: "flex", md: "" },
        justifyContent: { xs: "center", md: "left" },
    },
    valueContainer: {
        display: { xs: "flex", md: "" },
        justifyContent: { xs: "center", md: "left" },
    }
}