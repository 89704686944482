import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getHeaderToken } from "../../Utilities/header";
import { ApiResponse } from "../../Utilities/ApiResponse";
import { AddAssetModel } from "../../Model/AddAssetModel";
import { getStoreId } from "../../Utilities/Store";
const url = process.env.REACT_APP_BASEURL;

export const CreateAsset = createAsyncThunk<ApiResponse, AddAssetModel>(
  "post/AddAsset",
  async (asset) => {
    try {
      const AddAssetURL = url + "/api/Asset/createAsset";
      const response = await axios.post(
        AddAssetURL,
        asset,
        await getHeaderToken()
      );
      return {
        status: true,
        data: response.data,
        resultCode: response.status,
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        status: false,
        data: null,
        resultCode: error.response.status,
        message: error.response,
      };
    }
  }
);
export const GetAssetDetails = createAsyncThunk<ApiResponse>(
  "Get/AssetDetails",
  async () => {
    try {
      let response = await axios.get(
        `${url}/api/Asset/${await getStoreId()}`,
        await getHeaderToken()
      );
      return {
        status: true,
        data: response.data.data,
        resultCode: response.status,
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        status: false,
        data: null,
        resultCode: error.response.status,
        message: error.response,
      };
    }
  }
);

export const UpdateAsset = createAsyncThunk<ApiResponse, AddAssetModel>(
  "Update/asset",
  async (params) => {
    try {
      let response = await axios.put(
        `${url}/api/Asset/update/asset`,
        params,
        await getHeaderToken()
      );
      return response;
    } catch (error: any) {
      return error.response;
    }
  }
);

export const GetByAssetID = createAsyncThunk(
  "asset/getByAssetID",
  async (id: number) => {
    try {
      let response = await axios.get(
        `${url}/api/Asset/GetByAsset/${await getStoreId()}/${id}`,
        await getHeaderToken()
      );
      return response.data;
    } catch (error: any) {
      return error.response;
    }
  }
);
export const GetAssetTypeList = createAsyncThunk(
  "Asset/GetAssetTypeList",
  async () => {
    try {
      const URL = process.env.REACT_APP_BASEURL + `/api/Asset/GetAssetTypeList/${await getStoreId()}`;
      const response = await axios.get(URL, await getHeaderToken());
      return response.data;
    } catch (e: any) {
      return e.response;
    }
  }
);

export const deleteAsset = createAsyncThunk(
  "asset/delete",
  async (id: number) => {
    try {
      let response = await axios.delete(
        `${url}/api/Asset/deleteAsset/${await getStoreId()}/${id}`,
        await getHeaderToken()
      );
      return response.data;
    }
    catch (error: any) {
      return error.response;
    }
  }
);
