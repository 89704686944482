import { createAsyncThunk } from "@reduxjs/toolkit";
import { getHeaderToken } from "../../Utilities/header";
import axios from "axios";
import { getStoreId } from "../../Utilities/Store";
import { GetReportParamsModel, GetTaskParamsModel } from "../../Model/ReportModel";

export const GetTaskReportData = createAsyncThunk(
    "Report/getTaskReport",
    async (data: GetTaskParamsModel) => {
        try {
            const URL = process.env.REACT_APP_BASEURL + `/api/Report/taskReport`;
            const response = await axios.post(URL, data, await getHeaderToken());
            return response.data;
        } catch (e: any) {
            return e.response;
        }
    }
);

//Get all Attender list:-
export const GetAttenderList = createAsyncThunk(
    "Job/getAttenderList",
    async () => {
        try {
            const URL = process.env.REACT_APP_BASEURL + `/api/Attender/Attender/${await getStoreId()}`;
            const response = await axios.get(URL, await getHeaderToken());
            return response.data;
        } catch (e: any) {
            return e.response;
        }
    }
);
export const GetAttendanceReportData = createAsyncThunk(
    "Report/getAttendanceReport",
    async (data: GetReportParamsModel) => {
        try {
            console.log("GetAttendanceReportData")
            const URL = process.env.REACT_APP_BASEURL + `/api/Report/attendanceReport`;
            const response = await axios.post(URL, data, await getHeaderToken());
            return response.data;
        } catch (e: any) {
            return e.response;
        }
    }
);