import { createTheme, PaletteColorOptions } from "@mui/material/styles";
import shadows from "@mui/material/styles/shadows";

declare module "@mui/material/styles/createPalette" {
  interface CommonColors {
    layout: {
      btnHover: string;
      btnPositive: string;
      btnNegative: string;
      white: string;
      black: string;
      liteBrown: string;
      liteBrownTwo: string;
      lavenderBlue: string;
      skeletonBackground: string;
      alertBoxProfileBackground: string;
      AlertDanger: string;
      AlertText: string;
      SearchbarBorder: string;
      TableHeaderBackground: string;
      InProgressText: string;
      overlayClr: string;
      alertBoxBorder: string;
      commonBlue: string;
      patientButton: string;
      disabledButton: string;
      borderclr: string;
      clearButton: string;
      buttonUpload: string;
      backgroundClr: string;
      buttonHoverBackground: string;
    };
  }
}

const Theme = createTheme({
  palette: {
    primary: {
      main: "#4035a0",
      light: "#5D51CC",
      dark: "#2E2586",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#FFEECA",
      light: "#4f44b0",
      dark: "#9daab8",
      contrastText: "#f53232",
    },
    background: {
      default: "#f0f0f0",
      paper: "#FFFFFF",
    },
    text: {
      primary: "#000",
      secondary: "#000",
    },
    common: {
      layout: {
        commonBlue: "#2F2587",
        backgroundClr: "#352B90",
        clearButton: "#584faa",
        btnHover: "#00f235",
        btnPositive: "#0cbe08",
        btnNegative: "#8B0000",
        white: "#ffffff",
        black: "#000000",
        liteBrown: "#717171",
        liteBrownTwo: "#c2c2c2",
        lavenderBlue: "#eeedfb",
        skeletonBackground: "#c9c7e1",
        alertBoxProfileBackground: "#d1efff",
        AlertDanger: "#f53232",
        AlertText: "#556b82",
        SearchbarBorder: "#d9d9d9",
        TableHeaderBackground: "#f7f7fc",
        InProgressText: "#dfd71a",
        overlayClr: "#00000080",
        alertBoxBorder: "#9daab8",
        patientButton: "#E3E1FC",
        disabledButton: "#9e9e9e",
        borderclr: "#B6B6B6",
        buttonUpload: "#352B90",
        buttonHoverBackground: "#BEBBF0",
      },
    },
  },
  typography: {
    allVariants: {
      fontFamily: ["Raleway", "sans-serif"].join(","),
    },
    h1: {
      fontSize: "2.5rem",
    },
    h2: {
      fontSize: "2rem",
    },
    h3: {
      fontSize: "1.75rem",
    },
    h4: {
      fontSize: "1.5rem",
    },
    h5: {
      fontSize: "1.25rem",
    },
    h6: {
      fontSize: "0.8rem",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "poppins",
          justifyContent: "center",
          fontWeight: "bold",
          fontSize: "12px",
          "&.Mui-focused": {
            borderRadius: "15px",
          },
        },
      },
    },
  },
});

export default Theme;

export const datePickerTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          '&.MuiPickersToolbarText-root.Mui-selected': {
            color: '#1976d2',
            fontWeight: '600',
          },
          '&.MuiTimePickerToolbar-ampmLabel': {
            color: '#9e9e9e',
            fontWeight: 'normal',
          },
        },
      },
    },
  },
});