import theme from "../../theme/theme"
//Login Page TextField Styles
export const inputStyle = {
  "& .MuiOutlinedInput-root": {
    color: `${theme.palette.primary.dark}`,
    height: '40px',
    fontFamily: "Arial",
    fontWeight: "bold",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.palette.primary.dark}`,
      borderWidth: "1px",
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.palette.primary.dark}`,
        borderWidth: "1px",
      },
    },
    "&:hover:not(.Mui-focused)": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.palette.primary.light}`,
      },
    },
  },
  "& .MuiInputLabel-outlined": {
    color: `${theme.palette.primary.dark}`,
    fontWeight: "bold",
    "&.Mui-focused": {
      color: `${theme.palette.primary.dark}`,
      fontWeight: "bold",
    },
  },
}




