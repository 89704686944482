import { createAsyncThunk } from "@reduxjs/toolkit";
import { getHeaderToken } from "../../Utilities/header";
import axios from "axios";
import { DeleteFloorEntity } from "../../Model/FloorModel";
import { getStoreId } from "../../Utilities/Store";

export const GetFloorList = createAsyncThunk(
    "floor/getList",
    async () => {
        try {
            const URL = process.env.REACT_APP_BASEURL + `/api/Floor/${await getStoreId()}`;
            const response = await axios.get(URL, await getHeaderToken());
            return response.data;
        } catch (e: any) {
            return e.response;
        }
    }
);

export const GetFloorById = createAsyncThunk(
    "floor/getListById",
    async (data: DeleteFloorEntity) => {
        try {
            ;
            const URL = process.env.REACT_APP_BASEURL + `/api/Floor/${data.id}/${data.blockId}/${await getStoreId()}`;
            const response = await axios.get(URL, await getHeaderToken());
            return response.data;
        } catch (e: any) {
            return e.response;
        }
    }
);

export const AddFloor = createAsyncThunk(
    "floor/AddFloor",
    async (data: any) => {
        try {
            ;
            const URL = process.env.REACT_APP_BASEURL + "/api/Floor/add";
            const response = await axios.post(URL, data, await getHeaderToken());
            return response.data;
        } catch (e: any) {
            return e.response;
        }
    }
);

export const UpdateFloor = createAsyncThunk(
    "floor/updateFloor",
    async (data: any) => {
        try {
            ;
            const URL = process.env.REACT_APP_BASEURL + "/api/Floor/update";
            const response = await axios.put(URL, data, await getHeaderToken());
            return response.data;
        } catch (e: any) {
            return e.response;
        }
    }
);

export const deleteFloor = createAsyncThunk(
    "floor/deleteFloor",
    async (data: DeleteFloorEntity) => {
        try {
            ;
            const URL = process.env.REACT_APP_BASEURL + `/api/Floor/delete/${data.id}/${data.blockId}/${await getStoreId()}`;
            const response = await axios.delete(URL, await getHeaderToken());
            return response.data;
        } catch (e: any) {
            return e.response;
        }
    }
);