import { Box, Grid, Tooltip } from "@mui/material";
import { useState } from "react";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ScrollBarStyle } from "../../Components/ScrollBarStyle";
import AccessibleSharpIcon from '@mui/icons-material/AccessibleSharp';
import {
  startbarContainerStyles,
  itemContainerStyles,
  AccordionStyle,
  mobileDisplayStyles,
  textStylesforStartbar,
  TypographynumericStyles,
  iconBackgroundStyles,
} from "../../Components/NewStartbarStyles";
import { FloorDetails } from "./ExpansionStartbar";
import Divider from "@mui/material/Divider";
import { useAppSelector } from "../../Redux/Store";

const DashboardCount = () => {
  const { DashboardCount } = useAppSelector(state => state.dashboard)
  const [expand, setExpand] = useState<boolean>(false);
  const handleToggleExapand = () => {
    setExpand(!expand);
  };

  return (
    <Accordion
      disableGutters
      style={{ borderRadius: "1rem" }}
      sx={AccordionStyle}
    >
      <AccordionSummary sx={startbarContainerStyles}>
        <Grid item container sx={itemContainerStyles} xs={4}>
          <Grid item xs={12} md={4} sx={mobileDisplayStyles.mainContainer}>
            <PeopleAltOutlinedIcon sx={iconBackgroundStyles} />
          </Grid>
          <Grid item container xs={12} md={8}>
            <Grid item xs={12} sx={mobileDisplayStyles.titleContainer}>
              <Typography sx={textStylesforStartbar}>
                Total Attenders
              </Typography>
            </Grid>
            <Grid item xs={12} sx={mobileDisplayStyles.valueContainer}>
              <Typography sx={TypographynumericStyles}>
                {DashboardCount && DashboardCount.totalDetails.totalAttenders}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container sx={itemContainerStyles} xs={4}>
          <Grid item xs={12} md={4} sx={mobileDisplayStyles.mainContainer}>
            <HowToRegOutlinedIcon sx={iconBackgroundStyles} />
          </Grid>
          <Grid item container xs={12} md={8}>
            <Grid item xs={12} sx={mobileDisplayStyles.titleContainer}>
              <Typography sx={textStylesforStartbar}>
                Available Attenders
              </Typography>
            </Grid>
            <Grid item xs={12} sx={mobileDisplayStyles.valueContainer}>
              <Typography sx={TypographynumericStyles}>
                {DashboardCount && DashboardCount.totalDetails.availableAttenders}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container sx={itemContainerStyles} xs={4}>
          <Grid item xs={12} md={4} sx={mobileDisplayStyles.mainContainer}>
            <AccessibleSharpIcon sx={iconBackgroundStyles} />
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={8}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Grid item xs={12} sx={mobileDisplayStyles.titleContainer}>
              <Tooltip title={"Total Asset is " + DashboardCount?.totalDetails.assetDetails.totalAsset}>

                <Typography sx={textStylesforStartbar}>
                  Available Assets
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sx={mobileDisplayStyles.valueContainer}>
              <Typography sx={TypographynumericStyles}>
                {DashboardCount && DashboardCount.totalDetails.assetDetails.availableAsset}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails
        onClick={handleToggleExapand}  // Fixed typo: handleToggleExpand
      >
        <Divider
          sx={{
            width: { xs: '100%' },
            borderBottomWidth: 3,
            borderRadius: '20px',
          }}
        />
        <Box sx={{
          ...ScrollBarStyle,
          maxHeight: { xs: '200px', lg: '250px' },

        }}>

          {DashboardCount &&
            DashboardCount.floorDetails.map((data) => (
              <FloorDetails floorDetails={data} />
            ))}
        </Box>
        <Divider
          sx={{
            width: { xs: '100%' },
            borderBottomWidth: 3,
            borderRadius: '20px',
          }}
        />
      </AccordionDetails>

    </Accordion>
  );
};

export default DashboardCount;
