import { createSlice } from "@reduxjs/toolkit";
import { GetStoreList } from "../Thunks/StoreThunk";

interface InitialState {
    isLoading: boolean;
    Stores: any;
    Store: any
}

let initialState: InitialState = {
    isLoading: false,
    Stores: [],
    Store: null
};

const StoreSlice = createSlice({
    name: "Stores",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetStoreList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(GetStoreList.fulfilled, (state, action) => {
                state.isLoading = false;
                if (action.payload.status == true) {
                    state.Stores = action.payload.data
                }
            })
            .addCase(GetStoreList.rejected, (state) => {
                state.isLoading = false;
            })
    }
})

export const StoreReducer = StoreSlice.reducer;