import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INotificationList } from "../../Model/NotificationModel";
import { GetNotificationList } from "../Thunks/NotificationThunk";

interface InitialState {
    isLoading: boolean;
    notificationData: INotificationList[];
    error: string | null;
}

const initialState: InitialState = {
    isLoading: false,
    notificationData: [{ notificationId: 0 }],
    error: null
};
const notificationSlice = createSlice({
    name: "Notification",
    initialState,
    reducers: {
        removeNotification: (state, action) => {
            state.notificationData = state.notificationData.filter((notification) =>
                notification.notificationId !== action.payload
            );
        },
        addNotification: (state, action) => {
            state.notificationData.unshift(action.payload);
            if (state.notificationData.length > 15) {
                state.notificationData.pop();
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(GetNotificationList.fulfilled, (state, action) => {
            state.isLoading = false;
            if (action.payload.status == true) {
                state.notificationData = action.payload.data;
                state.error = null;
            } else {
                state.notificationData = [];
                state.error = "Failed to fetch notifications";
            }
        });
        builder.addCase(GetNotificationList.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(GetNotificationList.rejected, (state, action) => {
            state.isLoading = false;
            state.error = "Failed to fetch notifications";
        });
    }
});


export const notificationReducer = notificationSlice.reducer;
export const { removeNotification, addNotification } = notificationSlice.actions;
