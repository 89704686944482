import Theme from "../../theme/theme";

export const sliderContainerStyle = {
    backgroundColor: Theme.palette.common.white,
    paddingTop: "1rem",
    paddingBottom: "1rem",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    borderRadius: "15px"
}

export const pv10 = {
    paddingTop: "10px",
    paddingBottom: "10px",
}

export const pb2r = {
    paddingBottom: "2rem"
}

export const shiftIntervalCardContainer = {
    backgroundColor: Theme.palette.common.white,
    padding: "5px 10px 5px 10px",
    borderRadius: "10px"
}

export const shiftIntervalTitleText = {
    color: Theme.palette.primary.main,
    fontWeight: "bold"
}

export const removeTxt = {
    color: Theme.palette.common.layout.AlertDanger,
    textAlign: "center",
    cursor: "pointer"
}

export const dialogContainer = {
    backgroundColor: Theme.palette.common.white,
    padding: "1rem 1.5rem 1rem 1rem",
    borderRadius: "10px",

}