import { Box, Grid, IconButton, Tooltip } from "@mui/material";
import Asset from "./Asset";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/Store";
import { deleteAsset, GetAssetDetails } from "../../Redux/Thunks/AssetThunk";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { ColDef } from "ag-grid-community";
import DataTable from "../../Components/DataTable/DataTable";
import Title from "../../Components/Title/Title";
import DeleteIcon from "@mui/icons-material/Delete";
import { GetLocationList } from "../../Redux/Thunks/LocationThunk";
import { LocationData } from "../../Model/Location";
import DialogBox from "../../Components/DialogBox/DialogBox";
import { clearAsset } from "../../Redux/Slices/AssetSlice";
import { AssetData } from "../../Model/AddAssetModel";
import Theme from "../../theme/theme";
const AssetList: React.FC = () => {
  const dispatch = useAppDispatch();
  const { assetList } = useAppSelector((state) => state.asset);
  const [isUpdated, setIsUpdated] = useState(false)
  const [id, setId] = useState(0);
  const [deleteAssetId, setDeleteAssetId] = useState(0);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [assetTableData, setAssetTableData] = useState<AssetData[]>([]);
  const { locations } = useAppSelector((store) => store.location);
  useEffect(() => {
    dispatch(clearAsset());
    dispatch(GetAssetDetails());
  }, []);
  useEffect(() => {
    const GetLocationDetail = async () => {
      await dispatch(GetLocationList());
    };
    GetLocationDetail();
  }, [locations[0].locationId || locations.length]);
  useEffect(() => {
    if (assetList && assetList.length > 0) {
      setAssetTableData(assetList.filter((data) => data.assetId !== 0));
    }
  }, [assetList]);
  const TokenRenderer = (props: any) => {
    let locationName = locations.map((data: LocationData) => {
      return props.data.locationId === data.locationId ? data.locationName : props.data.locationName
    })
    return (
      <Tooltip title="type" placement="bottom-start">
        <Box>{locationName}</Box>
      </Tooltip>
    );
  };

  const onEdit = (data: any) => {
    setId(data.assetId);
    setIsUpdated(true);
  };

  const onDelete = async (data: any) => {
    setDeleteAssetId(data.assetId);
    setShowDialog(true);
  };
  const handleDisagree = () => {
    setShowDialog(false);
  };
  const handleAgree = async () => {
    setShowDialog(false);
    let res = await dispatch(deleteAsset(deleteAssetId));
    if (res.meta.requestStatus === "rejected") {

      return;
    }
    if (
      (res.payload && res.payload.status && res.payload.status === true) ||
      res.payload.status === 200
    ) {
      await dispatch(GetLocationList());
      await dispatch(GetAssetDetails());


    } else {

    }
  };
  const ActionRenderer = (props: any) => {
    return (
      <div>
        <Box aria-label="Actions">
          <Tooltip title="Edit" placement="bottom-start">
            <IconButton onClick={() => onEdit(props.data)}>
              <EditOutlinedIcon color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" placement="bottom-start">
            <IconButton onClick={() => onDelete(props.data)}>
              <DeleteIcon color="primary" />
            </IconButton>
          </Tooltip>
        </Box>
      </div>
    );
  };
  const columnDefs: ColDef[] = [
    {
      field: "no",
      headerName: "No",
      maxWidth: 75,
      valueGetter: 'node.rowIndex + 1',
    },
    {
      field: "assetName",
      headerName: "Asset Name",
      minWidth: 75,
    },
    {
      field: "assetTypeName",
      headerName: "AssetType Name",
      minWidth: 75,
    },
    {
      field: "locationId",
      headerName: "Location",
      minWidth: 104,
      cellRenderer: TokenRenderer,
    },
    { field: "actions", headerName: "Actions", cellRenderer: ActionRenderer },
  ];
  return (
    <>
      <Box pr={2} pt={1}>
        <Grid container spacing={1.5} padding={{ md: 1.4, sm: 2 }}>
          <Grid md={12} xs={12}>
            <Box>
              <Asset id={id} isUpdated={isUpdated} setId={setId} />
            </Box>
          </Grid>
          <Grid xs={12}>
            <Title title="Asset List" sx={{ color: Theme.palette.primary.main }} />
          </Grid>
          <Box sx={{ width: '100%' }}>
            <DataTable rows={assetTableData} columnDefs={columnDefs} />
          </Box>

        </Grid>

        <DialogBox
          message={"Do you want to delete the asset?"}
          handleAccept={() => handleAgree()}
          handleCancel={handleDisagree}
          open={showDialog}
        />

      </Box>

    </>
  );
};

export default AssetList;
