import React from 'react';
import { LabelProps } from './Label';

const LabelValue = (props: LabelProps) => {
    const { label, value, color, valueColor } = props;
    return (
        <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between' }}>
            <span style={{ fontWeight: '500', marginRight: '8px' }}>{label}:</span>
            <span style={{ color: valueColor ? valueColor : '', fontWeight: '500', }}>{value}</span>
        </div>
    );
}

export default LabelValue;
