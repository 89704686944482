import { createSlice } from "@reduxjs/toolkit";
import {
  CreateAsset,
  deleteAsset,
  GetAssetDetails,
  GetAssetTypeList,
  GetByAssetID,
  UpdateAsset,
} from "../Thunks/AssetThunk";
import { AddAssetModel, AssetData, AssetDataModel, AssetTypeListModel } from "../../Model/AddAssetModel";

interface InitialState {
  isLoading: boolean;
  asset: AssetData;
  assetData: {};
  assetList: AssetData[];
  assetTypeList: AssetTypeListModel[] | null;
}

let initialState: InitialState = {
  isLoading: false,
  asset: { assetId: 0 },
  assetData: {},
  assetList: [{ assetId: 0 }],
  assetTypeList: null

};

const creteAssetSlice = createSlice({
  name: "Asset",
  initialState,
  reducers: {
    clearAsset : (state) => {
      state.asset = { assetId: 0 };
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(CreateAsset.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(CreateAsset.fulfilled, (state) => {
        state.asset = { assetId: 0 };
        state.isLoading = false;
      })
      .addCase(CreateAsset.rejected, (state) => {
        state.isLoading = false;
      });

    builder.addCase(GetByAssetID.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload && action.payload.status) {
        state.asset = action.payload.data;
      }
    });
    builder.addCase(GetByAssetID.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(GetByAssetID.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(GetAssetDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload && action.payload.status) {
        state.assetList = action.payload.data;
      } else {
        state.assetList = [{ assetId: 0 }];
      }
    });
    builder.addCase(GetAssetDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(GetAssetDetails.rejected, (state) => {
      state.isLoading = false;
    });

    builder
      .addCase(UpdateAsset.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(UpdateAsset.fulfilled, (state, action) => {
        state.asset = action.payload.data;
        state.isLoading = false;
      })
      .addCase(UpdateAsset.rejected, (state, action) => {
        state.isLoading = false;
      });

    builder.addCase(GetAssetTypeList.pending, (state, action) => {
      state.isLoading = true;
    })
      .addCase(GetAssetTypeList.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload && action.payload.status == true) {
          state.assetTypeList = action.payload.data
        }
        else {
          state.assetTypeList = null;
        };
      })
      .addCase(GetAssetTypeList.rejected, (state, action) => {
        state.isLoading = false;
      });

    builder.addCase(deleteAsset.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload && action.payload.status) {
        state.assetData = action.payload.data;
      }
    });
    builder.addCase(deleteAsset.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteAsset.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const {clearAsset} = creteAssetSlice.actions;

export const assetReducer = creteAssetSlice.reducer;
