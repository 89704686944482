import { Box, Grid } from "@mui/material";
import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";
import { layoutProps } from "../Model/LayoutModel";
import SideNavbar from "../Components/SideNav/SideNavbar";
import appBackground from "../Assets/images/hfm.png";

export default function PrivateRoute({ children }: layoutProps) {
  const isAuthenticated = Cookies.get("accessToken");
  return isAuthenticated ? (
    <Box
      sx={{
        position: "relative",
        // "&::before":{
        //     content: '""',
        //     position: "absolute",
        //     top: 0,
        //     left: 0,
        //     width: "100%",
        //     height: "100%",
        //     minHeight: "100vh",
        //     backgroundRepeat: "repeat",
        //     backgroundSize: "cover",
        //     backgroundAttachment: "fixed",
        //     backgroundImage: `url(${appBackground})`,
        //     zIndex: -1,
        //     opacity: 0.3,
        // }
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={2} md={1}>
          <Box>
            <SideNavbar />
          </Box>
        </Grid>
        <Grid item md={11} xs={10}>
          {children}
        </Grid>
      </Grid>
    </Box>
  ) : (
    <Navigate to="/" replace />
  );
}
