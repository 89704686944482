import { Box, Grid, Typography } from "@mui/material";
import Theme from "../../theme/theme";
import Divider from "@mui/material/Divider";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Avatar } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../Redux/Store";
import TextLabel from "../../Components/Label/TextLabel";
import { textStylesforNotificationbar } from "../../Components/NewStartbarStyles";
import RefreshIcon from "@mui/icons-material/Refresh";
import SnackbarComponent from "../../Components/SnackBar/Snackbar";
import { GetNotificationList } from "../../Redux/Thunks/NotificationThunk";
import {
  addNotification,
  removeNotification,
} from "../../Redux/Slices/NotificationSlice";
import { getStoreId } from "../../Utilities/Store";
import { ScrollBarStyle } from "../../Components/ScrollBarStyle";
import { TaskStatusNames } from "../../Utilities/TaskStatuses";
import { GetCancelRequestList } from "../../Redux/Thunks/CancelRequestedThunk";
import { GetJobInitialDetails, GetJobList } from "../../Redux/Thunks/JobsThunk";
import { GetAssetAttenderCount } from "../../Redux/Thunks/DashboardThunk";
import { SignalRNotificationType } from "../../Utilities/SignalRNotificationType";

const AlertStyles = {
  maxHeight: "365px",
  boxShadow: Theme.shadows[10],
  borderRadius: "1rem 3px 1rem 1rem",
  minWidth: "100%",
  marginRight: "2rem",
};

const NotificationBox = () => {
  const { connection } = useAppSelector((state) => state.signalR);
  const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [rotate, setRotate] = useState(false);
  const { notificationData } = useAppSelector((state) => state.notification);
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchAllNotification();
  }, []);

  const fetchAllNotification = async () => {
    setRotate(true);
    await dispatch(GetNotificationList());
  };

  const addToChannel = async (channelId: string) => {
    if (connection) {
      try {
        await connection.send("AddToGroup", channelId);
      } catch (error) {
        console.error("Hub error on AddToGroup:", channelId, error);
      }
    } else {
      console.error("No connection to hub");
    }
  };

  useEffect(() => {
    const AdminGroup = process.env.REACT_APP_SIGNALR_ADMIN_GROUP || "";
    const channel = process.env.REACT_APP_SIGNALR_CHANEL || "";

    const startConnection = async () => {
      if (connection?.state === "Disconnected") {
        try {
          await connection.start();
          const storeId = await getStoreId();
          await addToChannel(AdminGroup + "_" + storeId);
          connection.on(channel, async (data) => {
            console.log("Data",data);
            await dispatch(GetCancelRequestList());
            await dispatch(GetJobList());
            await dispatch(GetAssetAttenderCount());
            await dispatch(GetJobInitialDetails());
            data = JSON.parse(data);
            if (data.NotificationType === SignalRNotificationType.CancelRequested) {
              await dispatch(GetCancelRequestList());
            }
            const notificationId =
              notificationData.length > 0
                ? Math.max(
                    ...notificationData.map((data) => data.notificationId)
                  ) + 1
                : 0;
            const notification = {
              notificationId: Number(notificationId),
              notificationTypeId: data.NotificationType,
              notificationMessage: data.Message,
              notificationSubject: data.Title,
            };
            dispatch(addNotification(notification));
            if (
              data.NotificationType === SignalRNotificationType.ShiftStarted ||
              data.NotificationType === SignalRNotificationType.ShiftEnded 
            ) {
              await dispatch(GetJobInitialDetails());
            }
          });
        } catch (e) {
          console.log("Connection failed: ", e);
        }
      }
    };

    startConnection();
  }, [connection]);

  const handleNotificationDelete = async (id: number) => {
    await dispatch(removeNotification(id));
    setSnackBarMessage("Notification deleted successfully!!");
    setShowSnackBar(true);
  };
  return (
    <>
      {notificationData?.length > 0 ? (
        <>
          <Grid container mt={1} sx={AlertStyles}>
            <Grid
              item
              xs={12}
              m={1}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Grid item xs={12} m={0.5} justifyContent={"center"}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: Theme.palette.common.layout.buttonUpload,
                    display: "inline-block",
                  }}
                >
                  Notifications({notificationData.length}){" "}
                </Typography>
                <RefreshIcon
                  sx={{
                    display: "inline-block",
                    float: "right",
                    cursor: "pointer",
                    transition: "transform 1s ease",
                    transform: rotate ? "rotate(360deg)" : "rotate(0deg)",
                  }}
                  onClick={fetchAllNotification}
                  fontSize="small"
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Box sx={{ ...ScrollBarStyle, maxHeight: "285px" }}>
              {notificationData &&
                notificationData?.length > 0 &&
                notificationData?.map((data) => {
                  const isColor =
                    data.notificationTypeId === TaskStatusNames.Cancelled ||
                    data.notificationTypeId === TaskStatusNames.Rejected
                      ? Theme.palette.common.layout.AlertDanger
                      : Theme.palette.common.black;
                  return (
                    <React.Fragment key={data.notificationId}>
                      <Grid
                        item
                        xs={12}
                        display={"flex"}
                        justifyContent={"space-around"}
                        m={{ sm: 1, md: 1.3 }}
                      >
                        <Grid
                          item
                          mx={{ md: 0.3, lg: 0.3 }}
                          sm={0.5}
                          md={1.5}
                          lg={1}
                        >
                          <Avatar
                            sx={{
                              height: {
                                xs: 13,
                                sm: 20,
                                md: 25,
                                lg: 25,
                                xl: 35,
                              },
                              width: { xs: 13, sm: 20, md: 25, lg: 25, xl: 35 },
                            }}
                          ></Avatar>
                        </Grid>
                        <Grid
                          item
                          sm={10.5}
                          md={9.5}
                          lg={10}
                          mx={{ md: 1, lg: 1.3 }}
                        >
                          <TextLabel
                            label={data.notificationSubject ?? ""}
                            color={isColor}
                          />
                          <Typography
                            sx={textStylesforNotificationbar}
                            paddingRight={1}
                          >
                            {data.notificationMessage}
                          </Typography>
                        </Grid>
                        <Grid item sm={1}>
                          <CloseIcon
                            fontSize="small"
                            sx={{
                              color: Theme.palette.common.layout.commonBlue,
                              fontWeight: "bold",
                              cursor: "pointer",
                              float: "right",
                            }}
                            onClick={() =>
                              handleNotificationDelete(data.notificationId)
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    </React.Fragment>
                  );
                })}
            </Box>
          </Grid>
          <SnackbarComponent
            setShowSnackBar={setShowSnackBar}
            showSnackBar={showSnackBar}
            snackBarMessage={snackBarMessage}
          />
        </>
      ) : null}
    </>
  );
};

export default NotificationBox;
