import { Dialog, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { shiftIntervalDialogProps } from "../../Model/ShiftModels";
import { pb2r, dialogContainer } from "./ShiftStyles";
import TextLabel from "../../Components/Label/TextLabel";
import TextBox from "../../Components/TextBox/TextBox";
import Theme from "../../theme/theme";
import { Patientstyle } from "../Patient/PatientStyle";
import AutoComplete from "../../Components/AutoComplete/AutoComplete";
import { LocationButtonStyles } from "../../layouts/SideNavbarStyle";
import CustomButton from "../../Components/Button/Button";
import { AutoComplete as AutoCompleteModel } from "../../Model/AutoCompleteModel";

function ShiftIntervalForm(props: shiftIntervalDialogProps) {
    const { open, onClose, intervalTypes, onSave, shiftIntervalFormik, selectedIntervalType, setSelectedIntervalType } = props;

    useEffect(() => {
        if (
            isNaN(Number(shiftIntervalFormik.values.shiftIntervalType))
                ? 0
                : Number(shiftIntervalFormik.values.shiftIntervalType) > 0
        ) {
            let updatedIntervalType = isNaN(Number(shiftIntervalFormik.values.shiftIntervalType))
                ? 0
                : Number(shiftIntervalFormik.values.shiftIntervalType);
            let selectedIntervalType =
                intervalTypes.find((intervalType) => intervalType.id === updatedIntervalType) ?? null;
            setSelectedIntervalType(selectedIntervalType);
        }
    }, [shiftIntervalFormik.values.shiftIntervalType]);

    const handleIntervalTypeSelect = (id: number | string) => {
        let selectedIntervalType =
            intervalTypes.find((intervalType) => intervalType.id === id) ?? null;
        setSelectedIntervalType(selectedIntervalType);
        shiftIntervalFormik.setFieldValue("shiftIntervalType", id);
    };

    const handleAutoCompleteClear = () => {
        setSelectedIntervalType(null);
    };

    const handleSave = () => {
        setSelectedIntervalType(null);
        onSave();
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth>
            <Grid container sx={dialogContainer}>
                <Grid container sx={pb2r}>
                    <Grid item xs={6} md={4}>
                        <TextLabel label='Name' color={Theme.palette.common.black} required />
                        <TextBox
                            type='text'
                            formik={shiftIntervalFormik}
                            error={
                                Boolean(shiftIntervalFormik.touched.shiftIntervalName) &&
                                Boolean(shiftIntervalFormik.errors.shiftIntervalName)
                            }
                            fieldpropsName='shiftIntervalName'
                            helpertext={
                                shiftIntervalFormik.touched.shiftIntervalName
                                    ? String(shiftIntervalFormik.errors.shiftIntervalName)
                                    : ""
                            }
                            label='Enter interval name'
                            sx={Patientstyle.textboxStyles}
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <TextLabel
                            label='Interval duration (mins)'
                            color={Theme.palette.common.black}
                            required
                        />
                        <TextBox
                            type='number'
                            formik={shiftIntervalFormik}
                            error={
                                Boolean(shiftIntervalFormik.touched.shiftIntervalDuration) &&
                                Boolean(shiftIntervalFormik.errors.shiftIntervalDuration)
                            }
                            fieldpropsName='shiftIntervalDuration'
                            helpertext={
                                shiftIntervalFormik.touched.shiftIntervalDuration
                                    ? String(shiftIntervalFormik.errors.shiftIntervalDuration)
                                    : ""
                            }
                            label='Enter interval duration'
                            sx={Patientstyle.textboxStyles}
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <TextLabel
                            label='Interval remainder (mins)'
                            color={Theme.palette.common.black}
                            required
                        />
                        <TextBox
                            type='number'
                            formik={shiftIntervalFormik}
                            error={
                                Boolean(shiftIntervalFormik.touched.shiftIntervalRemainder) &&
                                Boolean(shiftIntervalFormik.errors.shiftIntervalRemainder)
                            }
                            fieldpropsName='shiftIntervalRemainder'
                            helpertext={
                                shiftIntervalFormik.touched.shiftIntervalRemainder
                                    ? String(shiftIntervalFormik.errors.shiftIntervalRemainder)
                                    : ""
                            }
                            label='Enter remainder duration'
                            sx={Patientstyle.textboxStyles}
                        />
                    </Grid>
                </Grid>
                <Grid container sx={pb2r} alignItems='self-end'>
                    <Grid item xs={6} md={4}>
                        <TextLabel
                            label='Interval type'
                            color={Theme.palette.common.black}
                            required
                        />
                        <AutoComplete
                            value={selectedIntervalType}
                            data={intervalTypes}
                            title='Interval Type'
                            onClick={handleIntervalTypeSelect}
                            onClear={handleAutoCompleteClear}
                            error={
                                shiftIntervalFormik.touched.shiftIntervalType &&
                                Boolean(shiftIntervalFormik.errors.shiftIntervalType)
                            }
                            helperText={
                                shiftIntervalFormik.touched.shiftIntervalType
                                    ? shiftIntervalFormik.errors.shiftIntervalType
                                    : ""
                            }
                        />
                    </Grid>
                    <Grid item md={6}></Grid>
                    <Grid item xs={6} md={2}>
                        <CustomButton
                            onClick={handleSave}
                            variant='outlined'
                            sx={LocationButtonStyles}
                        >
                            {"Save"}
                        </CustomButton>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    );
}

export default ShiftIntervalForm;
