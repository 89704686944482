import { configureStore } from "@reduxjs/toolkit";
import CombineReducer from './RootReducer';
import { TypedUseSelectorHook, useDispatch } from "react-redux";
import { useSelector } from "react-redux";

export const RESET_STATE = "RESET_STATE";

export const resetState = () => ({
    type: RESET_STATE,
});

const rootReducer = (state: any, action: any) => {
    if (action.type === RESET_STATE) {
        state = undefined;
    }

    return CombineReducer(state, action);
};


const store = configureStore({
    reducer: rootReducer
})


// export type AppStore = typeof store
// export type RootState = ReturnType<AppStore['getState']>
// export type AppDispatch = AppStore['dispatch']


// export type RootState = ReturnType<typeof rootReducer>
export type RootState = ReturnType<typeof store.getState>
export default store;
export const useAppDispatch = () => useDispatch<typeof store.dispatch>();
export const useAppSelector: TypedUseSelectorHook<ReturnType<typeof store.getState>> = useSelector;



// export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
// export const useAppSelector = useSelector.withTypes<RootState>();