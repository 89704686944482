import { TaskDetailModel } from "../Model/ReportModel";

export class TaskStatusNames {
    static readonly Assigned = 1;
    static readonly ProgressAssigned = 0;
    static readonly Scheduled = 2;
    static readonly Accepted = 3;
    static readonly Rejected = 4;
    static readonly CancelRequested = 5;
    static readonly Completed = 6;
    static readonly Cancelled = 7;

    private static readonly taskStatusIds = [
        TaskStatusNames.Assigned,
        TaskStatusNames.Scheduled,
        TaskStatusNames.Accepted,
        TaskStatusNames.Rejected,
        TaskStatusNames.CancelRequested,
        TaskStatusNames.Completed,
        TaskStatusNames.Cancelled
    ]

    static getStatusName(value: number): string {
        switch (value) {
            case TaskStatusNames.Assigned:
                return 'Assigned';
            case TaskStatusNames.Scheduled:
                return 'Scheduled';
            case TaskStatusNames.Accepted:
                return 'Accepted';
            case TaskStatusNames.Rejected:
                return 'Rejected';
            case TaskStatusNames.CancelRequested:
                return 'CancelRequested';
            case TaskStatusNames.Completed:
                return 'Completed';
            case TaskStatusNames.Cancelled:
                return 'Cancelled';
            default:
                return 'error';
        }
    }

    static readonly taskStatus = this.taskStatusIds.map(statusId => ({
        statusId,
        statusName: TaskStatusNames.getStatusName(statusId)
    }));


    static getProgressBarDetails = (data: TaskDetailModel[]) => {
        const currentStatus = data[data.length - 1].currentStatus;

        let stepLabels = data.map((task) => {
            return task.statusName
        })

        if (currentStatus === TaskStatusNames.Assigned) {
            stepLabels.push(TaskStatusNames.getStatusName(TaskStatusNames.Accepted));
            stepLabels.push(TaskStatusNames.getStatusName(TaskStatusNames.Completed));
            stepLabels.push(TaskStatusNames.getStatusName(TaskStatusNames.CancelRequested));
            stepLabels.push(TaskStatusNames.getStatusName(TaskStatusNames.Cancelled));
        }
        if (currentStatus === TaskStatusNames.Scheduled) {
            stepLabels.push(TaskStatusNames.getStatusName(TaskStatusNames.Assigned));
            stepLabels.push(TaskStatusNames.getStatusName(TaskStatusNames.Accepted));
            stepLabels.push(TaskStatusNames.getStatusName(TaskStatusNames.Completed));
            stepLabels.push(TaskStatusNames.getStatusName(TaskStatusNames.CancelRequested));
            stepLabels.push(TaskStatusNames.getStatusName(TaskStatusNames.Cancelled));
        }
        if (currentStatus === TaskStatusNames.Accepted) {
            stepLabels.push(TaskStatusNames.getStatusName(TaskStatusNames.Completed));
        }
        if (currentStatus === TaskStatusNames.CancelRequested) {
            stepLabels.push(TaskStatusNames.getStatusName(TaskStatusNames.Cancelled));

        }
        return stepLabels;
    }

}

