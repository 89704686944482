import { useState, useEffect } from 'react';
import ShiftForm from './ShiftForm';
import ShiftList from './ShiftList';
import { Box, Container, Grid, IconButton, Tooltip } from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Title from '../../Components/Title/Title';
import { IntervalFormSubmitModel, shiftAssignmentFormModel, shiftAssignmentInitialDataModel, shiftAssignmentShiftInitial, shiftFormModel, ShiftFormSubmitModel, shiftIntervalFormModel, ShiftIntervalListModel, ShiftListModel, shiftTableDataModel, UserShiftAssignmentParamsModel } from '../../Model/ShiftModels';
import { convertUTCToLocal, getDuration, numberToDateTime, tomorrowDate } from '../../Utilities/Common';
import { getStoreId } from '../../Utilities/Store';
import { useAppDispatch, useAppSelector } from '../../Redux/Store';
import { createShift, updateShift, getShift, getShiftList, deleteShift, getShiftAssignmentInitialData, assignUserShift, getIntervalTypes } from '../../Redux/Thunks/ShiftThunk';
import { nanoid, unwrapResult } from '@reduxjs/toolkit';
import SnackbarComponent from '../../Components/SnackBar/Snackbar';
import DialogBox from '../../Components/DialogBox/DialogBox';
import ShiftAllocationForm from './ShiftAllocation/ShiftAllocationForm';
import { SetBackgroundStyle } from '../../layouts/SideNavbarStyle';
import AddIcon from "@mui/icons-material/Add";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import Theme from '../../theme/theme';
import { useNavigate } from 'react-router-dom';
import { AutoComplete as AutoCompleteModel } from '../../Model/AutoCompleteModel';
import UnAssignedUsersDialog from './ShiftAllocation/UnAssignedUsersDialog';
import Loader from '../../Components/Loader/Loader';
import dayjs from 'dayjs';

const Shift = () => {

    const { isLoading, shiftAssignmentInitialData } = useAppSelector(state => state.shift);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [intervals, setIntervals] = useState<shiftIntervalFormModel[]>([]);
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [shiftListData, setShiftListData] = useState<shiftTableDataModel[]>([]);
    const [openShiftIntervalFormDialog, setOpenShiftIntervalFormDialog] = useState(false);
    const [openUserSelectionDialog, setOpenUserSelectionDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [deleteShiftId, setDeleteShiftId] = useState(0);
    const [assignShift, setAssignShift] = useState(true);
    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
    const [intervalTypes, setIntervalTypes] = useState<AutoCompleteModel[]>([]);
    const [selectedShift, setSelectedShift] = useState<shiftAssignmentShiftInitial | null>(null);
    const [selectedLocation, setSelectedLocation] = useState<AutoCompleteModel | null>(null);
    const [selectedUserNames, setSelectedUserNames] = useState<string[]>([]);
    const [unassignedUser, setUnassignedUser] = useState({
        openDialog: false,
        users: []
    });
    //Formik Functions
    const shiftInitialValues: shiftFormModel = {
        shiftId: 0,
        shiftName: "",
        startTime: numberToDateTime(9),
        endTime: numberToDateTime(18)
    };

    const shiftIntervalInitialValues: shiftIntervalFormModel = {
        Id: "",
        shiftIntervalId: 0,
        shiftIntervalName: "",
        shiftIntervalDuration: "",
        shiftIntervalRemainder: "",
        shiftIntervalType: ""
    };

    const shiftAssignment: shiftAssignmentFormModel = {
        shiftId: "",
        locationId: "",
        fromDate: new Date(),
        toDate: tomorrowDate()
    }

    const shiftFormik = useFormik({
        initialValues: shiftInitialValues,
        validationSchema: yup.object().shape({
            shiftName: yup.string().required("Required"),
        }),
        onSubmit: async (values) => {
            let intervalParams: IntervalFormSubmitModel[] = intervals.map((interval) => {
                return {
                    shiftIntervalId: interval.shiftIntervalId,
                    intervalName: interval.shiftIntervalName,
                    intervalType: isNaN(Number(interval.shiftIntervalType)) ? 0 : Number(interval.shiftIntervalType),
                    intervalDuration: isNaN(Number(interval.shiftIntervalDuration)) ? 0 : Number(interval.shiftIntervalDuration),
                    reminderMinutes: isNaN(Number(interval.shiftIntervalRemainder)) ? 0 : Number(interval.shiftIntervalRemainder)
                }
            })

            let params: ShiftFormSubmitModel = {
                ...values,
                storeId: await getStoreId(),
                intervals: intervalParams
            }
            let result;
            if (values.shiftId > 0) {
                result = unwrapResult(await dispatch(updateShift(params)));
            } else {
                result = unwrapResult(await dispatch(createShift(params)));
            }
            if (result === undefined) {
                setSnackBarMessage("Something went wrong!");
                setShowSnackBar(true);
                return
            }
            if (result.status && result.data && result.statusCode === 200) {
                shiftFormik.resetForm();
                setSnackBarMessage(result.message);
                setShowSnackBar(true);
                setIntervals([]);
                await getShifts();
            } else {
                setSnackBarMessage(result.data);
                setShowSnackBar(true);
            }
            await getShiftAssignmentInitial();
        }
    })

    const shiftIntervalFormik = useFormik({
        initialValues: shiftIntervalInitialValues,
        validationSchema: yup.object().shape({
            shiftIntervalName: yup.string().required("Required"),
            shiftIntervalDuration: yup.string().required("Required"),
            shiftIntervalRemainder: yup.string().required("Required"),
            shiftIntervalType: yup.string().required("Required"),
        }),
        onSubmit: (values) => {
            console.clear()
            console.log("values::", values)
            let filteredIntervals: shiftIntervalFormModel[] = [];
            if (values.shiftIntervalId > 0) {
                filteredIntervals = intervals.filter((interval) => (
                    interval.shiftIntervalId !== values.shiftIntervalId
                ));
            } else {
                filteredIntervals = intervals.filter((interval) => (
                    interval.Id !== values.Id
                ));
            }

            let filteredIntervalById = intervals.find((interval) => (interval.Id === values.Id)) ?? null;

            if (values.shiftIntervalId > 0 || filteredIntervalById !== null) {
                setIntervals([...filteredIntervals, values]);
            } else {
                setIntervals([...intervals, values]);
            }
            setOpenShiftIntervalFormDialog(false);
            shiftIntervalFormik.resetForm();
        }
    })

    const shiftAssignmentFormik = useFormik({
        initialValues: shiftAssignment,
        validationSchema: yup.object().shape({
            shiftId: yup.string().required("Required"),
            locationId: yup.string().required("Required"),
        }),
        onSubmit: async (values) => {
            if (selectedUsers.length < 1) {
                setSnackBarMessage("Please select atleast one user");
                setShowSnackBar(true);
                return;
            }
            let params: UserShiftAssignmentParamsModel = {
                fromDate: values.fromDate,
                toDate: values.toDate,
                shiftId: isNaN(Number(values.shiftId)) ? 0 : Number(values.shiftId),
                locationId: isNaN(Number(values.locationId)) ? 0 : Number(values.locationId),
                userIds: selectedUsers,
                storeId: await getStoreId()
            }

            const result = unwrapResult(await dispatch(assignUserShift(params)));
            if (result === undefined) {
                setSnackBarMessage("Something went wrong!");
                setShowSnackBar(true);
                return;
            }
            if (result.status && result.data) {
                if (result.data < 1) {
                    shiftAssignmentFormik.resetForm();
                    setSnackBarMessage(result.message);
                    setShowSnackBar(true);
                    setSelectedUsers([]);
                    setSelectedShift(null);
                    setSelectedLocation(null);
                    setSelectedUserNames([]);
                } else {
                    setUnassignedUser({
                        openDialog: true,
                        users: result.data
                    })
                }
            } else {
                setSnackBarMessage(result.data);
                setShowSnackBar(true);
            }
        }
    });

    useEffect(() => {
        getShifts();
        getShiftIntervalTypes();
        getShiftAssignmentInitial();
    }, [])

    const getShiftAssignmentInitial = async (fromDate?: Date | null, toDate?: Date | null) => {
        let params: shiftAssignmentInitialDataModel = {
            storeId: await getStoreId(),
            fromDate: fromDate ?? shiftAssignmentFormik.values.fromDate,
            toDate: toDate ?? shiftAssignmentFormik.values.toDate
        }
        await dispatch(getShiftAssignmentInitialData(params));
    }


    const getShifts = async () => {
        const response = unwrapResult(await dispatch(getShiftList()));
        if (response.data && response.data.length > 0 && response.status) {
            let tableData: shiftTableDataModel[] = response.data.map((shift: ShiftListModel) => {
                return {
                    shiftId: shift.shiftId,
                    shiftName: shift.shiftName,
                    startTime: convertUTCToLocal(shift.startTime, "hh:mm A"),
                    endTime: convertUTCToLocal(shift.endTime, "hh:mm A"),
                    duration: getDuration(shift.startTime, shift.endTime)
                }
            })
            setShiftListData(tableData);
        } else {
            setAssignShift(false);
            setShiftListData([]);
        }
    }

    const getShiftIntervalTypes = async () => {
        let result = unwrapResult(await dispatch(getIntervalTypes()));
        if (result.data) {
            setIntervalTypes(result.data);
        }
    }

    const handleIntervalRemove = (id: string) => {
        let filteredIntervals = intervals.filter((item) => item.Id !== id);
        setIntervals(filteredIntervals);
    }

    const handleIntervalEdit = (interval: shiftIntervalFormModel) => {
        shiftIntervalFormik.setFieldValue("Id", interval.Id);
        shiftIntervalFormik.setFieldValue("shiftIntervalId", interval.shiftIntervalId);
        shiftIntervalFormik.setFieldValue("shiftIntervalName", interval.shiftIntervalName);
        shiftIntervalFormik.setFieldValue("shiftIntervalDuration", interval.shiftIntervalDuration);
        shiftIntervalFormik.setFieldValue("shiftIntervalRemainder", interval.shiftIntervalRemainder);
        shiftIntervalFormik.setFieldValue("shiftIntervalType", interval.shiftIntervalType);
        setOpenShiftIntervalFormDialog(true);
    }

    const onEdit = async (props: shiftTableDataModel) => {
        const result = unwrapResult(await dispatch(getShift(props.shiftId)));
        if (result === undefined) {
            setSnackBarMessage("Something went wrong!");
            setShowSnackBar(true);
            return
        }
        if (result.status && result.data) {
            shiftFormik.setFieldValue("shiftId", result.data.shiftId);
            shiftFormik.setFieldValue("shiftName", result.data.shiftName);
            shiftFormik.setFieldValue("startTime", convertUTCToLocal(result.data.startTime));
            shiftFormik.setFieldValue("endTime", convertUTCToLocal(result.data.endTime));
            if (result.data.intervals.length > 0) {
                let intervals: shiftIntervalFormModel[] = result.data.intervals.map((interval: ShiftIntervalListModel) => {
                    return {
                        Id: nanoid(),
                        shiftIntervalId: interval.shiftIntervalId,
                        shiftIntervalName: interval.intervalName,
                        shiftIntervalDuration: interval.intervalDuration.toString(),
                        shiftIntervalRemainder: interval.reminderMinutes.toString(),
                        shiftIntervalType: interval.intervalType.toString()
                    }
                })
                setIntervals(intervals);
            } else {
                setIntervals([]);
            }
        } else {
            setSnackBarMessage(result.data);
            setShowSnackBar(true);
        }
        setAssignShift(false);
    }

    const handleClear = () => {
        shiftFormik.resetForm();
        shiftIntervalFormik.resetForm();
        setIntervals([]);
    }

    const onDelete = (props: shiftTableDataModel) => {
        setShowDeleteDialog(true);
        setDeleteShiftId(props.shiftId);
    }

    const handleDelete = async () => {
        const result = unwrapResult(await dispatch(deleteShift(deleteShiftId)));
        if (result === undefined) {
            setSnackBarMessage("Something went wrong!");
            setShowSnackBar(true);
            return
        }
        if (result.status && result.data && result.statusCode === 200) {
            setShowDeleteDialog(false);
            setDeleteShiftId(0);
            setSnackBarMessage("Shift deleted successfully!");
            setShowSnackBar(true);
            await getShifts();
            await getShiftAssignmentInitial();
        } else {
            setSnackBarMessage(result.data);
            setShowSnackBar(true);
        }
    }

    const onViewShift = (props: shiftTableDataModel) => {
        navigate(`/shift/view?shiftId=${props.shiftId}`);
    }

    const onUnassignedDialogClose = () => {
        setUnassignedUser({ openDialog: false, users: [] })
    }

    return (
        <Box pr={2} pt={1}>
            <Grid spacing={1.5} container padding={{ md: 1.4, sm: 2 }}>
                <Grid item md={12} xs={12}>
                    <Grid container sx={SetBackgroundStyle}>
                        <Grid item xs={11}>
                            <Title
                                title={assignShift ? "Shift Allocation" : (shiftFormik.values.shiftId > 0 ? "Edit Shift" : "Add Shift")}
                                sx={{ color: "white" }}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            {
                                shiftListData.length > 0 ?
                                    <Tooltip title={assignShift ? "Create Shift" : "Assign Shifts"} placement="bottom">
                                        <IconButton sx={{ backgroundColor: Theme.palette.common.white, ":hover": { backgroundColor: Theme.palette.common.white } }} onClick={() => setAssignShift(!assignShift)}>
                                            {assignShift ? <AddIcon /> : <SwapHorizIcon />}
                                        </IconButton>
                                    </Tooltip> : null
                            }
                        </Grid>
                        {assignShift ? <ShiftAllocationForm
                            shiftAssignmentFormik={shiftAssignmentFormik}
                            shiftAssignmentInitialData={shiftAssignmentInitialData}
                            openDialog={openUserSelectionDialog}
                            setOpenDialog={setOpenUserSelectionDialog}
                            selectedUsers={selectedUsers}
                            setSelectedUsers={setSelectedUsers}
                            selectedLocation={selectedLocation}
                            setSelectedLocation={setSelectedLocation}
                            selectedUserNames={selectedUserNames}
                            setSelectedUserNames={setSelectedUserNames}
                            selectedShift={selectedShift}
                            setSelectedShift={setSelectedShift}
                            getShiftAssignmentInitial={getShiftAssignmentInitial} />
                            : <ShiftForm
                                shiftFormik={shiftFormik}
                                intervals={intervals}
                                shiftIntervalFormik={shiftIntervalFormik}
                                handleIntervalRemove={handleIntervalRemove}
                                openDialog={openShiftIntervalFormDialog}
                                setOpenDialog={setOpenShiftIntervalFormDialog}
                                handleClear={handleClear}
                                handleIntervalEdit={handleIntervalEdit}
                                intervalTypes={intervalTypes}
                                setIntervalTypes={setIntervalTypes} />
                        }
                    </Grid>
                    <Grid item md={12} xs={11} mt={2} padding={0.5}></Grid>
                    <Title title="Shifts" />
                    <ShiftList onView={onViewShift} shifts={shiftListData} onEdit={onEdit} onDelete={onDelete} />
                </Grid>
            </Grid>
            <SnackbarComponent
                setShowSnackBar={setShowSnackBar}
                showSnackBar={showSnackBar}
                snackBarMessage={snackBarMessage}
            />
            <DialogBox
                message={"Do you want to delete the shift?"}
                handleAccept={() => handleDelete()}
                handleCancel={() => setShowDeleteDialog(false)}
                open={showDeleteDialog}
            />
            <UnAssignedUsersDialog onClose={onUnassignedDialogClose} open={unassignedUser.openDialog} users={unassignedUser.users} />
            <Loader openModal={isLoading} />
        </Box >
    )
}

export default Shift;
