import {
    Autocomplete,
    TextField,
    Grid,
    styled,
    lighten,
} from "@mui/material";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import {  GroupedByBlockAndFloor, LocationAutoCompleteProps } from "../../Model/AutoCompleteModel";
import { nanoid } from "@reduxjs/toolkit";

const GroupHeader = styled("div")(({ theme }) => ({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    color: theme.palette.common.white,
    backgroundColor: lighten(theme.palette.primary.dark, 0.5),
}));

const GroupItems = styled("ul")({
    padding: 0,
});

function convertData(grouped: GroupedByBlockAndFloor) {
    const result: any = [];
    for (const [blockName, floors] of Object.entries(grouped)) {
        for (const [floorName, locations] of Object.entries(floors)) {
            locations.forEach((location: any) => {
                result.push({
                    key: nanoid(),
                    title: `${blockName}-${floorName}`,
                    value: location.locationName,
                    id: location.locationId,
                    label: location.locationName,
                });
            });
        }
    }
    return result;
}
function LocationAutoComplete(props: LocationAutoCompleteProps) {
    const {
        data,
        title,
        onClick,
        value,
        error,
        required,
        helperText,
        onClear,
        sx,
    } = props;
    const [groupedByBlockAndFloor, setGroupedByBlockAndFloor] = useState<any[]>([]);
    const groupByBlockAndFloor = useCallback(async () => {
        const grouped: GroupedByBlockAndFloor = {} as GroupedByBlockAndFloor;
        for (const asset of data) {
            const { blockName, floorName } = asset;
    
            if (blockName && floorName) {
                if (!grouped[blockName]) {
                    grouped[blockName] = {};
                }
    
                if (!grouped[blockName][floorName]) {
                    grouped[blockName][floorName] = [];
                }
    
                grouped[blockName][floorName].push(asset);
            }
        }
    
        const result = await convertData(grouped);
        setGroupedByBlockAndFloor(result)
    }, [data]);
    useEffect(() => {
        groupByBlockAndFloor();
    }, [groupByBlockAndFloor]);

    const options = useMemo(
        () =>
            groupedByBlockAndFloor.sort((a: any, b: any) =>
                -b.title.localeCompare(a.title)
            ),
        [groupedByBlockAndFloor]
    );
    return (
        <div>
            <Autocomplete
                value={value}
                disablePortal
                id="combo-box-demo"
                options={options}
                groupBy={(option) => option.title}
                isOptionEqualToValue={(option, value) => {
                    if (!option || !value) return false;
                    return option.id === value.id;
                }}
                getOptionLabel={(option) => {
                    return option.label;
                }}
                fullWidth
                className={!value && error ? "shake" : ""}
                onChange={(event, selectedOption) => {
                    if (selectedOption) {
                        onClick(selectedOption.id);
                    }
                }}
                onInputChange={(event, inputValue, reason) => {
                    if (reason === "clear") {
                        if (onClear) {
                            onClear();
                        }
                    }
                }}
                renderGroup={(params) => (
                    <li key={params.key}>
                        <GroupHeader>{params.group}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                    </li>
                )}
                getOptionDisabled={(option) => option.label === "No option"}
                renderOption={(props, option: any) => (
                    <li {...props} style={{ color: "black" }}>
                        {option.label}
                    </li>
                )}
                ListboxProps={{
                    sx: {
                        maxHeight: "150px",
                        zIndex: 2,
                    },
                }}
                renderInput={(params) => (
                    <Grid container alignItems="center">
                        <TextField
                            {...params}
                            required={required}
                            error={!value && error ? error : false}
                            helperText={helperText}
                            className={!value && error ? "shake" : ""}
                            sx={{
                                "& .MuiInputLabel-root": {
                                    color: "black",
                                    fontSize: "small !important",
                                    backgroundColor: "transparent",
                                },
                                "& .MuiOutlinedInput-root": {
                                    backgroundColor: "white",
                                    borderRadius: "10px",
                                    "& fieldset": {
                                        color: "black",
                                    },
                                },
                                "& .MuiInputBase-input": {
                                    color: "black",
                                    minHeight: "unset",
                                    padding: "2px !important",
                                    fontSize: "small",
                                },
                                ...sx,
                            }}
                            placeholder={title}
                        />
                    </Grid>
                )}
            />
        </div>
    );
}

export default LocationAutoComplete;
