import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getHeaderToken } from "../../Utilities/header";
import { ApiResponse } from "../../Utilities/ApiResponse";
import { AddAssetModel } from "../../Model/AddAssetModel";
import { getStoreId } from "../../Utilities/Store";
const url = process.env.REACT_APP_BASEURL;

export const GetAssetAttenderCount = createAsyncThunk(
    "Dashboard/getCount",
    async () => {
        try {
            let params = {
                storeId: await getStoreId(),
                currentDate: new Date()
            }
            const URL = process.env.REACT_APP_BASEURL + `/api/Dashboard/dashboard-details`;
            const response = await axios.post(URL, params, await getHeaderToken());
            return response.data;
        } catch (e: any) {
            return e.response;
        }
    }
);