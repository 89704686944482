import { Badge, Box, Dialog, Grid } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../Redux/Store';
import { GetCancelRequestList } from '../../Redux/Thunks/CancelRequestedThunk';
import { useEffect, useState } from 'react';
import { CancelRequestTask } from '../../Model/CancelRequestModel';
import { GetJobInitialDetails } from '../../Redux/Thunks/JobsThunk';
import JobAlertBox from './JobAlertBox';
import Title from '../../Components/Title/Title';
import Theme from '../../theme/theme';
import CloseIcon from '@mui/icons-material/Close';
import Loader from '../../Components/Loader/Loader';
import CampaignIcon from '@mui/icons-material/Campaign';
const AcceptedDialogBox = () => {
    const [isDialogBoxOpen, setIsDialogBoxOpen] = useState<boolean>(false);
    const [isComponentComplete, setIsComponentComplete] = useState<boolean>(false);
    const { CancelRequestedList } = useAppSelector(state => state.cancelRequestTask);
    const dispatch = useAppDispatch();

    const fetchData = async () => {
        await dispatch(GetCancelRequestList());
        await dispatch(GetJobInitialDetails());
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (CancelRequestedList !== null && CancelRequestedList.length > 0) {
            setIsDialogBoxOpen(true);
            setIsComponentComplete(true);
        } else {
            setIsDialogBoxOpen(false);
            setIsComponentComplete(false);
        }
    }, [CancelRequestedList]);

    const onClosed = () => {
        setIsDialogBoxOpen(false);
    };

    return (
        <>
            <Dialog open={isDialogBoxOpen} onClose={onClosed} PaperProps={{ sx: { borderRadius: '13px', padding: '20px' } }}>
                <Grid container display={'flex'} justifyContent={'space-between'}>
                    <Grid item>
                        <Title title='JOB ALERT' sx={{ color: Theme.palette.primary.dark }} />
                    </Grid>
                    <Grid item>
                        <CloseIcon fontSize='large' sx={{ cursor: 'pointer' }} onClick={onClosed} />
                    </Grid>
                </Grid>
                {CancelRequestedList !== null && CancelRequestedList.length > 0 && CancelRequestedList.map((data: CancelRequestTask) => (
                    <JobAlertBox key={data.taskId} data={data} isComplete={isComponentComplete} />
                ))}
            </Dialog>
            {
                CancelRequestedList !== null && CancelRequestedList.length > 0 ? <Box onClick={() => setIsDialogBoxOpen(true)} sx={{ cursor: 'pointer', right: 26.6, bottom: 26, position: 'fixed', backgroundColor: "red", padding: 1.5, borderRadius: '50%' }}>
                    <Badge color="primary" badgeContent={CancelRequestedList.length}>
                        <CampaignIcon color="primary" sx={{ height: '35px', width: "35px" }} />
                    </Badge>
                </Box> : null
            }
        </>
    );
};

export default AcceptedDialogBox;
