import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { AutoCompleteProps } from "../../Model/AutoCompleteModel";
import { ScrollBarStyle } from "../../Components/ScrollBarStyle";
function AutoComplete(props: AutoCompleteProps) {

  const {
    data,
    title,
    onClick,
    value,
    error,
    required,
    helperText,
    onClear,
    color,
    sx,
    onAutoCompleteClick
  } = props;

  return (
    <div>
      <Autocomplete
        value={value}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        disablePortal
        id="combo-box-demo"
        options={data}
        fullWidth
        className={error && !value ? "shake" : ""}
        onChange={(event, selectedOption) => {
          if (selectedOption) {
            onClick(selectedOption.id);
          }
        }}
        onInputChange={(event, inputValue, reason) => {
          if (reason === "clear") {
            if (onClear) {
              onClear();
            }
          }
        }}
        getOptionDisabled={(option) => option.label === "No option"}
        renderOption={(props, option: any) => (
          <li {...props} style={{ color: "black" }}>
            {option.label}
          </li>
        )}
        ListboxProps={{
          sx: {
            maxHeight: '150px',
            zIndex: 2
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            error={error}
            helperText={helperText}
            className={error && !value ? "shake" : ""}
            sx={{
              "& .MuiInputLabel-root": {
                color: "black",
                fontSize: "small !important",
                backgroundColor: "transparent",
              },
              "& .MuiOutlinedInput-root": {
                backgroundColor: "white",
                borderRadius: "10px",
                "& fieldset": {
                  color: "black",
                },
              },
              "& .MuiInputBase-input": {
                color: "black",
                minHeight: "unset",
                padding: "2px !important",
                fontSize: "small",
              },
              ...sx,
            }}
            placeholder={title}
            onClick={() => {
              if (onAutoCompleteClick) {
                onAutoCompleteClick();
              }
            }}
          />
        )}
      />
    </div>
  );
}

export default AutoComplete;
