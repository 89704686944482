import { Grid, Container, Box, Typography, Tooltip } from "@mui/material";
import MeetingRoomOutlinedIcon from '@mui/icons-material/MeetingRoomOutlined';
import {
  startbarContainerStyles,
  itemContainerStyles,
  mobileDisplayStyles,
  iconBackgroundStyles,
  textStylesforStartbar,
  TypographynumericStyles,
} from "../../Components/NewStartbarStyles";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined";
import { rowDataArray } from "../../Components/NewStartbarData";
import { DashboardCountprops, FloorDetail } from "../../Model/DashboardModel";
import AccessibleSharpIcon from '@mui/icons-material/AccessibleSharp';

import Theme from "../../theme/theme";

export const FloorDetails = (props: DashboardCountprops) => {
  const { floorDetails } = props;
  return (
    <Container sx={{ ...startbarContainerStyles }} disableGutters>
      <Grid item container sx={itemContainerStyles} xs={1}>
        <Grid item xs={12}>
          <MeetingRoomOutlinedIcon sx={{ color: Theme.palette.common.white, }} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ whiteSpace: "nowrap", color: Theme.palette.common.white }}>{floorDetails.floorName}</Typography>
        </Grid>
      </Grid>
      <Grid item container sx={itemContainerStyles} xs={4}>
        <Grid item xs={12} md={6} sx={mobileDisplayStyles.mainContainer}>
          <PeopleAltOutlinedIcon sx={iconBackgroundStyles} />
        </Grid>
        <Grid item container xs={12} md={6}>
          <Grid item xs={12} sx={mobileDisplayStyles.titleContainer}>
            <Typography sx={textStylesforStartbar}>Total Attenders</Typography>
          </Grid>
          <Grid item xs={12} sx={mobileDisplayStyles.valueContainer}>
            <Typography sx={TypographynumericStyles}>{floorDetails.floorDetails.totalAttenders}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container sx={itemContainerStyles} xs={4}>
        <Grid item xs={12} md={6} sx={mobileDisplayStyles.mainContainer}>
          <HowToRegOutlinedIcon sx={iconBackgroundStyles} />
        </Grid>
        <Grid item container xs={12} md={6}>
          <Grid item xs={12} sx={mobileDisplayStyles.titleContainer}>
            <Typography sx={textStylesforStartbar}>
              Available Attenders
            </Typography>
          </Grid>
          <Grid item xs={12} sx={mobileDisplayStyles.valueContainer}>
            <Typography sx={TypographynumericStyles}>{floorDetails.floorDetails.availableAttenders}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container sx={itemContainerStyles} xs={3}>
        <Grid item xs={12} md={6} sx={mobileDisplayStyles.mainContainer}>
          <AccessibleSharpIcon sx={iconBackgroundStyles} />
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={6}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Grid item xs={12} sx={mobileDisplayStyles.titleContainer}>
            <Tooltip title={"Total Asset is " + floorDetails.floorDetails.assetDetails.totalAsset}>
              <Typography sx={textStylesforStartbar}>Available Assets</Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sx={mobileDisplayStyles.valueContainer}>
            <Typography sx={TypographynumericStyles}>{floorDetails.floorDetails.assetDetails.availableAsset}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
