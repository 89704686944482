import { Badge, Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import Title from '../../Components/Title/Title';
import TextLabel from '../../Components/Label/TextLabel';
import JobAlertText from './JobAlertText';
import AutoComplete from '../../Components/AutoComplete/AutoComplete';
import { CancelRequestTask, UpdateCancelRequest, UpdateTaskParams } from '../../Model/CancelRequestModel';
import Theme from '../../theme/theme';
import { assetStyles } from '../Asset/AssetStyles';
import CustomButton from '../../Components/Button/Button';
import { AutoComplete as AutoCompleteprops } from '../../Model/AutoCompleteModel';
import { UserDataSourceModel } from '../../Model/UserModel';
import { GetJobInitialDetails, GetJobList, UpdateJobStatus } from '../../Redux/Thunks/JobsThunk';
import { useAppDispatch, useAppSelector } from '../../Redux/Store';
import { GetCancelRequestList, UpdateCancelRequestList, UpdateTaskReassign } from '../../Redux/Thunks/CancelRequestedThunk';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Loader from '../../Components/Loader/Loader';
import _ from 'lodash';
import SnackbarComponent from '../../Components/SnackBar/Snackbar';

const JobAlertBox = (props: any) => {
    const { data, isComplete } = props;
    const { JobInitial, isLoading } = useAppSelector(state => state.job);
    const [attenderData, setAttenderData] = useState<AutoCompleteprops[]>([]);
    const [selectedAttender, setSelectedAttender] = useState<AutoCompleteprops | null>(null);
    const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const dispatch = useAppDispatch();

    async function getData() {
        await dispatch(GetCancelRequestList());
    }

    useEffect(() => {
        dispatch(GetJobInitialDetails());
    }, []);

    useEffect(() => {
        if (JobInitial != null) {
            let newAttenderData: AutoCompleteprops[] = JobInitial.users && JobInitial.users.map((data: UserDataSourceModel) => {
                return { label: data.displayName, id: data.userId };
            });
            setAttenderData(newAttenderData);
        }
    }, [JobInitial]);

    const formikCancelRequest = useFormik({
        initialValues: {
            attender: '',
        },
        validationSchema: yup.object({
            attender: yup.string().required('Required'),
        }),
        onSubmit: async (values, { resetForm }) => {
            const params: any = {
                taskId: data.taskId,
                statusId: 1,
                assigneeId: values.attender
            }
            let res = await dispatch(UpdateTaskReassign(params));
            if (res && res.meta.requestStatus === "rejected") {
                setSnackBarMessage("Connection Refused");
                setShowSnackBar(true);
                return;
            }
            if (res && res.payload.status == true || res.payload.status == 200) {
                setSnackBarMessage(`Job assigned successfully!!`);
                setShowSnackBar(true);
                await dispatch(GetCancelRequestList());
                resetForm();
                await dispatch(GetJobList());
            } else {
                setSnackBarMessage(res.payload.data || res.payload.statusText);
                setShowSnackBar(true);
            }

        },
    });

    const changeAttenderValue = (id: number | string) => {
        formikCancelRequest.setFieldValue('attender', id);
        let currentSelectedAttender: any = attenderData.length > 0 ? attenderData.map((data: AutoCompleteprops) => {
            if (id == data.id) {
                return data.label;
            }
        }).filter((data: any) => data != undefined) : [];
        setSelectedAttender(currentSelectedAttender);
    };

    const handleClearAttender = () => {
        setSelectedAttender(null);
    };

    return (
        <>
            <Grid key={data.taskId} container mt={2} mb={2} p={2} sx={{ ...assetStyles.containerStyles }}>
                <Grid item xs={12} sx={{ m: { xs: 1, md: 1 } }} display={'flex'} justifyContent={'space-between'}>
                    <Title title='Job Alert' sx={{ color: Theme.palette.common.white }} />
                    <TextLabel label={`#${data.taskNumber}`} color={Theme.palette.common.white} />
                </Grid>
                <Grid item xs={12} sm={5} md={4} sx={{ p: { xs: 1, md: 1 } }}>
                    <JobAlertText mainTitle='Source Location' mainTitleColor={Theme.palette.common.layout.btnPositive} textLabel={data.sourceLocationName} labelColor={Theme.palette.common.white} />
                </Grid>
                <Grid item xs={12} sm={5} md={4} sx={{ p: { xs: 1, md: 1 } }}>
                    <JobAlertText mainTitle='Destination Location' mainTitleColor={Theme.palette.common.layout.InProgressText} textLabel={data.destinationLocationName} labelColor={Theme.palette.common.white} />
                </Grid>
                <Grid item xs={12} sm={5} md={4} sx={{ p: { xs: 1, md: 1 } }}>
                    <JobAlertText mainTitle='Asset Type' mainTitleColor={Theme.palette.common.white} textLabel={data.assetTypeName} labelColor={Theme.palette.common.white} />
                </Grid>
                <Grid item xs={12} sm={5} md={4} sx={{ p: { xs: 1, md: 1 } }}>
                    <JobAlertText mainTitle='Cancel By' mainTitleColor={Theme.palette.common.white} textLabel={data.attenderName} labelColor={Theme.palette.common.white} />
                </Grid>
                <Grid item xs={12} sm={5} md={4} sx={{ p: { xs: 1, md: 1 } }}>
                    <JobAlertText mainTitle='Cancel Reason' mainTitleColor={Theme.palette.common.white} textLabel={data.attenderNotes??"Cancel by admin"} labelColor={Theme.palette.common.white} />
                </Grid>
                <Grid item xs={12} />
                <Grid item xs={12} sm={12} md={4} sx={{ p: { xs: 1, md: 1 } }}>
                    <AutoComplete
                        color={Theme.palette.primary.contrastText}
                        value={selectedAttender}
                        data={attenderData}
                        title='Attender'
                        onClick={changeAttenderValue}
                        required={true}
                        onClear={handleClearAttender}
                        error={formikCancelRequest.touched.attender && Boolean(formikCancelRequest.errors.attender)}
                        helperText={formikCancelRequest.touched.attender && formikCancelRequest.errors.attender}
                    />
                </Grid>
                <Grid item xs={12} sm={5} md={4} sx={{ p: { xs: 1, md: 1 } }}>
                    <CustomButton
                        onClick={() => {
                            formikCancelRequest.handleSubmit();
                            setSelectedAttender(null);
                        }}
                        variant='outlined'
                        sx={assetStyles.buttonStyles.submitButtonStyles}
                    >
                        Accept
                    </CustomButton>
                </Grid>
                <Grid item xs={12} sm={5} md={4} sx={{ p: { xs: 1, md: 1 } }}>
                    <CustomButton
                        onClick={() => {
                            formikCancelRequest.resetForm();
                            setSelectedAttender(null);
                        }}
                        variant='outlined'
                        sx={assetStyles.buttonStyles.cancelButtonStyles}
                    >
                        Cancel
                    </CustomButton>
                </Grid>
            </Grid>
            <SnackbarComponent
                setShowSnackBar={setShowSnackBar}
                showSnackBar={showSnackBar}
                snackBarMessage={snackBarMessage}
            />
        </>
    );
};

export default JobAlertBox;
